import { Col, Form, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonModal from '../../../components/Common/Modal';
import { REGEX_ONLY_NUMBER } from "../../../utils/constants";

import vehicleTypeServices from "../../../services/vehicleTypes.service";

interface VehicleTypeCreateModalProps {
    dataDetail: any,
    modalStatus: boolean,
    handleOk: () => void,
    handleCancel: () => void
}

const VehicleTypeCreateModal = (props:VehicleTypeCreateModalProps) => {
    const [form] = Form.useForm();
    const {modalStatus, handleOk, handleCancel, dataDetail} = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const onFinish = (values:any) => {
        onCreatePosition(values);
    }

    const onCreatePosition = async (values:any) => {
        setIsLoading(true);
        const dataUpdate = {...values, isInternalCar: dataDetail.isInternalCar}
        delete dataUpdate.timeOfVehicleType
        if(dataDetail?.id){
            const resp = await vehicleTypeServices.updateVehicleType(dataDetail?.id,dataUpdate);
            const data = resp?.data;
            if (resp?.status === 200) {
                notification.success({
                    message: data?.message || t('vehicleTypePage.form.message.editSuccess'),
                });
                handleOk();
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
        }else{
            dataUpdate.isInternalCar = 0;
            const resp = await vehicleTypeServices.createVehicleType(dataUpdate);
            const data = resp?.data;
            if (resp?.status === 200) {
                notification.success({
                    message: data?.message || t('vehicleTypePage.form.message.createSuccess'),
                });
                handleOk();
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
        }
        setIsLoading(false);
    }


    return(
        <CommonModal
            className="vehicle-type-modal-create"
            title={dataDetail?.id ? t('vehicleTypePage.form.editTitle') : t('vehicleTypePage.form.createTitle')}
            open={modalStatus}
            maskClosable={false}
            onCancel={handleCancel}
            footer={[
                <CommonButton
                    key="cancel"
                    onClick={handleCancel}
                    size="small"
                >
                    {t('common.button.close')}
                </CommonButton>,
                <CommonButton
                    form="myForm"
                    key="submit"
                    onClick={()=>form.submit()}
                    btnType="primary"
                    size="small"
                    className="btn-icon-left"
                    loading={isLoading}
                >
                    {t('common.button.save')}
                </CommonButton>
            ]}
        >
            <CommonForm
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    name:  dataDetail?.name,
                    timeForAllow: dataDetail?.timeForAllow,
                    status: dataDetail?.status,
                }}
            >
                <Row gutter={30}>
                    <Col span={11}>
                        <CommonFormItem
                            name="name"
                            label={t('vehicleTypePage.form.label.vehicleTypeName')}
                            placeholder={t('vehicleTypePage.form.placeholder.vehicleTypeName') as string}
                            showCount
                            maxLength={40}
                            showRequiredIcon={!dataDetail.isInternalCar ? true : false}
                            disabled={!dataDetail.isInternalCar  ? false : true}
                            rules={!dataDetail.isInternalCar ? [
                                { required: true, whitespace:true, message: t('vehicleTypePage.form.validate.vehicleTypeName') }
                            ]:[]}
                            type='textArea'
                        />
                    </Col>
                    <Col span={11}>
                        <CommonFormItem
                            name="timeForAllow"
                            label={t('vehicleTypePage.form.label.time')}
                            placeholder={t('vehicleTypePage.form.placeholder.time') as string}
                            showRequiredIcon={!dataDetail.isInternalCar ? true : false}
                            disabled={!dataDetail.isInternalCar  ? false : true}
                            rules={!dataDetail.isInternalCar ? [
                                { required: true, message: t('vehicleTypePage.form.validate.time') },
                                { pattern: REGEX_ONLY_NUMBER, message: t('vehicleTypePage.form.validate.timeOnlyNumber') }
                            ]:[]}
                            showCount
                            maxLength={30}
                            type='textArea'
                        />
                    </Col>
                    <Col span={2} style={{padding: 0}}>
                        <CommonFormItem
                            name="timeOfVehicleType"
                            label=' '
                            disabled
                        >
                            <span className="time-of-vehicle-type">{t('common.minute')}</span>
                        </CommonFormItem>
                    </Col>
                </Row>
                <Row gutter={30}>  
                    <Col span={11}>
                        <CommonFormItem
                            name="status"
                            label={t('vehicleTypePage.form.label.status')}
                            placeholder={t('vehicleTypePage.form.placeholder.status') as string}
                            type="select"
                            showRequiredIcon
                            options={[
                                {value: 0, label: "Hoạt động"},
                                {value: 1, label: "Ngừng hoạt động"},
                            ]}
                            rules={[
                                { required: true, message: t('vehicleTypePage.form.validate.status') }
                            ]}
                        />
                    </Col>
                </Row>
            </CommonForm>
        </CommonModal>
    )
}

export default VehicleTypeCreateModal;