import React, {useEffect, useState} from 'react'
import {buildQueryString} from '../../../utils/utilFunctions'
import {useQuery} from '../../../utils/customHooks'
import {useNavigate} from "react-router-dom";
import {Row, Col, Form, Space} from "antd";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import {ReactComponent as Search} from "../../../resources/images/search-1.svg";

import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from '../../../components/Common/FormItem';
import {ROLE_PAGE_STATUS} from "../../../utils/constants";
import CommonInput from "../../../components/Common/Input";

function SearchBox(props:any) {
    const componentPath = props?.componentPath
    const navigate = useNavigate();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        advance: advanceQuery,
        search: searchQuery,
    } = params;
    const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {}
    
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [advance, setAdvance] = useState(advanceQuery === 'true' || false)
    useEffect(() => {
        setAdvance(advanceQuery === 'true')
    }, [advanceQuery])

    const onFinish = (values: any) => {
        onSearch(values)
    }

    const onClear = (clearParams = {}) => {
        onSearch({...clearParams},true)
        setTimeout(() => {
            form.resetFields();
        }, 100)
    }

    const onSearch = (data:any = {},isClear?:boolean) => {
        const dataSearch = {...data};
        if(data?.modifiedDate?.length){
            dataSearch["modifiedDateFromLong"] = moment(data?.modifiedDate?.[0]).startOf("day").valueOf();
            dataSearch["modifiedDateToLong"] = moment(data?.modifiedDate?.[1]).endOf("day").valueOf();
        }
        delete dataSearch.advance;
        delete dataSearch?.modifiedDate;
        let queryString ={ 
            advance, 
            page: 1,
            search: JSON.stringify(dataSearch),
        }
        if(isClear){
            queryString.advance = data.advance;
        }
        // detect if query not change => call api
        if (queryString !== search) {
            navigate(`${componentPath}${buildQueryString(queryString) || ''}`)
        } else {
            if (props?.getData) props?.getData()
        }
    }

    return <div className="avic-search-box">
        {
            !advance ?
                <div className="normal-search-box">
                    <CommonForm
                        form={form}
                        onFinish={onFinish}
                        layout="horizontal"
                        initialValues={{
                            keyword: searchQueryData?.keyword,
                        }}
                    >
                        <CommonFormItem name="keyword">
                            <CommonInput
                                placeholder={t('permissionsPage.searchBox.placeholder.advancedSearch') as string}
                                prefix={
                                    <div
                                        onClick={() => {
                                            onClear({advance: true})
                                            // setAdvance(true)
                                        }}
                                        className="open-advance-search-btn"
                                    >
                                        {t('permissionsPage.searchBox.label.advancedSearch')} <CaretDownOutlined/>
                                    </div>
                                }
                                addonAfter={
                                    <div onClick={() => form.submit()} className="btn-normal-search">
                                        <Search/>
                                    </div>
                                }
                            />
                        </CommonFormItem>
                    </CommonForm>
                </div>
                :
                <div className="advance-search-box">
                    <div
                        className="close-advance-search-btn cursor-pointer"
                        onClick={() => {
                            // onClear()
                            // setAdvance(false)
                            onClear({advance: false})
                        }}
                    >
                        <div
                        >
                            {t('permissionsPage.searchBox.title')}
                        </div>
                        <CaretUpOutlined
                            className="cursor-pointer"
                        />
                    </div>

                    <CommonForm
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={{
                            groupName: searchQueryData?.groupName,
                            modifiedBy: searchQueryData?.modifiedBy,
                            modifiedDate: (searchQueryData?.modifiedDateFromLong && searchQueryData?.modifiedDateToLong) ? [moment(searchQueryData?.modifiedDateFromLong),moment(searchQueryData?.modifiedDateToLong)] : [],
                            status: searchQueryData?.status,
                        }}
                    >
                        <Row gutter={30}>
                            <Col span={12}>
                                <CommonFormItem
                                    name="groupName"
                                    label={t('permissionsPage.searchBox.label.name')}
                                    placeholder={t('permissionsPage.searchBox.placeholder.name') as string}
                                />
                            </Col>
                            <Col span={12}>
                                <CommonFormItem
                                    name='modifiedBy'
                                    label={t('permissionsPage.searchBox.label.modifiedBy')}
                                    placeholder={t('permissionsPage.searchBox.placeholder.modifiedBy') as string}
                                />
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <CommonFormItem
                                    name='modifiedDate'
                                    type='rangePicker'
                                    label={t('permissionsPage.searchBox.label.modifiedDate')}
                                    placeholder={[t('common.fromDate'), t('common.toDate')]}
                                />
                            </Col>
                            <Col span={12}>
                                <CommonFormItem
                                    name='status'
                                    label={t('permissionsPage.searchBox.label.status')}
                                    placeholder={t('permissionsPage.searchBox.placeholder.status') as string}
                                    options={ROLE_PAGE_STATUS.map(x => ({...x, label: t(x.label)}))}
                                    type='select'
                                />
                            </Col>
                        </Row>

                        <Space className='form-btn-container'>
                            <CommonButton btnType='default' size={'small'} onClick={() => onClear({advance: true})}>
                                {t('common.button.deleteCondition')}
                            </CommonButton>
                            <CommonButton btnType='primary' size={'small'} htmlType='submit'>
                                {t('common.button.search')}
                            </CommonButton>
                        </Space>

                    </CommonForm>
                </div>
        }
    </div>
}

export default SearchBox;

