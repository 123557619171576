import { combineReducers } from 'redux';

import globalReducer from './global.reducer';
import profileReducer from './profile.reducer';
import positionsReducer from './positions.reducer';
import positionSelectedReducer from './positionSelected.reducer';

const rootReducer = combineReducers({
  globalReducer,
  profileReducer,
  positionsReducer,
  positionSelectedReducer
});

export default rootReducer;