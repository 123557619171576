import ViIcon from "../resources/images/vietnamese.svg";
import UkIcon from "../resources/images/english.svg";

// ROLE_ADMIN
// ROLE_SALE_LEADER
// ROLE_SALE_MAN
// ROLE_SALE_MANAGER
// ROLE_SALE_ADMIN
// ROLE_ACCOUNTANT

export const ROUTER_ROLE = [
    {
        scene: 'dashboard',
        roles: null //['ROLE_ADMIN', 'ROLE_SALE_LEADER', 'ROLE_SALE_MAN', 'ROLE_SALE_MANAGER', 'ROLE_SALE_ADMIN', 'ROLE_ACCOUNTANT']
    },
    {
        scene: 'quote-status',
        roles: null
    },
    {
        scene: 'purchases-status',
        roles: null
    },
    {
        scene: 'manage-quote-request',
        roles: null
    },
    {
        scene: 'roles',
        roles: null
    },
]
export const ROLES_LIST = [
    {
        id: 'ROLE_ADMIN',
        name: 'Admin'
    },
    {
        id: 'ROLE_SALE_MANAGER',
        name: 'Sale Manager'
    },
    {
        id: 'ROLE_SALE_LEADER',
        name: 'Sale Leader'
    },
    {
        id: 'ROLE_SALE_MAN',
        name: 'Sale man'
    },
    {
        id: 'ROLE_SALE_ADMIN',
        name: 'Sale Admin'
    },
    {
        id: 'ROLE_ACCOUNTANT',
        name: 'Accountant'
    },
]
export const LANGUAGE_LIST = [
    {
        value: 'vi',
        label: 'Tiếng Việt',
        icon: ViIcon
    },
    {
        value: 'en',
        label: 'English',
        icon: UkIcon
    },
]
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm';
export const DATE_TIME_FORMAT_SECOND = 'DD/MM/YYYY, HH:mm:ss';
export const DOB_FORMAT = 'DD MMMM, YYYY';
export const PAGE_SIZE_LIST = [1, 10, 20, 50, 100]
export const DEFAULT_PAGE_SIZE = 10
// export const REGEX_PASSWORD = /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Za-z]).*/g
export const REGEX_USERNAME = /^([a-zA-Z0-9_]+)$/g
export const REGEX_CODE = /^([a-zA-Z0-9]+)$/g
export const REGEX_PHONE_NUMBER = /^[0-9]([0-9]+){9}$/g
export const REGEX_ONLY_NUMBER = /^[0-9]+$/g
export const REGEX_NO_SPACE = /^\S*$/

export const AT_LEAST_8_CHARS = /^.{8,}$/;    // Ít nhất 8 kí tự
// export const AT_LEAST_1_UPPERCASE = /^(?=.*[A-Z]).*$/;    // Ít nhất 1 kí tự viết hoa
export const AT_LEAST_1_NUMBER = /^(?=.*\d).*$/;  // Ít nhất 1 số
export const REGEX_PASSWORD = /^(?=.*\d).{8,}$/;

export const VI_MOMENT_CONFIG = {
    week: {
        dow: 1 /// Date offset
    },
    months: [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
    ],
    monthsShort: [
        'Thg 1',
        'Thg 2',
        'Thg 3',
        'Thg 4',
        'Thg 5',
        'Thg 6',
        'Thg 7',
        'Thg 8',
        'Thg 9',
        'Thg 10',
        'Thg 11',
        'Thg 12',
    ],
    weekdays: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
    weekdaysShort: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'],
    weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    relativeTime: {
        future: 'trong %s',
        past: '%s trước',
        s: 'vài giây trước',
        ss: '%d giây',
        m: '1 phút',
        mm: '%d phút',
        h: '1 giờ',
        hh: '%d giờ',
        d: '1 ngày',
        dd: '%d ngày',
        w: '1 tuần',
        ww: '%d tuần',
        M: '1 tháng',
        MM: '%d tháng',
        y: '1 năm',
        yy: '%d năm',
    },
}

//rolePage
export const ROLE_PAGE_STATUS = [
    {
        value: 0,
        label: 'permissionsPage.status.active',
        type: 'success'
    },
    {
        value: 1,
        label: 'permissionsPage.status.inactive',
        type: 'default'
    },
]
// //tableUserByRole
// export const TABLE_USER_BY_ROLE_STATUS = [
//     {
//         value: 1,
//         label: 'tableUserByRole.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'tableUserByRole.status.inactive',
//         type: 'default'
//     },
// ]
//accountsPage 
export const ACCOUNT_PAGE_STATUS = [
    {
        value: 0,
        label: 'accountPage.status.active',
        type: 'success'
    },
    {
        value: 1,
        label: 'accountPage.status.inactive',
        type: 'default'
    },
]
// //areasPage
// export const AREA_PAGE_STATUS = [
//     {
//         value: 1,
//         label: 'areasPage.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'areasPage.status.inactive',
//         type: 'default'
//     },
// ]
// // positionPage
// export const POSITION_PAGE_STATUS = [
//     {
//         value: 1,
//         label: 'positionPage.options.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'positionPage.options.status.inactive',
//         type: 'default'
//     },
// ]
// // departmentPage
// export const DEPARTMENT_PAGE_STATUS = [
//     {
//         value: 1,
//         label: 'departmentPage.options.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'departmentPage.options.status.inactive',
//         type: 'default'
//     },
// ]
// // supplierPage
// export const SUPPLIER_PAGE_STATUS = [
//     {
//         value: 1,
//         label: 'supplierPage.options.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'supplierPage.options.status.inactive',
//         type: 'default'
//     },
// ]
// export const SUPPLIER_PAGE_STATUS_APPROVE = [
//     {
//         value: 0,
//         label: 'supplierPage.options.statusApprove.pending',
//         type: 'default'
//     },
//     {
//         value: 1,
//         label: 'supplierPage.options.statusApprove.approveLevel1',
//         type: 'warning'
//     },
//     {
//         value: 2,
//         label: 'supplierPage.options.statusApprove.approveLevel2',
//         type: 'info'
//     },
//     {
//         value: 3,
//         label: 'supplierPage.options.statusApprove.approved',
//         type: 'success'
//     },
//     {
//         value: 4,
//         label: 'supplierPage.options.statusApprove.refuseApprove',
//         type: 'danger'
//     },
// ]

// // customerPage
// export const CUSTOMER_PAGE_STATUS = [
//     {
//         value: 1,
//         label: 'customerPage.options.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'customerPage.options.status.inactive',
//         type: 'default'
//     },
// ]
// export const CUSTOMER_PAGE_STATUS_APPROVE = [
//     {
//         value: 0,
//         label: 'customerPage.options.statusApprove.pending',
//         type: 'default'
//     },
//     {
//         value: 1,
//         label: 'customerPage.options.statusApprove.approveLevel1',
//         type: 'warning'
//     },
//     {
//         value: 2,
//         label: 'customerPage.options.statusApprove.approveLevel2',
//         type: 'info'
//     },
//     {
//         value: 3,
//         label: 'customerPage.options.statusApprove.approved',
//         type: 'success'
//     },
//     {
//         value: 4,
//         label: 'customerPage.options.statusApprove.refuseApprove',
//         type: 'danger'
//     },
// ];
// export const CUSTOMER_PAGE_TYPE = [
//     {
//         value: 1,
//         label: 'customerPage.options.customerType.individual',
//     },
//     {
//         value: 2,
//         label: 'customerPage.options.customerType.organization',
//     },
// ]

// //unit type
// export const UNIT_TYPE_PAGE_STATUS = [
//     {
//         value: 1,
//         label: 'unitTypePage.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'unitTypePage.status.inactive',
//         type: 'default'
//     },
// ]
// //Loại đơn vị. 1-Đơn vị tính tham chiếu, 2-Lớn hơn đơn vị tính tham chiếu, 3-Nhỏ hơn đơn vị tính tham chiếu
// export const UNIT_TYPE_PAGE_TYPE = [
//     {
//         value: 1,
//         label: 'unitTypePage.type.referenceUnit',
//     },
//     {
//         value: 2,
//         label: 'unitTypePage.type.greaterThanReferenceUnit',
//     },
//     {
//         value: 3,
//         label: 'unitTypePage.type.lessThanReferenceUnit',
//     },
// ]

// //attribute
// export const ATTRIBUTE_PAGE_STATUS = [
//     {
//         value: 1,
//         label: 'attributePage.status.active',
//         type: 'success'
//     },
//     {
//         value: 2,
//         label: 'attributePage.status.inactive',
//         type: 'default'
//     },
// ]

// lock warning
export const LOCK_WARNING =[
    {label: "Hết pin", value: 0},
    {label: "Tín hiệu GPS kém", value: 1},
    {label: "Cảnh báo dừng đỗ (xe)", value: 2},
    {label: "Cảnh báo quá tốc độ", value: 3},
    {label: "Backcover của khóa bị mở", value: 4},
];

// lock work
export const LOCK_WORK =[
    {label: "Chưa khóa", value: 0},
    {label: "Đang khóa", value: 1},
    // {label: "Đã gán móc khóa", value: 2},
    // {label: "Chưa gán móc khóa", value: 3},
];

// Vehicle status
export const VEHICLE_STATUS =[
    {label: "Đã đăng ký vào nhà máy", value: 1, type: "info"},
    {label: "Đang giao/ nhận hàng", value: 2, type: "info"},
    {label: "Đã tháo Seal", value: 3, type: "warning"},
    {label: "Đã hoàn thành", value: 4, type: "success"},
    {label: "Đã Hủy giao hàng", value: 5, type: "danger"},
];

