import React from 'react'
import {useTranslation} from "react-i18next";
import {notification, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import { useDispatch, useSelector } from 'react-redux';

import {ReactComponent as CameraIcon} from "../../../resources/images/camera-icon.svg";
import { saveProfile } from '../../../redux/actions/profile.actions';

import accountsServices from "../../../services/users.service";
import userServices from "../../../services/users.service";

function CameraAntd() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        profile
    } = useSelector((state:any) => state?.profileReducer);


    
    const getProfile = async () => {
        const resp = await userServices.getProfile();
        const data = resp.data;
        if (resp?.status === 200) {
            dispatch(saveProfile(data?.data))
        }
    }

    const onSubmit = async (values:any) => {
        const body = {
            ...profile,
            avatar: values,
            authorities: null,
            grantedAuths: null,
        }

        const resp = await accountsServices.updateAccount(profile.id, body);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: t('profilePage.accountProfileTab.message.changeAvatar'),
            });
            getProfile();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
    }

    const onUploadFile = async (options:any) => {
        if (!options?.file) {
            return
        }
        console.log("options?.file",options)
        const reader = new FileReader()
        reader.readAsDataURL(options?.file)
        reader.onload = () => {
            console.log('called: ', reader)
            onSubmit(reader.result)
        }
    }


    return (
        <div className="avatar-change-icon">
            <ImgCrop rotate>
                <Upload
                    fileList={[]}
                    customRequest={onUploadFile}
                >
                    <CameraIcon />
                </Upload>
            </ImgCrop>
        </div>
    );
}

export default CameraAntd;

