import APIService from '../utils/apiServices';

export interface NotifySearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class NotifyServices extends APIService {
    async getNotify(params: NotifySearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/v1/system/action/getPage`, {}, {params: apiParams});
    }
}
const service = new NotifyServices();
export default service
