import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CommonButton from './../../components/Common/Button';

interface NotifyItemProps{
    itemData: any;
    onOK: () => void
}

const NotifyItem = (props: NotifyItemProps)=>{
    const {t} = useTranslation();
    const { itemData } = props;

    return (
        <>
            <div className={ `notify-item-wrapper ${false ? 'is-read':''}` } >
                <div className='notify-item-header'>
                    <div>
                        <div style={{cursor: "pointer"}}>{itemData?.plateNumber}</div>
                        <div className="item-status">{itemData?.createdDate ? moment(itemData?.createdDate).format("DD/MM/YYYY HH:mm:ss") : ""}</div>
                    </div>
                    {/* <CommonButton size={'small'} btnType="primary"> 
                        {t('Mở khóa')} 
                    </CommonButton> */}
                </div>
                
                <div className='notify-item-footer'>
                    <div>
                        {itemData?.description}
                    </div>
                </div>
            </div>
        </>
    )
}
export default NotifyItem;