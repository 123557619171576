import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Space, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Close } from "../../../resources/images/close-contained.svg";

import CommonDrawer from "../../../components/Common/Drawer";
import CommonButton from "../../../components/Common/Button";
import CommonTree from "../../../components/Common/Tree";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonForm from "../../../components/Common/Form";
import CommonSpin from './../../../components/Common/Spin';
import { ROLE_PAGE_STATUS } from "../../../utils/constants";
import { saveProfile } from "../../../redux/actions/profile.actions";

import permissionsServices from "../../../services/permissions.service";
import positionServices from "../../../services/positions.service";
import userServices from "../../../services/users.service";

export interface PermissionDrawerProps{
    visible: boolean,
    onAddSuccessful: () => void,
    resetState: () => void,
    currentPermission: any,
}

const PermissionDrawer = ({ visible, onAddSuccessful, currentPermission, resetState }: PermissionDrawerProps) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [positionSelectData, setPositionSelectData] = useState<any[]>([]);
    const [gateInSelectData, setGateInSelectData] = useState<any[]>([]);
    const [gateOutSelectData, setGateOutSelectData] = useState<any[]>([]);
    const [permissionSecurityId, setPermissionSecurityId] = useState<number>(0);
    const [permissionSecurityGateInId, setPermissionSecurityGateInId] = useState<number>(0);
    const [permissionSecurityGateOutId, setPermissionSecurityGateOutId] = useState<number>(0);
    const {profile} = useSelector((state:any) => state?.profileReducer);
    const isCanCreateListPermission = profile?.authorities?.find((item:any)=> item.authority === "PERMISSION_CREATE");
    const isCanUpdateListPermission = profile?.authorities?.find((item:any)=> item.authority === "PERMISSION_EDIT");

    const getDataSelect =  async (reload?: boolean) =>{
        const paramsSearch = {
            page: 0,
            size: 1000,
            search: JSON.stringify({status: 0}) ,
        }

        //select vị trí
        const respPosition = await positionServices.getPosition(paramsSearch);
        const dataPosition = respPosition?.data;
        if (respPosition?.status === 200) {
            // setPositionSelectData(dataPosition?.data?.content?.map((item:any)=>({value:item.id, label: item.name+" - "+item.address})));
            setPositionSelectData(dataPosition?.data?.content?.filter(
                (item:any) => item.type!==100&&item.type!==200)?.map(
                    (item:any)=> ({value:item.id, label: item.name+" - "+item.address})));
            setGateInSelectData(dataPosition?.data?.content?.filter(
                        (item:any) => item.type===100)?.map(
                            (item:any)=> ({value:item.id, label: item.name})));
            setGateOutSelectData(dataPosition?.data?.content?.filter(
                        (item:any) => item.type===200)?.map(
                            (item:any)=> ({value:item.id, label: item.name})));
        } else {
            setPositionSelectData([]);
        }

    };

    useEffect(()=>{
        getDataSelect();
    },[])

    const getProfile = async () => {
        const resp = await userServices.getProfile();
        const data = resp.data;
        if (resp?.status === 200) {
            dispatch(saveProfile(data?.data))
        }
    }

    const getData =  useCallback(async ()=>{
        setIsLoading(true)
        const resp = await permissionsServices.getFullAuthority();
        const data = resp?.data;
        if (resp?.status === 200) {
            const newTreeData = data?.data?.map((item:any)=>({key: item.id, title: item.description}));
            setTreeData(newTreeData);
            if(currentPermission){
                setCheckedKeys(currentPermission?.authorities?.map((item:any)=>item.id));
            }
            setPermissionSecurityId(data?.data?.find((item:any)=> item.authKey==="SECURITY")?.id||0);
            setPermissionSecurityGateInId(data?.data?.find((item:any)=> item.authKey==="SECURITY_GATEIN")?.id||0);
            setPermissionSecurityGateOutId(data?.data?.find((item:any)=> item.authKey==="SECURITY_GATEOUT")?.id||0);
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    },[currentPermission, t])

    useEffect(()=>{
        getData();
    },[getData])
    
    
    const onCheck = (checkedKeysValue:any, noteCheck:any) => {
        if(noteCheck.checked && [permissionSecurityId, permissionSecurityGateInId, permissionSecurityGateOutId].includes(noteCheck.node.key)){
            const newCheckedKeys =  [...checkedKeysValue.filter(
                                        (item:any)=> ![permissionSecurityId, permissionSecurityGateInId, permissionSecurityGateOutId].includes(item)),
                                        noteCheck.node.key
                                    ];
            setCheckedKeys(newCheckedKeys)
            
        }else{
            setCheckedKeys(checkedKeysValue);
        }
    };

    const onFinish = (values: any) => {
        onSubmit(values)
    }

    const onSubmit = async (values:any) => {
        if (checkedKeys?.length === 0) {
            notification.error({
                message: t('permissionsPage.form.message.permissionRequired'),
            });
            return;
        }

        setIsSubmitLoading(true)
        let resp;
        const body:any = {
            "description": values?.description,
            "authorities": checkedKeys.map((item:any)=>({id: item})),
            "groupName": values?.groupName,
            "status": values?.status ? ROLE_PAGE_STATUS[0].value : ROLE_PAGE_STATUS[1].value
        }
        if(values?.categoryPosition){
            body.categoryPosition = {id: values?.categoryPosition};
        }
        if (currentPermission) {
            resp = await permissionsServices.updatePermission(currentPermission.id, body);
        } else {
            resp = await permissionsServices.createPermission(body);
        }
        const data = resp?.data;
        if (resp?.status === 200) {
            if (!currentPermission) {
                notification.success({
                    message: t('permissionsPage.form.message.createSuccess'),
                });
            } else {
                notification.success({
                    message: t('permissionsPage.form.message.updateSuccess'),
                });
            } 
            getProfile();
            onAddSuccessful();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsSubmitLoading(false)
    }

    return (
        <CommonDrawer
            closable={false}
            mask={true}
            maskClosable={false}
            width={'60%'}
            title={currentPermission ?
                <div className="drawer-title">
                    <span>{t('permissionsPage.form.detailTitle')}</span>
                    <Close onClick={() => resetState()} />
                </div>
                : <div className="drawer-title">
                    <span>{t('permissionsPage.form.createTitle')}</span>
                    <Close onClick={() => resetState()} />
                </div>}
            placement="right"
            open={visible}
            onClose={() => resetState()}
            footer={[
                <Row className='row-drawer' key='footer'>
                    <Space>
                        <CommonButton
                            key="close"
                            btnType='default'
                            size="large"
                            onClick={() => resetState()}
                        >
                            {t('common.button.close')}
                        </CommonButton>
                        { isCanCreateListPermission && !currentPermission ?
                            <CommonButton
                                key="update"
                                btnType='primary'
                                size="large"
                                onClick={() => form.submit()}
                                loading={isSubmitLoading}
                            >
                                {t('common.button.addNew')}
                            </CommonButton> : <></>
                        }
                        { isCanUpdateListPermission && currentPermission ?
                            <CommonButton
                                key="update"
                                btnType='primary'
                                size="large"
                                onClick={() => form.submit()}
                                loading={isSubmitLoading}
                            >
                                {t('common.button.save')}
                            </CommonButton> : <></>
                        }
                    </Space>
                </Row>
            ]}
        >   
            <CommonForm
                key="form"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    groupCode: currentPermission?.groupCode,
                    groupName: currentPermission?.groupName,
                    description: currentPermission?.description,
                    status: currentPermission ? (currentPermission?.status === 0 ? true : false) : true,
                    categoryPosition: currentPermission?.categoryPosition?.id || undefined
                }}
                disabled={!isCanUpdateListPermission && currentPermission}
            >
                <div className="detail-page-box">
                    <div className="box-title">
                        {t('permissionsPage.form.infoBoxTitle')}
                    </div>
                    <Row gutter={20}>
                        <Col span={12}>
                            <CommonFormItem
                                name="groupName"
                                label={t('permissionsPage.form.label.name')}
                                placeholder={t('permissionsPage.form.placeholder.name') as string}
                                rules={[
                                    { whitespace: true, required: true, message: t('permissionsPage.form.validate.name') as string }
                                ]}
                                showRequiredIcon={true}
                            />
                        </Col>
                        <Col span={12}>
                            <CommonFormItem
                                name="description"
                                label={t('permissionsPage.form.label.description')}
                                placeholder={t('permissionsPage.form.placeholder.description') as string}
                            />
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        {   checkedKeys?.includes(permissionSecurityId) ? 
                                <Col span={12}>
                                    <CommonFormItem
                                        name="categoryPosition"
                                        label={t('permissionsPage.form.label.categoryPosition')}
                                        placeholder={t('permissionsPage.form.placeholder.categoryPosition') as string}
                                        options={positionSelectData}
                                        type="select"
                                        allowClear
                                        rules={[
                                            { required: true, message: t('permissionsPage.form.validate.categoryPosition') as string }
                                        ]}
                                        showRequiredIcon={true}
                                    />
                                </Col> : <></>
                        }
                        {   checkedKeys?.includes(permissionSecurityGateInId) ?  
                                <Col span={12}>
                                    <CommonFormItem
                                        name="categoryPosition"
                                        label={t('permissionsPage.form.label.categoryPositionGateIn')}
                                        placeholder={t('permissionsPage.form.placeholder.categoryPositionGateIn') as string}
                                        options={gateInSelectData}
                                        type="select"
                                        allowClear
                                        rules={[
                                            { required: true, message: t('permissionsPage.form.validate.categoryPositionGateIn') as string }
                                        ]}
                                        showRequiredIcon={true}
                                    />
                                </Col> : <></>
                        }
                        {   checkedKeys?.includes(permissionSecurityGateOutId) ? 
                                <Col span={12}>
                                    <CommonFormItem
                                        name="categoryPosition"
                                        label={t('permissionsPage.form.label.categoryPositionGateOut')}
                                        placeholder={t('permissionsPage.form.placeholder.categoryPositionGateOut') as string}
                                        options={gateOutSelectData}
                                        type="select"
                                        allowClear
                                        rules={[
                                            { required: true, message: t('permissionsPage.form.validate.categoryPositionGateOut') as string }
                                        ]}
                                        showRequiredIcon={true}
                                    />
                                </Col> : <></>
                        }
                        <Col span={12}>
                            <CommonFormItem
                                name="status"
                                label={t('permissionsPage.form.label.status')}
                                valuePropName="checked"
                                type='switch'
                            />
                        </Col>
                    </Row>
                    <div className="box-title">
                        {t('permissionsPage.form.permissionBoxTitle')}
                    </div>
                    <CommonSpin isLoading={isLoading}> 
                        <CommonTree
                            key="tree"
                            checkable
                            selectable={false}
                            showLine={true}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            treeData={treeData}
                            disabled={!isCanUpdateListPermission && currentPermission ? true :false}
                        />
                    </CommonSpin>
                </div>
            </CommonForm>
        </CommonDrawer>
    )
}

export default PermissionDrawer;