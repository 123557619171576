import React from 'react'
import CommonButton from '../../components/Common/Button'

function index() {
    return (
        <div style={{ height: '100%', flexDirection:"column" ,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <a href={`${process.env.REACT_APP_DOWNLOAD_URL}/apk-download`} style={{textDecoration: "none"}}>
                <CommonButton
                    btnType='primary'
                    size='large'
                    style={{minWidth: 200, minHeight: 60, fontSize: 20}}
                > 
                    Tải App
                </CommonButton>
            </a>
            <a href={`${process.env.REACT_APP_DOWNLOAD_URL}`} style={{textDecoration: "none", marginTop: 40}}>
                <CommonButton
                    btnType='default'
                    size='large'
                    style={{minWidth: 200, minHeight: 60, fontSize: 20}}
                > 
                    Về trang chủ
                </CommonButton>
            </a>
        </div>
    )
}

export default index