import React, {useEffect, useRef, useState} from 'react'
import { Button, Form, Checkbox, Input, Select, Row, Col } from 'antd';
import userServices from "../../services/users.service";
import LocalStorage from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux";

import background from "../../resources/images/login/background-login.jpg";
import { ReactComponent as Logo } from "../../resources/images/logo_small.svg";

import {LANGUAGE_LIST} from "../../utils/constants";
import {saveProfile} from "../../redux/actions/profile.actions";

const localLanguage = LocalStorage.getInstance().read('language');
function Login() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formRef = useRef<any>()
    const [currentLanguage, setCurrentLanguage] = useState(localLanguage || LANGUAGE_LIST[0]?.value);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        dispatch(saveProfile(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePasswordLogin = async () => {
        try {
            const response = await fetch('/api/session/1', {
                method: 'POST'
            });
            if (response.ok) {
                const user = await response.json();
            } else {
                throw Error(await response.text());
            }
        } catch (error) {
        }
    };

    const onFinish = async (values:any) => {
        setIsLoading(true);
        handlePasswordLogin();
        const resp = await userServices.login(values);
        const data = resp?.data?.data;
        if (resp?.status === 200) {
            LocalStorage.getInstance().save('accessToken', data?.accessTokenInfo?.accessToken);
            getProfile()
        } else {
            if (formRef?.current) {
                formRef?.current?.setFields([
                    {
                        name: 'username',
                        errors: [''],
                    },
                    {
                        name: 'password',
                        errors: [t('login.wrongPassword')],
                    },
                ]);
            }
        }
        setIsLoading(false);
    };

    const getProfile = async () => {
        const resp = await userServices.getProfile();
        const data = resp?.data;
        if (resp?.status === 200) {
            dispatch(saveProfile(data?.data))
            const redirectUrl = LocalStorage.getInstance().read('redirectUrl');
            if (redirectUrl) {
                LocalStorage.getInstance().save('redirectUrl', null);
                window.location.href = redirectUrl
            } else {
                window.location.reload()
            }
        }
    }

    const onFinishFailed = (errorInfo:any) => {
        console.log('Failed:', errorInfo);
    };

    const handleChangeLanguage = (value:string) => {
        setCurrentLanguage(value)
        LocalStorage.getInstance().save('language', value);
        window.location.reload()
    };

    return (
        <div className="login-container" style={{backgroundImage: `url(${background})` }}>
            <div className="login-box">
                <div className="text-center">
                    <Logo className="style-size-logo"/>
                </div>

                <h3 className="txt-welcome">{t('login.welcome')}</h3>
                <h2 className="txt-title">{t('login.title')}</h2>
                <Form
                    ref={formRef}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size={'large'}
                >
                    <Form.Item
                        name="username"
                        validateTrigger={"onBlur"}
                        rules={[{ required: true, message: t('validate.usernameRequired') as string }]}
                    >
                        <Input
                            placeholder={t('login.usernamePlaceholder') as string}
                            allowClear
                            maxLength={50}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        validateTrigger={"onBlur"}
                        rules={[{ required: true, message: t('validate.passwordRequired') as string }]}
                    >
                        <Input.Password
                            placeholder={t('login.passwordPlaceholder') as string}
                            maxLength={20}
                        />
                    </Form.Item>

                    <Row>
                        <Col span={12}>
                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>{t('login.rememberLabel')}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Button loading={isLoading} type="primary" className="btn-login" htmlType="submit">
                                {t('login.submit')}
                            </Button>
                        </Col>
                    </Row>

                    <div className="text-center">
                        <Select
                            value={currentLanguage}
                            style={{ width: 230 }}
                            onChange={handleChangeLanguage}
                            options={LANGUAGE_LIST}
                        />
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default Login;

