import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import LocalStore from "./utils/localStorage";
import Layout from "./components/Layout";
import Profile from './containers/SystemManagement/Profile';
import Page404 from './containers/Page404';
import Login from './containers/Login';
import MyMapComponent from './containers/Map';
import Permission from './containers/SystemManagement/Permission';
import Account from './containers/SystemManagement/Account';
import DownloadApp from './containers/DownloadApp';
import VehicleReport from './containers/SystemManagement/Reports/VehicleReport';
import LockReport from './containers/SystemManagement/Reports/LockReport';
import LoginReport from './containers/SystemManagement/Reports/LoginReport';

const MyRoutes = () => {
    const accessToken = LocalStore.getInstance().read('accessToken')

    return (
        <Router>
            {
                accessToken
                ?
                    <Routes>
                        <Route path="/" element={<Layout><MyMapComponent/></Layout>}/>
                        <Route path="/system-management" element={<Layout><Profile /></Layout>}/>
                        <Route path="/system-management/profile" element={<Layout><Profile /></Layout>}/>
                        <Route path="/system-management/account" element={<Layout><Account/></Layout>}/>
                        <Route path="/system-management/permission" element={<Layout><Permission/></Layout>}/>
                        <Route path="/system-management/vehicle-report" element={<Layout><VehicleReport/></Layout>}/>
                        <Route path="/system-management/lock-report" element={<Layout><LockReport/></Layout>}/>
                        <Route path="/system-management/login-report" element={<Layout><LoginReport/></Layout>}/>
                        <Route path="/apk" element={<DownloadApp/>}/>
                        <Route path='*' element={<Page404/>} />
                    </Routes>
                :
                <Routes>
                    <Route path="/" element={<Login />}/>
                    <Route path="/apk" element={<DownloadApp/>}/>
                    <Route path='*' element={<Page404/>} />
                </Routes>
            }
        </Router>
    );
}
export default MyRoutes
