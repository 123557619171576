import { Col, Form, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonModal from './../../../components/Common/Modal';

import positionServices from "../../../services/positions.service";

interface PositionCreateModalProps {
    dataDetail: any,
    modalStatus: boolean,
    handleOk: () => void,
    handleCancel: () => void
}

const PositionCreateModal = (props:PositionCreateModalProps) => {
    const [form] = Form.useForm();
    const {modalStatus, handleOk, handleCancel, dataDetail} = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const onFinish = (values:any) => {
        onCreatePosition(values);
    }

    const onCreatePosition = async (values:any) => {
        setIsLoading(true);
        // const paramsSearch = {...values}
        if(dataDetail?.id){
            const resp = await positionServices.updatePosition(dataDetail?.id,values);
            const data = resp?.data;
            if (resp?.status === 200) {
                notification.success({
                    message: data?.message || t('positionPage.form.message.editSuccess'),
                });
                handleOk();
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
        }else{
            const resp = await positionServices.createPosition(values);
            const data = resp?.data;
            if (resp?.status === 200) {
                notification.success({
                    message: data?.message || t('positionPage.form.message.createSuccess'),
                });
                handleOk();
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
        }
        setIsLoading(false);
    }


    return(
        <CommonModal
            className="position-modal-create"
            title={dataDetail ? t('positionPage.form.editTitle') : t('positionPage.form.createTitle')}
            open={modalStatus}
            maskClosable={false}
            onCancel={handleCancel}
            footer={[
                <CommonButton
                    key="cancel"
                    onClick={handleCancel}
                    size="small"
                >
                    {t('common.button.close')}
                </CommonButton>,
                <CommonButton
                    form="myForm"
                    key="submit"
                    onClick={()=>form.submit()}
                    btnType="primary"
                    size="small"
                    className="btn-icon-left"
                    loading={isLoading}
                >
                    {t('common.button.save')}
                </CommonButton>
            ]}
        >
            <CommonForm
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    name:  dataDetail?.name,
                    address: dataDetail?.address,
                    status: dataDetail?.status,
                }}
            >
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            name="name"
                            label={t('positionPage.form.label.buildingName')}
                            placeholder={t('positionPage.form.placeholder.buildingName') as string}
                            showRequiredIcon
                            showCount
                            maxLength={30}
                            rules={[
                                { required: true, whitespace:true, message: t('positionPage.form.validate.buildingName') }
                            ]}
                            type='textArea'
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            name="address"
                            label={t('positionPage.form.label.position')}
                            placeholder={t('positionPage.form.placeholder.position') as string}
                            showRequiredIcon
                            rules={[
                                { required: true, whitespace:true, message: t('positionPage.form.validate.position') }
                            ]}
                            showCount
                            maxLength={30}
                            type='textArea'
                        />
                    </Col>
                </Row>
                <Row gutter={30}>  
                    <Col span={12}>
                        <CommonFormItem
                            name="status"
                            label={t('positionPage.form.label.status')}
                            placeholder={t('positionPage.form.placeholder.status') as string}
                            type="select"
                            showRequiredIcon
                            options={[
                                {value: 0, label: "Hoạt động"},
                                {value: 1, label: "Ngừng hoạt động"},
                            ]}
                            rules={[
                                { required: true, message: t('positionPage.form.validate.status') }
                            ]}
                        />
                    </Col>
                </Row>
            </CommonForm>
        </CommonModal>
    )
}

export default PositionCreateModal;