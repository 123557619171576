import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Layout, Tabs, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';

import { ReactComponent as CollapseIcon } from '../../resources/images/sidebar-collapse-icon.svg';
import { ReactComponent as UnCollapseIcon } from '../../resources/images/sidebar-uncollapse-icon.svg';
import { ReactComponent as MapIcon } from '../../resources/images/tab-map-icon.svg';
import { ReactComponent as CategoryIcon } from '../../resources/images/tab-category-icon.svg';
import { ReactComponent as NotifyIcon } from '../../resources/images/tab-notify-icon.svg';
import { ReactComponent as SystemIcon } from '../../resources/images/tab-system-icon.svg';

import VehicleRegistration from '../../containers/VehicleRegistration';
import Position from '../../containers/Categories/Position';
import VehicleType from '../../containers/Categories/VehicleType';
import Lock from '../../containers/Categories/Lock';
import Notify from '../../containers/Notify';
import { buildQueryString } from '../../utils/utilFunctions';

import { useQuery } from '../../utils/customHooks';

const { Sider } = Layout;

function Sidebar (props:any) {
    const navigate= useNavigate();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        tab: tabQuery,
        subTab: subTabQuery
    } = params
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState<string>(tabQuery||"mapTab");
    const [categoryDefaultKey, setCategoryDefaultKey] = useState<string>(subTabQuery||"positionTab");
    const [title, setTitle] = useState<string>('');
    
    const {
        profile
    } = useSelector((state:any) => state?.profileReducer);

    const isCanSeeListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_VIEW");

    useEffect(()=>{
        let newTitle = "";
        if(props?.config?.arrMenus){
            newTitle = t('layout.sidebar.tabs.systemManagementTitle');
        }else{
            
            switch(activeTabKey){
                case 'mapTab': 
                    newTitle = t('vehicleRegistrationPage.title');
                    break;
                case 'categoryTab':
                    if(categoryDefaultKey === 'positionTab'){
                        newTitle = t('positionPage.title');
                    } else if(categoryDefaultKey === 'vehicleTypeTab'){
                        newTitle = t('vehicleTypePage.title');
                    } else {
                        newTitle = t('lockPage.title');
                    }
                    break;
                case 'notifyTab':
                    newTitle = t('notifyPage.title');
                    break;
                default:
                    break;
            }
        }
        setTitle(newTitle);
        
    },[activeTabKey, categoryDefaultKey, props?.config?.arrMenus, t]);

    const onChangeSubMenu = useCallback((key: string)=>{
        setCategoryDefaultKey(key);
        let queryString = buildQueryString({
            tab: "categoryTab",
            subTab: key
        });
        if (queryString !== search) {
            navigate(`${queryString || ''}`)
        }
    },[navigate, search])

    const itemsDropdownProfile = useMemo(()=>[
        {
            key: 'positionTab',
            label: (
                <div>
                    {t('layout.sidebar.tabs.positionCategory')}
                </div>
            ),
            onClick: ()=> onChangeSubMenu('positionTab')
        },
        {
            key: 'vehicleTypeTab',
            label: (
                <div>
                    {t('layout.sidebar.tabs.vehicleTypeCategory')}
                </div>
            ),
            onClick: ()=> onChangeSubMenu('vehicleTypeTab')
        },
        {
            key: 'lockTab',
            label: (
                <div>
                    {t('layout.sidebar.tabs.lockCategory')}
                </div>
            ),
            onClick: ()=> onChangeSubMenu('lockTab')
        }
    ],[onChangeSubMenu, t])

    const items = useMemo(() => {
        const newItems = [
            {
                label: (
                    <div className="tab-title">
                        <MapIcon />
                        {t('layout.sidebar.tabs.mapTab')}
                    </div>
                ),
                key: "mapTab",
                children: <VehicleRegistration/>
            },
            isCanSeeListCategory ? {
                label: (
                    <Dropdown 
                        menu={{ items: itemsDropdownProfile, 
                                selectable: true,
                                selectedKeys: [categoryDefaultKey], 
                            }} 
                        trigger={["hover"]}
                    >
                        <div className="tab-title">
                            <CategoryIcon />
                            {t('layout.sidebar.tabs.categoryTab')}
                        </div>
                    </Dropdown>
                ),
                key: "categoryTab",
                children: <>
                    {
                        categoryDefaultKey === 'positionTab' ? <Position/> : (
                            categoryDefaultKey === 'vehicleTypeTab' ? <VehicleType/> : <Lock/>
                        )
                    }
                </>
            } : null,
            {
                label: (
                    <div className="tab-title">
                        <NotifyIcon />
                        {t('layout.sidebar.tabs.notifyTab')}
                    </div>
                ),
                key: "notifyTab",
                children:<Notify/>
            },
            {
                label: (
                    <div className="tab-title">
                        <SystemIcon />
                        {t('layout.sidebar.tabs.systemManagementTab')}
                    </div>
                ),
                key: "systemTab",
                children: `Content of Tab 4`,
            }
        ];
        return newItems?.filter((item:any)=>item)
    },[categoryDefaultKey, itemsDropdownProfile, isCanSeeListCategory, t]);

    const onChangeTabs = (key:string) => {
        if(key==="systemTab"){
            navigate("/system-management/profile")
        }else{
            let obj:any = {
                tab: key,
            };
            if(key==="categoryTab"){
                obj["subTab"] = categoryDefaultKey;
            }
            let queryString = buildQueryString({...obj});
            if (queryString !== search) {
                navigate(`${queryString || ''}`)
            }
            setActiveTabKey(key);
        }
    }

    return <Sider
        className="sidebar-container"
        collapsible
        collapsed={collapsed}
        trigger={null}
    >
        <div className="sidebar-box">
            <div className="sidebar-content">
                {
                    collapsed &&
                    <div className="un-collapse-icon" onClick={() => setCollapsed(false)}>
                        <UnCollapseIcon />
                    </div>
                }
                {
                    !collapsed &&
                    <div className="collapse-icon" onClick={() => setCollapsed(true)}>
                        <CollapseIcon />
                    </div>
                }

                <div className="logo" >
                    {
                        !collapsed ? title : null
                    }
                </div>

                { props?.config?.arrMenus ?
                    <Menu
                        defaultSelectedKeys={[props?.config?.selectedKeys||"profile"]}
                        defaultOpenKeys={[props?.config?.openKeys]}
                        mode="inline"
                        items={props?.config?.arrMenus}
                    />
                    :
                    <Tabs
                        className="sidebar-content-main"
                        tabPosition='bottom'
                        items={items as []}
                        activeKey={activeTabKey}
                        onChange={onChangeTabs}
                    />
                }
            </div>
        </div>
    </Sider>;
}

export default Sidebar;