import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {Modal, notification, Space} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as IconAction } from '../../../resources/images/action_icon.svg';
import { ReactComponent as IconAdd } from '../../../resources/images/plus.svg';

import {buildQueryString} from '../../../utils/utilFunctions'
import {useQuery} from '../../../utils/customHooks'
import CommonTable from '../../../components/Common/Table'
import { DATE_TIME_FORMAT_SECOND, DEFAULT_PAGE_SIZE, ACCOUNT_PAGE_STATUS} from "../../../utils/constants";
import SearchBox from "./SearchBox";
import CommonButton from "../../../components/Common/Button";
import CommonDropdown from "../../../components/Common/Dropdown";
import CommonTag from "../../../components/Common/Tag";
import AccountDrawer from './AccountDrawer';

import accountsServices from "../../../services/users.service";

function Account () {
    const componentPath = "/system-management/account"
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        page: pageQuery,
        pageSize: pageSizeQuery,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: searchQuery,
    } = params
    const page = pageQuery ? parseFloat(pageQuery) : 1;
    const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
    const [data, setData] = useState<any>({});
    const [dataSelected, setDataSelected] = useState<any[]>([]);
    const [currentAccount, setCurrentAccount] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);
    const {profile} = useSelector((state:any) => state?.profileReducer);
    const isCanCreateListAccount = profile?.authorities?.find((item:any)=> item.authority === "ACCOUNT_CREATE");
    const isCanUpdateListAccount = profile?.authorities?.find((item:any)=> item.authority === "ACCOUNT_UPDATE");
    const isCanDeleteListAccount = profile?.authorities?.find((item:any)=> item.authority === "ACCOUNT_DELETE");

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    const getData = async (reload?: boolean) => {
        setIsLoading(true)
        const paramsSearch = {
            page: reload? 0 : page-1,
            size: pageSize,
            sortBy: sortByQuery,
            sortType: sortTypeQuery,
            search: searchQuery,
        }
        const resp = await accountsServices.getAccount(paramsSearch);
        const data = resp?.data;
        if (resp?.status === 200) {
            setData(data?.data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
    }

    const deleteAccount = (type:number) => {
        if (dataSelected?.length === 0) {
            return
        }
        Modal.confirm({
            title: t('common.confirmAction'),
            okText: t('common.button.accept'),
            onOk: async () => {
                let resp;
                if(type === 2){
                    resp = await accountsServices.deleteAccount(dataSelected);
                }else if(type===1){
                    resp = await accountsServices.lockAccount(dataSelected);
                }else{
                    resp = await accountsServices.unlockAccount(dataSelected);
                }

                const data = resp?.data;
                // const headers = resp?.headers;
                if (resp?.status === 200) {
                    if(type === 2){
                        notification.success({
                            message: data?.message || t('permissionsPage.form.message.deleteSuccess'),
                        });
                    }else{
                        notification.success({
                            message: data?.message || t('permissionsPage.form.message.changeSuccess'),
                        });
                    }
                    getData();
                    setDataSelected([]);
                } else {
                    notification.error({
                        message: data?.message || t('commonError.oopsSystem'),
                    });
                }
            },
            onCancel: () => {
                console.log('Cancel');
            },
            cancelText: t('common.button.cancel')
        })
    }

    const onPageChange = (pagination:any, filters:any, sorter:any) => {
        let queryString = buildQueryString({
            ...params,
            page: pageSize === pagination?.pageSize ? pagination?.current : 1,
            pageSize: pagination?.pageSize,
            sortBy: sorter?.order ? sorter?.field : '',
            sortType: sorter?.order ? sorter?.order === 'ascend' ? 'asc' : 'desc' : ''
        })
        navigate(`${componentPath}${queryString || ''}`)
    }

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys:any, selectedRows:any) => {
            // console.log('onChange rowSelection', selectedRowKeys, selectedRows);
            setDataSelected(selectedRowKeys)
        }
    };

    const items = useMemo(() => {
        const children = ACCOUNT_PAGE_STATUS.map((item:any, index:number) => {
            return {
                key: `1-${index}`,
                label: (
                    <div onClick={() => deleteAccount(item.value)}>
                        {t(item.label)}
                    </div>
                ),
            }
        })
        const newItems = [
            isCanUpdateListAccount ? {
                key: '1',
                label: (
                    <div>
                        {t('accountPage.changeStatus')}
                    </div>
                ),
                children:  children,
                placement: "left"
            } : null,
            isCanDeleteListAccount ? {
                key: '2',
                label: (
                    <div onClick={() => deleteAccount(2)}>
                        {t('common.button.remove')}
                    </div>
                ),
            }:null,
        ];
        return newItems?.filter((item:any)=>item)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSelected])

    const columns = [
        {
            title: t('accountPage.list.columns.STT'),
            dataIndex: 'index',
            key: 'index',
            width: '5%',
            align: 'center',
            render: (cell:any, record:any, index:number) => (page - 1) * pageSize + index + 1,
        },
        {
            title: t('accountPage.list.columns.username'),
            dataIndex: 'username',
            key: 'username',
            sorter: true,
            render: (value:any, cell:any) => {
                return <div 
                    className="link" 
                    onClick={()=>{
                        setCurrentAccount(cell);
                        setVisible(true);
                    }}
                >{value || '--'}</div>;
            }
        },
        {
            title: t('accountPage.list.columns.name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true
        },
        {
            title: t('accountPage.list.columns.email'),
            dataIndex: 'email',
            key: 'email',
            sorter: true
        },
        {
            title: t('accountPage.list.columns.groups'),
            dataIndex: 'groups',
            key: 'groups',
            render: (value: any, cell: any, index: number) => {
                let name = "";
                value?.map((item:any, index: number)=> {
                    if(index===0){
                        name+= item.groupName;
                    }else{
                        name+= ", " + item.groupName;
                    }
                    return item;
                })
                return name;
            }
        },
        {
            title: t('accountPage.list.columns.modifiedDate'),
            dataIndex: 'modifiedDate',
            key: 'modifiedDate',
            sorter: true,
            render: (value:any, cell:any) => {
                return  value ? moment(value).format(DATE_TIME_FORMAT_SECOND):"";
            }
        },
        {
            title: t('accountPage.list.columns.modifiedBy'),
            dataIndex: 'modifiedBy',
            key: 'modifiedBy',
            sorter: true
        },
        {
            title: t('accountPage.list.columns.status'),
            dataIndex: 'status',
            key: 'status',
            render: (value:any, cell:any) => {
                const curStatus:any = ACCOUNT_PAGE_STATUS.find((x:any) => x.value === value) || {}
                return <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
            }
        },
    ];

    const onAddSuccessful =()=>{
        setVisible(false);
        setCurrentAccount(undefined);
        let queryString = buildQueryString({
            ...params,
            page: 1,
        }); 
        if (queryString !== search) {    
            navigate(`${queryString || ''}`)
        }else{
            getData(true);
        }
    }

    const resetState = ()=>{
        setVisible(false);
        setCurrentAccount(undefined);
    }

    return <div className='content-wrapper'>
        <SearchBox getData={getData} componentPath={componentPath}/>

        <div className="avic-table-top">
            <div className="avic-table-top-title">
                {t('accountPage.list.title')}
            </div>
            <Space className="avic-table-top-right">
                {   items?.length > 0 ? 
                    <CommonDropdown menu={{ items }}>
                        <CommonButton btnType="primary" size={'small'} className="btn-icon-right">
                            {t('common.button.action')}<IconAction />
                        </CommonButton>
                    </CommonDropdown> : <></>
                }
                {isCanCreateListAccount ? 
                    <CommonButton btnType="primary" size={'small'} className="btn-icon-left" onClick={()=>setVisible(true)}>
                        <IconAdd />{t('common.button.addNew')}
                    </CommonButton> : <></>
                }
                {/* <CommonButton
                    loading={isLoadingExport}
                    onClick={() => exportData()}
                    size={'small'}
                    icon={<ExportOutlined />}
                >
                    {t('common.button.exportExcel')}
                </CommonButton> */}
            </Space>
        </div>

        <CommonTable
            isLoading={isLoading}
            rowKey={'id'}
            dataSource={data?.content || []}
            columns={columns}
            data={data}
            onChange={onPageChange}
            rowSelection={{ ...rowSelection, selectedRowKeys: dataSelected }}
            defaultSorter={{
                order: sortTypeQuery,
                field: sortByQuery,
            }}
        />

        { visible ? 
            <AccountDrawer
                visible={visible}
                onAddSuccessful={onAddSuccessful}
                resetState={resetState}
                currentAccount={currentAccount}
            />
            : <></>
        }
    </div>
}

export default Account;

