import { Layout, Space } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import Language from "../Language";
import MainSidebar from "./Sidebar";
import { ReactComponent as Logo } from "../../resources/images/logo.svg";
import HeaderProfile from "./HeaderProfile";

const { Header, Content } = Layout;

interface LayoutProps {
    children: ReactNode
}

function MainLayout(props:LayoutProps) {
    const { t } = useTranslation();
    const {
        profile
    } = useSelector((state:any) => state?.profileReducer);
    
    return (
        <Layout className={'fixed-sidebar full-height-layout'} style={{ minHeight: '100vh' }}>
            <MainSidebar/>
            <Layout className="main-layout fixed-header">
                <Header>
                    <div className="main-header">
                        <div className="main-header-left">
                            <div className="page-logo"><Logo/></div>
                            <div className="page-title">
                                {t('layout.header.title')}
                            </div>
                        </div>
                        <Space
                            size={20}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                height: "100%"
                            }}
                        >
                            {/* <Language/> */}
                            <div className="main-header-user">
                                <div className="main-header-user-name">{profile?.name}</div>
                                <div className="main-header-user-position">{t('Nhân viên an ninh')}</div>
                            </div>

                            <HeaderProfile />
                        </Space>
                    </div>
                </Header>
                <Content>
                    <div className="main-layout-background">
                        {props.children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default MainLayout;

