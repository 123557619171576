import { useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CommonButton from './../../../components/Common/Button';
import CommonConfirmModal from '../../../components/Common/ConfirmModal';
import PositionCreateModal from './PositionCreateModal';

import positionServices from "../../../services/positions.service";

interface VehicleItemProps{
    itemData: any,
    onOK: () => void
}

const VehicleItem = (props: VehicleItemProps)=>{
    const {t} = useTranslation();
    const { itemData, onOK } = props;
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const { profile } = useSelector((state:any) => state?.profileReducer);
    const isCanUpdateListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_UPDATE");
    const isCanDeleteListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_DELETE");

    const onSuccess = async ()=>{
        setLoadingConfirm(true);

        const resp = await positionServices.deletePosition([itemData?.id]);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t(`Xóa vị trí ${itemData.address||""} thành công!`),
            });
            onOK();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    const handleOk = ()=>{
        setShowDetailModal(false);
        onOK();
    }

    return (
        <>
            <div className='position-item-wrapper'>
                <div className='position-item-header'>
                    <div>
                        {itemData.name} / {itemData.address || "Vị trí"}
                    </div>
                    { itemData?.type !== 100 && itemData?.type !== 200 && isCanUpdateListCategory ?
                        <CommonButton size={'small'} btnType="primary" onClick={()=> setShowDetailModal(true)}> 
                            {t('common.button.Edit')} 
                        </CommonButton> : <></>
                    }
                </div>
                
                <div className='position-item-footer'>
                    <div className={itemData.status === 0 ? 'info':'danger'}>{itemData.status === 0 ? t('Đang hoạt động') : t('Ngừng hoạt động')}</div>
                    { itemData?.type !== 100 && itemData?.type !== 200 && isCanDeleteListCategory ?
                        <CommonButton size={'small'} btnType="danger" onClick={()=>setVisible(true)}> 
                            {t('common.button.remove')} 
                        </CommonButton>: <></>
                    }
                </div>
            </div>

            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content={`Bạn có chắc chắn muốn xóa vị trí "${itemData.address}" này không?`}
                visible={visible}
                onOk={onSuccess}
                loadingBtnOk={loadingConfirm}
            />

            { showDetailModal ?
                <PositionCreateModal
                    dataDetail={itemData}
                    modalStatus={showDetailModal}
                    handleOk={handleOk}
                    handleCancel={()=>{setShowDetailModal(false)}}            
                /> : <></>
            }
        </>
    )
}
export default VehicleItem;