import { InputNumber, InputNumberProps } from "antd";

interface CommonInputNumberProps extends InputNumberProps {
    isView?: boolean
}

const CommonInputNumber = (props:CommonInputNumberProps) => {
    if (props?.isView) return <div className="avic-input-view">{props?.value || '--'}</div>
    const childProps = { ...props };
    delete childProps?.isView;
    return <InputNumber
        {...childProps}
        className={`avic-input ${childProps?.className || ''}`}
    />
}

export default CommonInputNumber;