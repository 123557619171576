import APIService from '../utils/apiServices';

export interface VehicleTypeSearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class VehicleTypeServices extends APIService {
    async getVehicleType(params: VehicleTypeSearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/v1/system/category/car/getPage`, {}, {params: apiParams});
    }

    async createVehicleType(data:any) {
        return await this.request('POST', `/api/v1/system/category/car`, data);
    }

    async updateVehicleType(id:any, data:any) {
        return await this.request('PUT', `/api/v1/system/category/car/${id}`, data);
    }

    async deleteVehicleType(data:number[]) {
        return await this.request('DELETE', `/api/v1/system/category/car`, data);
    }

    async lockVehicleType(data:number[]) {
        return await this.request('POST', `/api/v1/system/category/car/lock`, data);
    }

    async unlockTypeVehicleType(data:number[]) {
        return await this.request('POST', `/api/v1/system/category/car/unlock`, data);
    }

    async exportVehicleType(params: VehicleTypeSearchParams) {
        return await this.request('GET', `/api/v1/system/category/car/exportExcel`, {}, {
            responseType: 'blob',
            params: params,
        });
    }
}
const service = new VehicleTypeServices();
export default service
