import APIService from '../utils/apiServices';

export interface PermissionSearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class PermissionServices extends APIService {
    async getPermission(params: PermissionSearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/v1/system/group/getPage`, {}, {params: apiParams});
    }

    async getPermissionById(id:any) {
        return await this.request('PUT', `/api/v1/system/group/${id}`);
    }

    async createPermission(data:any) {
        return await this.request('POST', `/api/v1/system/group`, data);
    }

    async updatePermission(id:any, data:any) {
        return await this.request('PUT', `/api/v1/system/group/${id}`, data);
    }

    async deletePermission(data:number[]) {
        return await this.request('DELETE', `/api/v1/system/group/delete`, data);
    }

    async lockPermission(data:number[]) {
        return await this.request('POST', `/api/v1/system/group/lock`, data);
    }

    async unlockPermission(data:number[]) {
        return await this.request('POST', `/api/v1/system/group/unlock`, data);
    }

    // lấy tất cả quyền 
    async getFullAuthority() {
        return await this.request('GET', `/api/v1/system/group/getFullAuthority`, {}, {});
    }
}
const service = new PermissionServices();
export default service
