import React from 'react'
import {Avatar} from 'antd';
import {UserOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Camera from "./CameraAntd";

function ProfileSidebar() {
    const { t } = useTranslation();
    const {
        profile
    } = useSelector((state:any) => state?.profileReducer);

    const {userRoleList = [], avatar} = profile

    return <div className="profile-page" style={{marginTop: 0}}>
        <div className="profile-page-sidebar">
            <div className="avatar-box-container">
                    <div className="avatar-box">
                        {
                            avatar
                                ? <Avatar className="avatar" src={avatar} icon={<UserOutlined />} />
                                : <Avatar className="avatar" icon={<UserOutlined />} />
                        }
                        <Camera/>
                    </div>
            </div>


            <div className="profile-info">
                <div className="profile-info-full-name">{profile?.name}</div>
                <div className="profile-info-role">{userRoleList[0]?.position?.positionName}</div>
                <div className='profile-info-row'>
                    <div>
                        <span className='profile-info-account-label'>{t('profilePage.accountProfileTab.label.phoneNumber')}</span>
                        <span className='profile-info-account-value'>{profile?.phone}</span>
                    </div>
                </div>
                <div className='profile-info-row'>
                    <div>
                        <span className='profile-info-account-label'>{t('profilePage.accountProfileTab.label.email')}</span>
                        <span className='profile-info-account-value'>{profile?.email}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ProfileSidebar;

