import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Snackbar } from '@mui/material';
// import { devicesActions, sessionActions } from './store';
import { useEffectAsync } from './utils/customHooks';
import { savePositions } from './redux/actions/positions.actions';
// import { useTranslation } from './common/components/LocalizationProvider';
// import { snackBarDurationLongMs } from './common/util/duration';
// import alarm from './resources/alarm.mp3';
// import { eventsActions } from './store/events';
// import useFeatures from './common/util/useFeatures';
// import { useAttributePreference } from './common/util/preferences';
import LocalStorage from "./utils/localStorage";


const logoutCode = 4000;

const SocketController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const t = useTranslation();

  // const authenticated = useSelector((state) => !!state.session.user);
  // const devices = useSelector((state) => state.devices.items);

  const socketRef = useRef();
  const accessToken = LocalStorage.getInstance().read('accessToken');
  const [events, setEvents] = useState([]);
  // const [notifications, setNotifications] = useState([]);

  // const soundEvents = useAttributePreference('soundEvents', '');
  // const soundAlarms = useAttributePreference('soundAlarms', 'sos');

  // const features = useFeatures();

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    // const socket = new WebSocket(`${protocol}//${process.env.REACT_APP_URL_SOCKET}/api/socket`);
    socketRef.current = socket;

    console.log("socket",socket)

    socket.onopen = () => {
      // dispatch(sessionActions.updateSocket(true));
      console.log("open");
    };

    socket.onclose = async (event) => {
      // dispatch(sessionActions.updateSocket(false));
      console.log("close");

      if (event.code !== logoutCode) {
        try {
          handlePasswordLogin();

          const devicesResponse = await fetch('/api/devices',{
            method: "GET", 
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`
            },
          });
          if (devicesResponse.ok) {
            // dispatch(devicesActions.update(await devicesResponse.json()));
            const dataDevices = await devicesResponse.json();
            console.log("devicesResponse",dataDevices);
          }
          const positionsResponse = await fetch('/api/positions',{
            method: "GET", 
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`
            },
          });
          if (positionsResponse.ok) {
            const dataPositions = await positionsResponse.json();
            dispatch(savePositions(dataPositions));
            console.log("positionsResponse",dataPositions);
          }
          if (devicesResponse.status === 401 || positionsResponse.status === 401) {
            navigate('/');
          }
        } catch (error) {
          // ignore errors
        }
        setTimeout(() => connectSocket(), 60000);
      }
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("data-onmessage",data)
      if (data.devices) {
        // dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        dispatch(savePositions(data.positions));
      }
      if (data.events) {
      //   if (!features.disableEvents) {
      //     dispatch(eventsActions.add(data.events));
      //   }
        setEvents(data.events);
      }
    };
  };

  const handlePasswordLogin = async () => {
    try {
      const response = await fetch('/api/session/1', {
        method: 'POST'
      });
      if (response.ok) {
        const user = await response.json();
        console.log("user", user)
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
    }
  };

  useEffectAsync(async () => {
    // if (authenticated) {
      // const response = await fetch('/api/devices');
      // if (response.ok) {
      //   // dispatch(devicesActions.refresh(await response.json()));
      //   console.log("response",response)
      // } else {
      //   throw Error(await response.text());
      // }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close(logoutCode);
        }
      };
    // }
    // return null;
  },[]);

  useEffect(() => {
    // setNotifications(events.map((event) => ({
    //   id: event.id,
    //   message: event.attributes.message,
    //   show: true,
    // })));
    console.log("events", events);
  }, [events]);

  // useEffect(() => {
  //   events.forEach((event) => {
  //     if (soundEvents.includes(event.type) || (event.type === 'alarm' && soundAlarms.includes(event.attributes.alarm))) {
  //       new Audio(alarm).play();
  //     }
  //   });
  // }, [events, soundEvents, soundAlarms]);



  return (
    <>
      {/* <Snackbar
        key={notification.id}
        open={notification.show}
        message={notification.message}
        autoHideDuration={snackBarDurationLongMs}
        onClose={() => setEvents(events.filter((e) => e.id !== notification.id))}
      /> */}
    </>
  );
};


export default connect()(SocketController);
