import { useTranslation } from "react-i18next";
import { Col, Form, Row, notification } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";

import { ReactComponent as DetailIcon } from "../../../resources/images/eye.svg";

import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonModal from '../../../components/Common/Modal';
import CommonPagination from './../../../components/Common/Pagination';
import CommonTable from "../../../components/Common/Table";
import LockCreateModal from "./LockCreateModal";
import { VEHICLE_STATUS } from "../../../utils/constants";
import VehicleDetailModal from "../../VehicleRegistration/VehicleDetailModal";
import CommonConfirmModal from "../../../components/Common/ConfirmModal";
// import LocalStorage from "../../../utils/localStorage";

import vehicleRegistrationService from "../../../services/vehicleRegistration.service";

interface LockDetailModalProps {
    dataDetail: any,
    modalStatus: boolean,
    handleOk: () => void,
    handleCancel: () => void,
}

const LockDetailModal = (props:LockDetailModalProps) => {
    const [form] = Form.useForm();
    const {modalStatus, handleOk, handleCancel, dataDetail} = props
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const { profile } = useSelector((state:any) => state?.profileReducer);
    const isCanUpdateListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_UPDATE");
    const [page, setPage] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [currentHistory, setCurrentHistory] = useState<any>(undefined);
    const [visible, setVisible] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    
    // const accessToken = LocalStorage.getInstance().read('accessToken');

    const getHistory = useCallback(async ()=>{
        const paramsSearch = {
            page: page-1,
            size: 10,
            search: JSON.stringify({categoryLockId: dataDetail?.id}) ,
        }
        const resp = await vehicleRegistrationService.getVehicleRegistration(paramsSearch);
        const data = resp?.data;
        if (resp?.status === 200) {
            setDataSource(data?.data?.content);
            setTotalElements(data?.data?.totalElements);
        } else {
            setDataSource([]);
            setTotalElements(0);
        }
    },[dataDetail.id, page])
    useEffect(()=>{
        getHistory();
    },[getHistory]);

    const columns: ColumnsType<any> = [
        {
            title: t('lockPage.form.list.STT'),
            key: 'index',
            align: "center",
            width: '5%',
            render: (cell:any, record:any, index:number) => index + 1,
        },
        {
            title: t('lockPage.form.list.detail'),
            key: 'detail',
            align: "center",
            width: '10%',
            render: (cell:any, record:any, index:number) => <DetailIcon 
                                                                className="icon-detail" 
                                                                style={{cursor: "pointer"}} 
                                                                onClick={()=>{
                                                                    setCurrentHistory(record.id);
                                                                    setShowDetail(true);
                                                                }}
                                                            />
        },
        {
            title: t('lockPage.form.list.plateNumber'),
            key:"plateNumber",
            dataIndex: "plateNumber"
        },
        {
            title: t('lockPage.form.list.dateInSEV'),
            key:"dateInStr",
            dataIndex: "dateInStr"
        },
        {
            title: t('lockPage.form.list.status'),
            dataIndex: 'status',
            key: "status",
            render: (cell:any, record:any, index:number) => VEHICLE_STATUS[cell-1].label || "--"
        }
    ];

    const onChangePage = (page: number, pageSize: number)=>{
        console.log(page, pageSize)
        setPage(page);
    }

    useEffect(()=>{
        if(dataDetail.isOffline){
            form.setFieldsValue({
                lockState: "Khóa offline"
            })
        }else{
            form.setFieldsValue({
                lockState: dataDetail?.lockState,
            })
        }

    },[dataDetail.isOffline, dataDetail?.lockState, form])

    console.log("dataDetail",dataDetail)

    const onSuccess = async ()=>{
        setLoadingConfirm(true);
        const response = await fetch(`/api/commands/send`,{
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                // "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                deviceId: dataDetail.id,
                type: "P43"
            })
        });
        console.log("response",response)
        if (response.ok) {
            notification.success({
                message: t(`Mở khóa cho Khóa ${dataDetail.uniqueid||""} thành công!`),
            });
            handleOk();
        } else {
            notification.error({
                message: t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    return(
        <CommonModal
            className="lock-modal-detail"
            title={t('lockPage.form.detailTitle')}
            maskClosable={false}
            open={modalStatus}
            onCancel={handleCancel}
            footer={[
                <CommonButton
                    key="cancel"
                    onClick={handleCancel}
                    size="small"
                >
                    {t('common.button.close')}
                </CommonButton>,
                (!dataDetail.isOffline) ? <CommonButton
                    btnType="warning"
                    size="small"
                    key="unlock"
                    className="btn-icon-left"
                    onClick={()=>{setVisible(true)}}
                >
                    {t('common.button.unlock')}
                </CommonButton> : <></>,
                isCanUpdateListCategory ? <CommonButton
                    btnType="primary"
                    size="small"
                    key="edit"
                    className="btn-icon-left"
                    onClick={()=>setShowEditModal(true)}
                >
                    {t('common.button.edit')}
                </CommonButton> : <></>,
            ]}
        >
            <CommonForm
                form={form}
                layout="vertical"
                initialValues={{
                    IMEINumber: dataDetail.uniqueid,
                    status: dataDetail.statusWork=== 0 ? t('Đang sử dụng') : t('Chưa sử dụng'),
                    lockState: dataDetail?.isOffline ? "Khóa offline" : dataDetail?.lockState,
                    plateNumber: dataDetail?.plateNumber,
                    companyName: dataDetail?.companyName,  
                    driverName: dataDetail?.driverName,  
                    phone: dataDetail?.phone,
                }}
            >
                <Row gutter={30}>
                    <Col span={8}>
                        <CommonFormItem
                            disabled
                            name="IMEINumber"
                            label={t('lockPage.form.label.IMEI')}
                        />
                    </Col>
                    <Col span={8}>
                        <CommonFormItem
                            disabled
                            name="status"
                            label={t('lockPage.form.label.status')}
                        />
                    </Col>
                    <Col span={8}>
                        <CommonFormItem
                            disabled
                            name="lockState"
                            label={t('lockPage.form.label.lockStatus')}
                        />
                    </Col>
                </Row>
                { dataDetail?.lockWarning!=="" ? <div className="lock-warning">
                        {t('lockPage.form.label.lockWarning')} <span className="lock-warning-description">{dataDetail?.lockWarning}</span>
                    </div> : <></>
                }
                <div className="assignment-info">
                    {t('lockPage.form.label.lockInfo')}
                </div>
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            disabled
                            name="plateNumber"
                            label={t('lockPage.form.label.plateNumber')}
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            disabled
                            name="companyName"
                            label={t('lockPage.form.label.companyName')}
                        />
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            disabled
                            name="driverName"
                            label={t('lockPage.form.label.driverName')}
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            disabled
                            name="phone"
                            label={t('lockPage.form.label.phone')}
                        />
                    </Col>
                </Row>
                <div>
                    <div className="list-title">
                        {t('lockPage.form.label.orderDeliveryPositionInfo')}
                    </div>
                    <div className="list-total">{`${t('lockPage.form.total')}: ${totalElements||0}`}</div>
                    
                    <CommonTable
                        scroll={{ y:250 }}
                        pagination={false}
                        rowKey="id"
                        columns={columns}
                        dataSource={dataSource}
                    />

                    { totalElements ? 
                        <div className="lock-pagination">
                            <CommonPagination
                                defaultCurrent={page}
                                current={page} 
                                total={totalElements}
                                pageSize={10}
                                showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}
                                showSizeChanger={false}
                                onChange={onChangePage}
                            />
                        </div>: <></>
                    }
                </div>
            </CommonForm>
            { showEditModal ?
                <LockCreateModal
                    dataDetail={dataDetail}
                    modalStatus={showEditModal}
                    handleOk={()=>{setShowEditModal(false); handleOk();}}
                    handleCancel={()=>{setShowEditModal(false)}}            
                /> : <></>
            }
            { showDetail ?
                <VehicleDetailModal
                    dataDetailId={currentHistory}
                    modalStatus={showDetail}
                    handleOk={()=>{setShowDetail(false); handleOk();}}
                    handleCancel={()=>{setShowDetail(false)}} 
                /> : <></>
            }
            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content={`Bạn có chắc chắn muốn mở khóa cho Khóa "${dataDetail.uniqueid}" này không?`}
                visible={visible}
                onOk={onSuccess}
                loadingBtnOk={loadingConfirm}
            />
        </CommonModal>
    )
}

export default LockDetailModal;