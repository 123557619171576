import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import {Row, Col, Form, Space} from "antd";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import CommonButton from "../../../../components/Common/Button";
import CommonForm from "../../../../components/Common/Form";
import CommonFormItem from '../../../../components/Common/FormItem';
import {buildQueryString} from '../../../../utils/utilFunctions'
import {useQuery} from '../../../../utils/customHooks';


import vehicleTypeServices from "../../../../services/vehicleTypes.service";

function SearchBox(props:any) {
    const componentPath = props?.componentPath
    const navigate = useNavigate();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        search: searchQuery,
    } = params;
    const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {}
    const [vehicleTypeList, setVehicleTypeList] = useState<any[]>([]);
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const getData = async () =>{
        const paramsSearch = {
            page: 0,
            size: 999,
        }
        const resp = await vehicleTypeServices.getVehicleType(paramsSearch);
        const data = resp?.data;

        if (resp?.status === 200) {
            setVehicleTypeList(data?.data?.content?.map((item:any)=> ({label: item?.name, value: item?.id})))
        } else {
            setVehicleTypeList([])
        }
    }

    useEffect(()=>{
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onFinish = (values: any) => {
        onSearch(values)
    }

    const onClear = () => {
        onSearch();
        setTimeout(() => {
            form.resetFields();
        }, 100)
    }

    const onSearch = (data:any = {}) => {
        const dataSearch = {...data};
        if(data?.modifiedDate?.length){
            dataSearch["fromDateLong"] = moment(data?.modifiedDate?.[0]).startOf("day").valueOf();
            dataSearch["toDateLong"] = moment(data?.modifiedDate?.[1]).endOf("day").valueOf();
        }
        delete dataSearch?.modifiedDate;
        let queryString ={ 
            page: 1,
            search: JSON.stringify(dataSearch),
        }
        // detect if query not change => call api
        if (queryString !== search) {
            navigate(`${componentPath}${buildQueryString(queryString) || ''}`)
        } else {
            if (props?.getData) props?.getData()
        }
    }

    return <div className="avic-search-box">
        <div className="advance-search-box">
            <div
                className="close-advance-search-btn cursor-pointer"
                onClick={onClear}
            >
                {/* <div
                >
                    {t('permissionsPage.searchBox.title')}
                </div>
                <CaretUpOutlined
                    className="cursor-pointer"
                /> */}
            </div>

            <CommonForm
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    plateNumber: searchQueryData?.plateNumber,
                    loaixe: searchQueryData?.loaixe,
                    nguoidangky: searchQueryData?.nguoidangky,
                    vipham: searchQueryData?.vipham,
                    imei: searchQueryData?.imei,
                    modifiedDate: (searchQueryData?.fromDateLong && searchQueryData?.toDateLong) ? [moment(searchQueryData?.fromDateLong),moment(searchQueryData?.toDateLong)] : [],
                }}
            >
                <Row gutter={30}>
                    <Col span={8}>
                        <CommonFormItem
                            name="plateNumber"
                            label={t('Biển số')}
                            placeholder={t('Nhập biển số') as string}
                        />
                    </Col>
                    <Col span={8}>
                        <CommonFormItem
                            name='loaixe'
                            label={t('Loại xe')}
                            placeholder={t('Chọn loại xe') as string}
                            type="select"
                            options={vehicleTypeList}
                        />
                    </Col>
                    <Col span={8}>
                        <CommonFormItem
                            name="nguoidangky"
                            label={t('Người đăng ký')}
                            placeholder={t('Nhập người đăng ký') as string}
                        />
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={8}>
                        <CommonFormItem
                            name='vipham'
                            label={t('Vi phạm')}
                            placeholder={t('Chọn vi phạm') as string}
                            type="select"
                            options={[
                                { label: "Không vi phạm" , value: 0},
                                { label: "Có vi phạm" , value: 1}
                            ]}
                        />
                    </Col>
                    <Col span={8}>
                        <CommonFormItem
                            name="imei"
                            label={t('IMEI khóa')}
                            placeholder={t('Nhập IMEI khóa') as string}
                        />
                    </Col>
                    <Col span={8}>
                        <CommonFormItem
                            name='modifiedDate'
                            type='rangePicker'
                            label={t('Thời gian')}
                            placeholder={[t('common.fromDate'), t('common.toDate')]}
                        />
                    </Col>
                </Row>

                <Space className='form-btn-container'>
                    <CommonButton btnType='default' size={'small'} onClick={onClear}>
                        {t('common.button.deleteCondition')}
                    </CommonButton>
                    <CommonButton btnType='primary' size={'small'} htmlType='submit'>
                        {t('common.button.search')}
                    </CommonButton>
                </Space>

            </CommonForm>
        </div>
    </div>
}

export default SearchBox;

