import APIService from '../utils/apiServices';

export interface LockSearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class LockServices extends APIService {
    async getLock(params: LockSearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/v1/system/category/lock/getPage`, {}, {params: apiParams});
    }

    async createLock(data:any) {
        return await this.request('POST', `/api/v1/system/category/lock`, data);
    }

    async updateLock(id:any, data:any) {
        return await this.request('PUT', `/api/v1/system/category/lock/${id}`, data);
    }

    async deleteLock(data:number[]) {
        return await this.request('DELETE', `/api/v1/system/category/lock`, data);
    }

    async lockLock(data:number[]) {
        return await this.request('POST', `/api/v1/system/category/lock/lock`, data);
    }

    async unlockLock(data:number[]) {
        return await this.request('POST', `/api/v1/system/category/lock/unlock`, data);
    }
    
    async exportLock(params: LockSearchParams) {
        return await this.request('GET', `/api/v1/system/category/lock/exportExcel`, {}, {
            responseType: 'blob',
            params: params,
        });
    }
}
const service = new LockServices();
export default service
