import APIService from '../utils/apiServices';

class CommandServices extends APIService {
    // Mở khóa bởi admin
    async unlockByAdminCommand(data:any) {
        return await this.request('POST', `/api/command/unlock-by-admin`, data);
    }

    // async updateCommand(id:any, data:any) {
    //     return await this.request('PUT', `/api/v1/system/category/lock/${id}`, data);
    // }

    // async deleteCommand(data:number[]) {
    //     return await this.request('DELETE', `/api/v1/system/category/lock`, data);
    // }

    // async lockCommand(data:number[]) {
    //     return await this.request('POST', `/api/v1/system/category/lock/lock`, data);
    // }

    // async unlockCommand(data:number[]) {
    //     return await this.request('POST', `/api/v1/system/category/lock/unlock`, data);
    // }
}
const service = new CommandServices();
export default service
