import React from 'react';
import {useNavigate} from "react-router-dom";
import {Row, Col, Form, Space} from "antd";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import CommonButton from "../../../../components/Common/Button";
import CommonForm from "../../../../components/Common/Form";
import CommonFormItem from '../../../../components/Common/FormItem';
import {buildQueryString} from '../../../../utils/utilFunctions'
import {useQuery} from '../../../../utils/customHooks';

function SearchBox(props:any) {
    const componentPath = props?.componentPath
    const navigate = useNavigate();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        search: searchQuery,
    } = params;
    const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {}
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        onSearch(values)
    }

    const onClear = () => {
        onSearch();
        setTimeout(() => {
            form.resetFields();
        }, 100)
    }

    const onSearch = (data:any = {}) => {
        const dataSearch = {...data};
        if(data?.modifiedDate?.length){
            dataSearch["fromDate"] = moment(data?.modifiedDate?.[0]).startOf("day").valueOf();
            dataSearch["toDate"] = moment(data?.modifiedDate?.[1]).endOf("day").valueOf();
        }
        delete dataSearch?.modifiedDate;
        let queryString ={ 
            page: 1,
            search: JSON.stringify(dataSearch),
        }
        // detect if query not change => call api
        if (queryString !== search) {
            navigate(`${componentPath}${buildQueryString(queryString) || ''}`)
        } else {
            if (props?.getData) props?.getData()
        }
    }

    return <div className="avic-search-box">
        <div className="advance-search-box">
            <div
                className="close-advance-search-btn cursor-pointer"
                onClick={onClear}
            >
                {/* <div
                >
                    {t('permissionsPage.searchBox.title')}
                </div>
                <CaretUpOutlined
                    className="cursor-pointer"
                /> */}
            </div>

            <CommonForm
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    username: searchQueryData?.username,
                    fullName: searchQueryData?.fullName,
                    userAgent: searchQueryData?.userAgent,
                    modifiedDate: (searchQueryData?.fromDate && searchQueryData?.toDate) ? [moment(searchQueryData?.fromDate),moment(searchQueryData?.toDate)] : [],
                }}
            >
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            name="username"
                            label={t('Tài khoản')}
                            placeholder={t('Nhập tài khoản') as string}
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            name='fullName'
                            label={t('Họ tên')}
                            placeholder={t('Nhập họ tên') as string}
                        />
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            name="userAgent"
                            label={t('Thiết bị')}
                            placeholder={t('Nhập thiết bị') as string}
                            type="select"
                            options={[
                                { label: "Trình duyệt", value: "Trình duyệt"},
                                { label: "Mobile", value: "Mobile"}
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            name='modifiedDate'
                            type='rangePicker'
                            label={t('Thời gian')}
                            placeholder={[t('common.fromDate'), t('common.toDate')]}
                        />
                    </Col>
                </Row>

                <Space className='form-btn-container'>
                    <CommonButton btnType='default' size={'small'} onClick={onClear}>
                        {t('common.button.deleteCondition')}
                    </CommonButton>
                    <CommonButton btnType='primary' size={'small'} htmlType='submit'>
                        {t('common.button.search')}
                    </CommonButton>
                </Space>

            </CommonForm>
        </div>
    </div>
}

export default SearchBox;

