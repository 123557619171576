import { Layout, Space } from 'antd';
import React, {useMemo, ReactNode} from 'react';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

import {ReactComponent as AccountManagementIcon} from "../../resources/images/user.svg";
import {ReactComponent as DotIcon} from "../../resources/images/circle-solid.svg";
import {ReactComponent as PermissionIcon} from "../../resources/images/permission_icon.svg";
import {ReactComponent as ProfileIcon} from "../../resources/images/user_settings.svg";
import {ReactComponent as ReportIcon} from "../../resources/images/report_icon.svg";
import {ReactComponent as HomeIcon} from "../../resources/images/home.svg";
import { ReactComponent as Logo } from "../../resources/images/logo.svg";

import Sidebar from "./Sidebar";
import {isHavePermissionScene} from "../../utils/utilFunctions";
import HeaderProfile from "./HeaderProfile";
const { Header, Content } = Layout;

interface LayoutProps {
    children: ReactNode
}

function SystemLayout(props:LayoutProps) {
    const defaultPath = "/system-management";
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const {
        profile
    } = useSelector((state:any) => state?.profileReducer);

    const isCanSeeListAccount = profile?.authorities?.find((item:any)=> item.authority === "ACCOUNT_VIEW");
    const isCanSeeListPermission = profile?.authorities?.find((item:any)=> item.authority === "PERMISSION_VIEW");

    // console.log("authorities", profile?.authorities, isCanSeeListPermission, isCanSeeListAccount)

    const items:any = useMemo(() => {
        const newItems = [
            {
                label: t('layout.sidebar.menu.home'),
                key: 'home',
                icon: <div className="sidebar-icon">
                    <HomeIcon />
                </div>,
                onClick: () => {
                    navigate('/?tab=mapTab')
                }
            },
            {
                label: t('layout.sidebar.menu.personalInfo'),
                key: 'profile',
                icon: <div className="sidebar-icon">
                    <ProfileIcon />
                </div>,
                onClick: () => {
                    navigate(`${defaultPath}/profile`)
                }
            },
            isCanSeeListAccount ? {
                label: t('layout.sidebar.menu.accountManagement'),
                key: 'account',
                icon: <div className="sidebar-icon">
                    <AccountManagementIcon />
                </div>,
                onClick: () => {
                    navigate(`${defaultPath}/account`)
                }
            }: null,
            isCanSeeListPermission ? {
                label: t('layout.sidebar.menu.permissionManagement'),
                key: 'permission',
                icon: <div className="sidebar-icon">
                    <PermissionIcon />
                </div>,
                onClick: () => {
                    navigate(`${defaultPath}/permission`)
                }
            }: null,
            {
                label: t('Báo cáo thống kê'),
                key: 'report',
                icon: <div className="sidebar-icon">
                    <ReportIcon />
                </div>,
                children:[
                    {
                        label: t('Dữ liệu xe'),
                        key: 'vehicle-report',
                        icon: <div className="sidebar-icon dot">
                            <DotIcon />
                        </div>,
                        onClick: () => {
                            navigate(`${defaultPath}/vehicle-report`)
                        }
                    },
                    {
                        label: t('Dữ liệu khóa'),
                        key: 'lock-report',
                        icon: <div className="sidebar-icon dot">
                            <DotIcon />
                        </div>,
                        onClick: () => {
                            navigate(`${defaultPath}/lock-report`)
                        }
                    },
                    {
                        label: t('Dữ liệu đăng nhập'),
                        key: 'login-report',
                        icon: <div className="sidebar-icon dot">
                            <DotIcon />
                        </div>,
                        onClick: () => {
                            navigate(`${defaultPath}/login-report`)
                        }
                    }
                ]
            },
        ]
        return newItems?.filter((item:any)=>item)
    }, [isCanSeeListAccount, isCanSeeListPermission, navigate, t]);

    const config = useMemo(() => {
        const pathname = location?.pathname;
        const splitPathname = pathname.split('/')[2]
        const selectedKeys = splitPathname
        let openKeys = "";
        let arrMenus = []

        for (let i = 0; i < items.length; i++) {
            if (Array.isArray(items[i]?.children)) {
                let newChild:any = []
                items[i]?.children.map((child:any) => {
                    if (isHavePermissionScene(child.key, profile) && !child.hidden) {
                        newChild.push(child)
                    }
                    return true;
                })
                if (newChild.length > 0) {
                    arrMenus.push({
                        ...items[i],
                        children: newChild
                    })
                }
            } else {
                if (isHavePermissionScene(items[i].key, profile) && !items[i].hidden) {
                    arrMenus.push({
                        ...items[i],
                    })
                }
            }

            if (items[i].key !== selectedKeys && Array.isArray(items[i]?.children)) {
                const child = items[i].children.find((x:any) => x.key === selectedKeys)
                if (child) {
                    openKeys = items[i].key;
                }
            }
        }

        return {
            selectedKeys,
            openKeys,
            arrMenus,
        }
    }, [items, location?.pathname, profile]);

    return (
        <Layout className={'fixed-sidebar full-height-layout'} style={{ minHeight: '100vh' }}>
            <Sidebar config={config}/>
            <Layout className="main-layout fixed-header">
                <Header>
                    <div className="main-header">
                        <div className="main-header-left">
                            <div className="page-logo"><Logo/></div>
                            <div className="page-title">
                                {t('layout.header.title')}
                            </div>
                        </div>
                        <Space
                            size={20}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                height: "100%"
                            }}
                        >
                            <div className="main-header-user">
                                <div className="main-header-user-name">{profile?.name}</div>
                                <div className="main-header-user-position">{t('Nhân viên an ninh')}</div>
                            </div>

                            <HeaderProfile />
                        </Space>
                    </div>
                </Header>
                <Content>
                    <div className="main-layout-background">
                        {props.children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default SystemLayout;

