import { Form, Row, Col, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CommonForm from './../../components/Common/Form'; 
import CommonFormItem from './../../components/Common/FormItem'; 
import CommonCheckbox from './../../components/Common/Checkbox';
import CommonButton from './../../components/Common/Button';
import { LOCK_WARNING, LOCK_WORK, VEHICLE_STATUS } from '../../utils/constants';
import { useQuery } from '../../utils/customHooks';
import { buildQueryString } from '../../utils/utilFunctions';

const VehicleFilter = (props:any) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj;
    const {
        search: searchQuery,
    } = params

    const onFinish = (values:any) => {
        console.log("values",values)
        onSearch(values)
    }

    const onSearch = (data:any = {}, isClear?:boolean) => {
        const dataSearch = {
            plateNumber: searchQuery ? JSON.parse(searchQuery)?.plateNumber : undefined,
            ...data,
        };
        if(data?.dateIn?.length){
            dataSearch["fromDateLong"] = moment(data?.dateIn?.[0]).startOf("day").valueOf();
            dataSearch["toDateLong"] = moment(data?.dateIn?.[1]).endOf("day").valueOf();
        }
        if(dataSearch?.isViolation){
            dataSearch["isViolation"] = 1;
        }else{
            delete dataSearch?.isViolation;
        }
        if(dataSearch?.isNotViolation){
            dataSearch["isNotViolation"] = 1;
        }else{
            delete dataSearch?.isNotViolation;
        }
        if(dataSearch?.isUnConfirmed){
            dataSearch["isUnConfirmed"] = 1;
        }else{
            delete dataSearch?.isUnConfirmed;
        }
        delete dataSearch?.dateIn;
        let queryString ={ 
            tab: "mapTab",
            page: 1,
            search: JSON.stringify(dataSearch),
        }
        // detect if query not change => call api
        if (queryString !== search) {
            navigate(`${buildQueryString(queryString) || ''}`)
        } else {
            if (props?.getData) props?.getData()
        }
        props?.onHide();
    }

    const onClear = () => {
        onSearch({})
        setTimeout(() => {
            form.resetFields();
        }, 100)
    }

    return (
        <div className="vehicle-registration-filter">
            <CommonForm
                form={form}
                onFinish={onFinish}
                layout="horizontal"
                initialValues={{
                    uniqueid: searchQuery ? JSON.parse(searchQuery)?.uniqueid : undefined,
                    status: searchQuery ? JSON.parse(searchQuery)?.status : undefined,
                    lockStatus: searchQuery ? JSON.parse(searchQuery)?.lockStatus : undefined,
                    lockWarning: searchQuery ? JSON.parse(searchQuery)?.lockWarning : undefined,
                    isViolation: searchQuery ? (JSON.parse(searchQuery)?.isViolation === 1 ? true : false)  : undefined,
                    isNotViolation: searchQuery ? (JSON.parse(searchQuery)?.isNotViolation === 1 ? true : false): undefined,
                    isUnConfirmed: searchQuery ? (JSON.parse(searchQuery)?.isUnConfirmed === 1 ? true : false) : undefined,
                    dateIn: (searchQuery &&  JSON.parse(searchQuery)?.fromDateLong && JSON.parse(searchQuery)?.toDateLong) ? [moment(JSON.parse(searchQuery)?.fromDateLong),moment(JSON.parse(searchQuery)?.toDateLong)] : [],
                }}
            >
                <Row gutter={30}>
                    <Col span={24}>
                        <CommonFormItem
                            name="uniqueid"
                            label={t('vehicleRegistrationPage.searchBox.label.uniqueid')}
                            placeholder={t('vehicleRegistrationPage.searchBox.placeholder.uniqueid') as string}
                        />
                    </Col>
                    <Col span={24}>
                        <CommonFormItem
                            name="status"
                            label={t('vehicleRegistrationPage.searchBox.label.status')}
                            placeholder={t('vehicleRegistrationPage.searchBox.placeholder.status') as string}
                            type="select"
                            options={VEHICLE_STATUS.map((item:any)=>({value: item.value, label: item.label}))}
                        />
                    </Col>
                    <Col span={24}>
                        <CommonFormItem
                            name="lockStatus"
                            label={t('vehicleRegistrationPage.searchBox.label.lockStatus')}
                            placeholder={t('vehicleRegistrationPage.searchBox.placeholder.lockStatus') as string}
                            type="select"
                            options={LOCK_WORK}
                        />
                    </Col>
                    
                    <Col span={24}>
                        <CommonFormItem
                            name="lockWarning"
                            label={t('vehicleRegistrationPage.searchBox.label.lockWarning')}
                            placeholder={t('vehicleRegistrationPage.searchBox.placeholder.lockWarning') as string}
                            type="select"
                            options={LOCK_WARNING}
                        />
                    </Col>
                    <Col span={24}>
                        <Row gutter={0}>
                            <Col span={12}>
                                <CommonFormItem
                                    name="isViolation"
                                    label={t('vehicleRegistrationPage.searchBox.label.violationVehicle')}
                                    valuePropName="checked"
                                >
                                    <CommonCheckbox/>
                                </CommonFormItem>
                            </Col>
                            <Col span={12}>
                                <CommonFormItem
                                    name="isNotViolation"
                                    label={t('vehicleRegistrationPage.searchBox.label.notViolationVehicle')}
                                    valuePropName="checked"
                                >
                                    <CommonCheckbox/>
                                </CommonFormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <CommonFormItem
                            name="isUnConfirmed"
                            label={t('vehicleRegistrationPage.searchBox.label.unconfirmedVehicle')}
                            valuePropName="checked"
                        >
                            <CommonCheckbox/>
                        </CommonFormItem>
                    </Col>
                    <Col span={24}>
                        <div className="search-label">
                            {t('vehicleRegistrationPage.searchBox.label.dateInSEV')}
                        </div>
                        <CommonFormItem
                            name="dateIn"
                            type="rangePicker"
                            placeholder={[t('common.fromDate'),t('common.toDate')]}
                        />
                    </Col>
                </Row>
                <Space className="form-btn-container">
                    <CommonButton onClick={onClear} className="btn-clear">
                        {t('common.button.deleteCondition')}
                    </CommonButton>
                    <CommonButton btnType="primary" htmlType="submit">
                        {t('common.button.showResults')}
                    </CommonButton>
                </Space>
            </CommonForm>
        </div>
    )
}

export default VehicleFilter;