import React from "react";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";

import MapControls from "./components/MapControls";
import SocketController from '../../SocketController';

function MyMapComponent() {
    const location:LatLngExpression = [21.02840045, 105.77753169273271];

    return (
        <>
            <SocketController />
            <MapContainer
                center={location}
                zoom={16}
                scrollWheelZoom={true}
            >
                {/* <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                /> */}
                <LayersControl>
                    <LayersControl.Overlay checked name="OpenStreetMap">
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked name="Satellite View">
                        <TileLayer
                            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                            maxZoom= {20}
                            subdomains={['mt1','mt2','mt3']}
                        />
                    </LayersControl.Overlay>
                    </LayersControl>
                <MapControls/>
            </MapContainer>
        </>
    )
}

export default MyMapComponent;
