import { Col, Form, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonModal from '../../components/Common/Modal';
import CommonTable from "../../components/Common/Table";
import VehicleCreateModal from "./VehicleCreateModal";
import { LOCK_WORK, VEHICLE_STATUS } from "../../utils/constants";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import { DATE_TIME_FORMAT } from "../../utils/constants";
import CommonSelect from './../../components/Common/Select';

import commandService from "../../services/commands.service";
import vehicleRegistrationService from "../../services/vehicleRegistration.service";
import positionServices from "../../services/positions.service";
import AssignIMEIModal from "./AssignIMEIModal";

interface VehicleDetailModalProps {
    dataDetailId: number,
    modalStatus: boolean,
    handleOk: () => void,
    handleCancel: () => void
}

interface DataType {
    key: string;
    receivingOrder: string;
    route: string;
    dateTime: string;
    account: string;
    note: string;
}

const VehicleDetailModal = (props:VehicleDetailModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const {modalStatus, handleOk, handleCancel, dataDetailId} = props;
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showAssignIMEIModal, setShowAssignIMEIModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [visibleCancel, setVisibleCancel] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [dataDetail, setDataDetail] = useState<any>(undefined);
    const [violationData, setViolationData] = useState<any[]>([]);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [currentLock, setCurrentLock] = useState<any>(undefined);
    const [positionSelectData, setPositionSelectData] = useState<any[]>([]);
    const [unlockTextWarning, setUnlockTextWarning] = useState<string>("");
    const { profile } = useSelector((state:any) => state?.profileReducer);
    const [ positionIdUnlock, setPositionIdUnlock] = useState<any>(undefined);
    const [isOffline, setIsOffline] = useState<boolean>(false);
    const [ companyDeliverySitesIdUnlock, setCompanyDeliverySitesIdUnlock] = useState<number|undefined>(undefined);
    const { positions } = useSelector((state:any) => state?.positionsReducer);

    const getDataDetail = async () => {
        const resp = await vehicleRegistrationService.getByIdVehicleRegistration(dataDetailId)
        const data = resp?.data;
        if (resp?.status === 200) {
            setDataDetail(data?.data);
        } else {
            setDataDetail(undefined)
        }
    }
    useEffect(()=>{
        getDataDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(dataDetail){
            form.setFieldsValue({
                    plateNumber: dataDetail?.plateNumber,
                    categoryCar: dataDetail?.categoryCar?.name,
                    gateIn:  dataDetail?.companyDeliverySites?.[0]?.categoryPosition?.name,
                    dateInStr: dataDetail?.dateInStr,
                    status: VEHICLE_STATUS[dataDetail?.status-1]?.label,
                    createBy: dataDetail?.createBy,
            });
            setCurrentLock(positions.find((item:any)=> item.deviceId===dataDetail?.categoryLock?.id))
            setDataSource(dataDetail?.companyDeliverySites?.map(
                (item:any, index:number)=>({
                    key: item?.id,
                    receivingOrder: (index !== 0 && index !== dataDetail?.companyDeliverySites.length - 1) && item?.orderNumber,
                    route: `${item?.categoryPosition?.name} / ${item?.categoryPosition?.address}`,
                    dateTime: item?.unlockTime ? moment(item?.unlockTime).format(DATE_TIME_FORMAT) : undefined,
                    account: item?.unlockUser?.username,
                    note: item?.note,
            })));
        }else{
            setDataSource([]);
        }
    },[dataDetail, form, positions])

    const getDataSelect = async () => {
        const paramsSearch = {
            page: 0,
            size: 1000,
            search: JSON.stringify({status: 0}) ,
        }
        //select vị trí
        const respPosition = await positionServices.getPosition(paramsSearch);
        const dataPosition = respPosition?.data;
        if (respPosition?.status === 200) {
            setPositionSelectData(dataPosition?.data?.content?.map((item:any)=> ({value:item.id, label: item.name+" - "+item.address})));
        } else {
            setPositionSelectData([]);
        }
    }
    useEffect(()=>{
        getDataSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(()=>{
        if(dataDetail){
            form.setFieldsValue({
                    plateNumber: dataDetail?.plateNumber,
                    categoryCar: dataDetail?.categoryCar?.name,
                    gateIn:  dataDetail?.companyDeliverySites?.[0]?.categoryPosition?.name,
                    dateInStr: dataDetail?.dateInStr,
                    status: VEHICLE_STATUS[dataDetail?.status-1]?.label,
                    createBy: dataDetail?.createBy,
                    IMEI: dataDetail?.categoryLock?.uniqueid
            });
            setCurrentLock(positions.find((item:any)=> item.deviceId===dataDetail?.categoryLock?.id))
            setDataSource(dataDetail?.companyDeliverySites?.map(
                (item:any, index:number)=>({
                    key: item?.id,
                    receivingOrder: (index !== 0 && index !== dataDetail?.companyDeliverySites.length - 1) && item?.orderNumber,
                    route: `${item?.categoryPosition?.name} / ${item?.categoryPosition?.address}`,
                    dateTime: item?.unlockTime ? moment(item?.unlockTime).format(DATE_TIME_FORMAT) : undefined,
                    account: item?.unlockUser?.username,
                    note: item?.note,
            })));
        }else{
            setDataSource([]);
        }
    },[dataDetail, form, positions])

    const getDataViolation = async () => {
        const resp = await vehicleRegistrationService.getViolationByRegistrationCarId(dataDetailId)
        const data = resp?.data;
        if (resp?.status === 200) {
            setViolationData(data?.data)
        } else {
            setViolationData([])
        }
    }
    useEffect(()=>{
        getDataViolation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // Check cảnh báo khóa
    useEffect(()=>{
        let warningText:string[] = [];
        if(currentLock?.mucpin <=5){
            warningText.push("Hết pin")
        }
        if(currentLock?.donglaplung ===0){
            warningText.push("Chưa đóng lắp lưng")
        }
        if(currentLock?.dongdaycap ===0){
            warningText.push("Đang tháo dây")
        }
        if(currentLock?.motoloi === 1){
            warningText.push("Mô tô lỗi")
        }
        if(isOffline){
            warningText.push("Khóa offline")
        }
        form.setFieldsValue({
            lockStatus:  isOffline ? "Khóa offline" : LOCK_WORK[currentLock?.khoathietbi]?.label || "",
            lockWarning: warningText.join(", ")
        })

        const checkOffline = setInterval(function check(){
            var startTime = moment(currentLock?.serverTime);
            var endTime = moment();
            var duration = moment.duration(endTime.diff(startTime));
            var minutes = Math.round(duration.asMinutes() % 60);
            console.log(minutes + ' minutes. chi tiết đăng ký vào SEV');
            if(minutes>3){
                setIsOffline(true);
            }else{
                setIsOffline(false);
            }
            return check
        }(),60000)
        
        return ()=> clearInterval(checkOffline);

    },[currentLock, form, isOffline]) 
    
    // check để mở khóa
    const unlockBtnOnClick = () => {
        const newArr = [...dataDetail?.companyDeliverySites];
        const gateOut = newArr.pop() //lấy, xóa cổng ra
        const checkGateOut = newArr.find((item:any)=> !item?.unlockTime);
        if(!positionIdUnlock){
            notification.error({
                message: t('Vị trí mở khóa không được bỏ trống.'),
            });
            return;
        }else if(gateOut?.categoryPosition?.id === positionIdUnlock && checkGateOut){
            notification.error({
                message: t('Phải mở khóa hết ở các vị trí khác trước khi mở khóa ở cổng ra.'),
            });
            return
        }else{
            setVisible(true);
        }

    }

    // mở khóa bởi admin
    const unLockByAdmin = async ()=>{
        setIsLoading(true);
        const dataSubmit:any = {
            registerCarId: dataDetail?.id,
            deviceId: dataDetail?.categoryLock?.id,
            userId: profile?.id,
            positionId: positionIdUnlock,
        }
        if(companyDeliverySitesIdUnlock){
            dataSubmit['companyDeliverySitesId'] = companyDeliverySitesIdUnlock;
            dataSubmit['type'] = 50;
        }else{
            dataSubmit['type'] = 100;
        }
        const resp = await commandService.unlockByAdminCommand(dataSubmit);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t('Mở khóa thành công!'),
            });
            setVisible(false);
            handleOk();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    }

    // Hủy
    const onCancelVehicleRegistration = async ()=>{
        setLoadingConfirm(true);
        const resp = await vehicleRegistrationService.cancelVehicleRegistration(dataDetailId);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t(`Hủy giao hàng Biển số xe: ${dataDetail?.plateNumber} thành công!`),
            });
            setVisibleCancel(false);
            handleOk();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    // Hoàn thành
    const onCompleteVehicleRegistration = async ()=>{
        setLoadingConfirm(true);
        const resp = await vehicleRegistrationService.completeVehicleRegistration(dataDetailId);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t(`Hoàn thành giao hàng Biển số xe: ${dataDetail?.plateNumber} thành công!`),
            });
            setVisibleCancel(false);
            handleOk();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    const columns: ColumnsType<DataType> = [
        {
            title: t('vehicleRegistrationPage.form.list.orderDeliveryPosition'),
            key: 'receivingOrder',
            dataIndex: 'receivingOrder',
            align: "center",
            
        },
        {
            title: t('vehicleRegistrationPage.form.list.route'),
            dataIndex: 'route',
            key: 'route',
        },
        {
            title: t('vehicleRegistrationPage.form.list.time'),
            key:"dateTime",
            dataIndex: "dateTime",
        },
        {
            title: t('vehicleRegistrationPage.form.list.account'),
            dataIndex: 'account',
            key: "account",
        },
        {
            title: t('vehicleRegistrationPage.form.list.note'),
            dataIndex: 'note',
            key: "note",
        }
    ];

    const onChangePositionUnlock = (value:any)=>{
        const checkPosition = dataDetail?.companyDeliverySites?.find((item:any)=>item?.categoryPosition?.id===value);
        console.log("value", value, "\nCheck",checkPosition);
        if(!checkPosition){
            setUnlockTextWarning("Giao hàng sai vị trí");
            setCompanyDeliverySitesIdUnlock(undefined);
        }else{
            setUnlockTextWarning("");
            setCompanyDeliverySitesIdUnlock(checkPosition?.id)
        }
        setPositionIdUnlock(value)
    }

    console.log("dataDetail",dataDetail)

    const footer = [
        <CommonButton key="close" onClick={handleCancel}size="small" >
            {t('common.button.close')}
        </CommonButton>,
            (!dataDetail?.categoryLock?.id && dataDetail?.status===1) ? <CommonButton
            key="assignIMEI"
            btnType="warning"
            size="small"
            className="btn-icon-left"
            onClick={()=>{setShowAssignIMEIModal(true);}}
        >
            {t('common.button.assignIMEI')}
        </CommonButton>: null,
        (!dataDetail?.categoryLock?.id && dataDetail?.status===1) ? <CommonButton
            key="cancel"
            btnType="danger"
            size="small"
            className="btn-icon-left"
            onClick={()=>{setVisibleCancel(true); setIsComplete(false);}}
        >
            {t('common.button.cancel')}
        </CommonButton>: null,
        (dataDetail?.status!==3 && dataDetail?.status!==4 && dataDetail?.status!==5 ) ? <CommonButton
            form="myForm"
            key="submit"
            onClick={()=>setShowCreateModal(true)}
            btnType="primary"
            size="small"
            className="btn-icon-left"
        >
            {t('common.button.edit')}
        </CommonButton> : null,
        (dataDetail?.status===3) ? <CommonButton
            key="success"
            btnType="success"
            size="small"
            className="btn-icon-left"
            onClick={()=>{setVisibleCancel(true); setIsComplete(true);}}
        >
            {t('common.button.success')}
        </CommonButton> : null,
    ];

    return(
        <CommonModal
            className="vehicle-modal-detail"
            title={t('vehicleRegistrationPage.form.detailTitle')}
            open={modalStatus}
            onCancel={handleCancel}
            footer={footer}
        >
            <CommonForm
                form={form}
                layout="vertical"
            >
                <div>
                    <Row gutter={10}>
                        <Col span={9}>
                            <CommonFormItem
                                name="plateNumber"
                                label={t('vehicleRegistrationPage.form.label.plateNumber')}
                                disabled
                            />
                        </Col>
                        <Col span={3}></Col>
                        <Col span={9}>
                            <CommonFormItem
                                name="categoryCar"
                                label={t('vehicleRegistrationPage.form.label.vehicleType')}
                                disabled
                            />
                        </Col>
                        <Col span={3}>
                            <CommonFormItem
                                name="timeForAllow"
                                label=' '
                                disabled
                            >
                                <span className="time-of-vehicle-type">{`${dataDetail?.categoryCar?.timeForAllow || 0} ${t('common.minute')}`}</span>
                            </CommonFormItem>
                        </Col>
                    </Row>
                    <Row gutter={10}>  
                        <Col span={9}>
                            <CommonFormItem
                                name="gateIn"
                                label={t('vehicleRegistrationPage.form.label.gateIn')}
                                disabled
                            />
                        </Col>
                        <Col span={3}></Col>
                        <Col span={9}>
                            <CommonFormItem
                                name="dateInStr"
                                label={t('vehicleRegistrationPage.form.label.dateInSEV')}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>  
                        <Col span={9}>
                            <CommonFormItem
                                name="status"
                                label={t('vehicleRegistrationPage.form.label.status')}
                                disabled
                            />
                        </Col>
                        <Col span={3}></Col>
                        <Col span={9}>
                            <CommonFormItem
                                name="lockStatus"
                                label={t('vehicleRegistrationPage.form.label.lockStatus')}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>  
                        <Col span={9}>
                            <CommonFormItem
                                name="lockWarning"
                                label={t('vehicleRegistrationPage.form.label.lockWarning')}
                                disabled
                            />
                        </Col>
                        <Col span={3}></Col>
                        <Col span={9}>
                            <CommonFormItem
                                name="createBy"
                                label={t('vehicleRegistrationPage.form.label.createBy')}
                                disabled
                            />
                        </Col>
                    </Row>
                    { dataDetail?.categoryLock?.id ? <Row gutter={10}>  
                        <Col span={9}>
                            <CommonFormItem
                                name="IMEI"
                                label={t('vehicleRegistrationPage.form.label.assignIMEI')}
                                disabled
                            />
                        </Col>
                    </Row> : <></> 
                    }
                </div>
                { violationData?.length ?
                    <div>
                        <div className="list-title">
                            {t('vehicleRegistrationPage.form.label.violationInfo')} 
                            <span className="list-title-time">
                                {`(${t('vehicleRegistrationPage.form.label.timeInSEV')}: 90 ${t('common.minute')} / ${dataDetail?.categoryCar?.timeForAllow} ${t('common.minute')})`}
                            </span>
                        </div>
                        <div className="list-violation-info">
                            {violationData?.map((item:any,index:number) =>{
                                return <Row key={index}>
                                <Col span={6}>{item?.modifiedDateStr}</Col>
                                <Col span={6} style={{fontWeight: 700}}>{item?.categoryPositionName}</Col>
                                <Col span={12}>{item?.description}</Col>
                            </Row>
                            })}
                        </div>
                    </div> : <></>
                }
                
                { (dataDetail?.categoryLock?.id && (dataDetail?.status===2||dataDetail?.status===3)) ?
                        <div>
                            <div className="list-title">
                                {t('vehicleRegistrationPage.form.label.unlockPosition')}
                            </div>
                            <div className="list-position-unlock">
                                <Row>
                                    <Col span={6}>
                                        <CommonSelect
                                            style={{width: '90%'}}
                                            placeholder={t("Chọn vị trí mở khóa") as string}
                                            options={positionSelectData}
                                            onChange={onChangePositionUnlock}
                                            showSearch
                                            allowClear
                                            disabled={isOffline}
                                        />
                                    </Col>
                                    <Col span={8} style={{display: "flex", alignItems: "center"}}>{unlockTextWarning}</Col>
                                    <Col span={6}>
                                        <CommonButton disabled={isOffline} size="small" btnType="warning" onClick={unlockBtnOnClick}>
                                            {t("common.button.unlock")}
                                        </CommonButton>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    : <></>
                }
                <div>
                    <div className="list-title">
                        {t('vehicleRegistrationPage.form.label.orderDeliveryPositionInfo')}
                    </div>
                    <div className="list-total">{`${t('vehicleRegistrationPage.form.total')} ${dataSource?.length - 2 } ${t('vehicleRegistrationPage.form.deliveryLocation')}`}</div>
                    
                    <CommonTable
                        scroll={{ y: 250 }}
                        pagination={false}
                        rowKey="key"
                        columns={columns}
                        dataSource={dataSource}
                    />
                </div>
            </CommonForm>
            
            { showCreateModal ?
                <VehicleCreateModal
                    dataDetail={dataDetail}
                    modalStatus={showCreateModal}
                    handleOk={()=>{setShowCreateModal(false); handleOk();}}
                    handleCancel={()=>{setShowCreateModal(false)}}            
                /> : <></>
            }
            
            { showAssignIMEIModal ?
                <AssignIMEIModal
                    dataDetail={dataDetail}
                    modalStatus={showAssignIMEIModal}
                    handleOk={()=>{setShowAssignIMEIModal(false); getDataDetail();}}
                    handleCancel={()=>{setShowAssignIMEIModal(false)}}            
                /> : <></>
            }


            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content={`Bạn có chắc chắn muốn mở khóa ở vị trí này không?`}
                visible={visible}
                onOk={unLockByAdmin}
                loadingBtnOk={isLoading}
            />
            <CommonConfirmModal
                onCancel={() => { setVisibleCancel(false) }}
                showCancel={true}
                showOk={true}
                content={ isComplete ? `Bạn có chắc chắn muốn Hoàn thành giao hàng Biển số xe: "${dataDetail?.plateNumber}" không?`
                    :`Bạn có chắc chắn muốn Hủy giao hàng Biển số xe: "${dataDetail?.plateNumber}" không?`
                }
                visible={visibleCancel}
                onOk={()=>{
                    if(isComplete){
                        onCompleteVehicleRegistration();
                    }else{
                        onCancelVehicleRegistration();
                    }
                }}
                loadingBtnOk={loadingConfirm}
            />
        </CommonModal>
        
    )
}

export default VehicleDetailModal;