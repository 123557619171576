import React, { useCallback, useEffect, useState } from "react";
import { Form, Row, Col, notification } from "antd";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

// import { ReactComponent as AddIcon } from "../../resources/images/add-square-icon.svg";
import { ReactComponent as ExportIcon } from "../../resources/images/export-square-icon.svg";
import { ReactComponent as Search} from '../../resources/images/search-1.svg';

import CommonEmpty from './../../components/Common/Empty';
import CommonForm from './../../components/Common/Form';
import CommonFormItem from './../../components/Common/FormItem';
import CommonPagination from './../../components/Common/Pagination';
import NotifyItem from './NotifyItem';
import CommonInput from './../../components/Common/Input';
import CommonSpin from "../../components/Common/Spin";
import { useQuery } from "../../utils/customHooks";

import notifyServices from "../../services/notifies.service";
import { buildQueryString } from "../../utils/utilFunctions";

const Notify = ()=>{
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        page: pageQuery,
        search: searchQuery,
    } = params;
    const [data, setData] = useState<any>();
    const page = pageQuery ? parseFloat(pageQuery) : 1;
    // const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getData = useCallback( async (reload?: boolean) =>{
        setIsLoading(true);
        const paramsSearch = {
            page: reload ? 0 : page-1,
            search: searchQuery ? 
                        JSON.stringify({
                            ...JSON.parse(searchQuery),
                            module: "LOCK"
                        }) : 
                        JSON.stringify({
                            module: "LOCK"
                        }),
        }
        const resp = await notifyServices.getNotify(paramsSearch);
        const data = resp?.data;

        if (resp?.status === 200) {
            console.log("data?.data",data?.data)
            setData(data?.data)
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    },[page, searchQuery, t])

    useEffect(()=>{
        getData();
    },[getData])

    const onChangePage = (page: number, pageSize: number)=>{
        let queryString = buildQueryString({
            ...params,
            page: page,
        })
        navigate(`${queryString || ''}`);
    }

    const onSearch = (values:any)=>{
        let queryString = buildQueryString({
            tab: "categoryTab",
            subTab: "lockTab",
            page: 1,
            search: JSON.stringify({
                ...form.getFieldsValue()
            }),
        })
        if (queryString !== search) {    
            navigate(`${queryString || ''}`)
        } 
    }

    const onResetList = ()=>{
        let queryString = buildQueryString({
            ...params,
            page: 1,
        }); 
        if (queryString !== search) {  
            navigate(`${queryString || ''}`)
        }
        getData(true);
    }
    
    // xuất excel
    const exportData = async () => {
        // if(!isLoadingExcel){
        //     setIsLoadingExcel(true);
        //     const paramsSearch = {
        //         page:0,
        //         size: 10000,
        //         search: (searchQuery) || JSON.stringify({})
        //     }
        //     const resp = await lockServices.exportLock(paramsSearch);
        //     const data = resp?.data;
        //     if (resp?.status === 200) {
        //         const fileName = `Lock_Data_Export_${moment().format('YYYYMMDD')}_${moment().unix()}.xlsx`
        //         saveAs(data, fileName);
        //     } else {
        //         notification.error({
        //             message: data?.message || t('commonError.oopsSystem'),
        //         });
        //     }
        //     setIsLoadingExcel(false);
        // }
    }

    return (<div className='page-sidebar-container'>
        <div className="notify-page">
            <div className="notify-form">
                <CommonForm
                    form={form}
                    onFieldsChange={debounce(onSearch,300)}
                    layout="horizontal"
                    initialValues={{
                        status: 0
                    }}
                >
                    <Row>
                        <Col span={21}>
                            <CommonFormItem name="keyword">
                                <CommonInput
                                    placeholder={t('notifyPage.searchBox.placeholder.plateNumber') as string}
                                    prefix={<Search className='prefix-icon'/> }
                                />
                            </CommonFormItem>
                        </Col>
                        <Col span={3}>
                            <div className="group-icon">
                                {/* <AddIcon /> */}
                                <ExportIcon  onClick={()=>exportData()}/>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row gutter={6}>
                        <Col span={15}>
                            <CommonFormItem
                                name="notifyType"
                                placeholder={t('notifyPage.searchBox.placeholder.notifyType') as string}
                                type="select"
                                options={[
                                    {label: "Mở khóa", value: 0},
                                    {label: "Hoàn thành", value: 1},
                                    {label: "Khóa hết pin", value: 2},
                                    {label: "Tín hiệu mạng 4G kém", value: 3},
                                    {label: "Tín hiệu GPS kém", value: 4},
                                    {label: "Cảnh báo dừng đỗ (xe)", value: 5},
                                    {label: "Cảnh báo quá tốc độ", value: 6},
                                    {label: "Backcover của khóa bị mở", value: 7},
                                    {label: "Xe giao hàng sai vị trí", value: 8},
                                    {label: "Xe giao hàng quá thời gian", value: 9},
                                ]}
                            />
                        </Col>
                        <Col span={9}>
                            <CommonFormItem
                                name="status"
                                placeholder={t('notifyPage.searchBox.placeholder.status') as string}
                                type="select"
                                options={[
                                    {label: "Chưa đọc", value: 0},
                                    {label: "Đã đọc", value: 1},
                                ]}
                            />
                        </Col>
                    </Row> */}
                </CommonForm>
            </div>

            <div className="notify-total">
                <div>{`${t('notifyPage.total')}: ${data?.totalElements} ${t('notifyPage.notify')}`}</div>
            </div>

            <div className="notify-list">
                {/* <CommonSpin isLoading={isLoading}>
                    { data?.content?.length ? <div>
                            {new Array(6).fill(null).map((_, i) => {
                                    const id = String(i + 1);
                                    return <NotifyItem 
                                                key={id} 
                                                itemId={parseInt(id)} 
                                            />
                                })}
                            </div> 
                        : <></>
                    }
                </CommonSpin>
                
                { !(!!data?.totalElements) ? <CommonEmpty/> : <></> } */}

                <CommonSpin isLoading={isLoading}>
                    { data?.content?.length ? <div>
                                    {data?.content.map((item:any, index:number) => {
                                        return <NotifyItem 
                                                    key={item?.id} 
                                                    itemData={item} 
                                                    onOK={onResetList}
                                                />
                                    })}
                            </div> 
                        : <></>
                    }
                </CommonSpin>
                
                { !(!!data?.totalElements) ? <CommonEmpty/> : <></> }
                
            </div>

            { true ? <div className="notify-pagination">
                    <CommonPagination
                        defaultCurrent={1} 
                        total={data?.totalElements}
                        current={page}
                        pageSize={10}
                        showSizeChanger={false}
                        onChange={onChangePage}
                    />
                </div> : null
            }
        </div>
    </div>)

    
}

export default Notify;