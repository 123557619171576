import { useCallback, useEffect, useState } from "react";
import { Form, Row, Col, notification } from "antd";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from "lodash";
import { saveAs } from 'file-saver';
import moment from "moment";
import { useSelector } from "react-redux";

import { ReactComponent as AddIcon } from "../../../resources/images/add-square-icon.svg";
import { ReactComponent as ExportIcon } from "../../../resources/images/export-square-icon.svg";

import CommonEmpty from './../../../components/Common/Empty';
import CommonForm from './../../../components/Common/Form';
import CommonFormItem from './../../../components/Common/FormItem';
import CommonPagination from './../../../components/Common/Pagination';
import VehicleTypeItem from './VehicleTypeItem';
import VehicleTypeCreateModal from "./VehicleTypeCreateModal";
import { useQuery } from "../../../utils/customHooks";
import CommonSpin from "../../../components/Common/Spin";
import { buildQueryString } from "../../../utils/utilFunctions";

import vehicleTypeServices from "../../../services/vehicleTypes.service";

const VehicleType = ()=>{
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        page: pageQuery,
        search: searchQuery,
    } = params;
    const page = pageQuery ? parseFloat(pageQuery) : 1;
    const { profile } = useSelector((state:any) => state?.profileReducer);
    const isCanCreateListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_CREATE");

    const getData = useCallback( async (reload?: boolean) =>{
        setIsLoading(true);
        const paramsSearch = {
            page: reload? 0 : page-1,
            search: (searchQuery) ,
        }
        const resp = await vehicleTypeServices.getVehicleType(paramsSearch);
        const data = resp?.data;

        if (resp?.status === 200) {
            setData(data?.data);
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    },[page, searchQuery, t])

    useEffect(()=>{
        getData();
    },[getData])

    const onChangePage = (page: number, pageSize: number)=>{
        let queryString = buildQueryString({
            ...params,
            page: page,
        })
        navigate(`${queryString || ''}`);
    }

    const onSearch = (values:any)=>{
        let queryString = buildQueryString({
            tab: "categoryTab",
            subTab: "vehicleTypeTab",
            page: 1,
            search: JSON.stringify({
                ...form.getFieldsValue()
            }),
        })
        if (queryString !== search) {    
            navigate(`${queryString || ''}`)
        } 
    }

    const onResetList = ()=>{
        let queryString = buildQueryString({
            ...params,
            page: 1,
        }); 
        if (queryString !== search) {   
            navigate(`${queryString || ''}`)
        }
        getData(true);
    }

    // xuất excel
    const exportData = async () => {
        if(!isLoadingExcel){
            setIsLoadingExcel(true);
            const paramsSearch = {
                page:0,
                size: 10000,
                search: (searchQuery) || JSON.stringify({})
            }
            const resp = await vehicleTypeServices.exportVehicleType(paramsSearch);
            const data = resp?.data;
            if (resp?.status === 200) {
                const fileName = `Vehicle_Type_Data_Export_${moment().format('YYYYMMDD')}_${moment().unix()}.xlsx`
                saveAs(data, fileName);
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
            setIsLoadingExcel(false);
        }
    }

    return (<div className='page-sidebar-container'>
        <div className="vehicle-type-category-page">
            <div className="vehicle-type-category-form">
                <CommonForm
                    form={form}
                    onFieldsChange={debounce(onSearch,300)}
                    layout="horizontal"
                    initialValues={{
                        name:  searchQuery ? JSON.parse(searchQuery)?.name : undefined,
                        status: searchQuery ? JSON.parse(searchQuery)?.status : undefined,
                    }}
                >
                    <Row>
                        <Col span={isCanCreateListCategory ? 18 : 20}>
                            <Row gutter={6}>
                                <Col span={12}>
                                    <CommonFormItem
                                        name="name"
                                        placeholder={t('vehicleTypePage.searchBox.placeholder.vehicleType') as string}
                                    />
                                </Col>
                                <Col span={12}>
                                    <CommonFormItem
                                        name="status"
                                        placeholder={t('vehicleTypePage.searchBox.placeholder.status') as string}
                                        type="select"
                                        options={[
                                            {label: "Hoạt động", value: 0},
                                            {label: "Ngừng hoạt động", value: 1},
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={isCanCreateListCategory ? 6 : 4}>
                            <div className="group-icon">
                                { isCanCreateListCategory ? <AddIcon onClick={()=> setShowCreateModal(true)}/> : <></>}
                                <ExportIcon onClick={()=>exportData()}/>
                            </div>
                        </Col>
                    </Row>
                </CommonForm>
            </div>

            <div className="vehicle-type-total">
                <div>{`${t('vehicleTypePage.total')}: ${data?.totalElements||0} ${t('vehicleTypePage.vehicleType')}`}</div>
            </div>

            <div className="vehicle-type-list">
                <CommonSpin isLoading={isLoading}>
                    { data?.content?.length ? <div>
                                {data?.content.map((item:any, index:number) => {
                                    return <VehicleTypeItem 
                                                key={item?.id} 
                                                itemData={item} 
                                                onOK={onResetList}
                                            />
                                })}
                        </div> 
                        : <></>
                    }
                </CommonSpin>
                
                { !(!!data?.totalElements) ? <CommonEmpty/> : <></>}
            </div>

            { data?.totalElements ? <div className="vehicle-type-pagination">
                    <CommonPagination
                        defaultCurrent={1} 
                        total={data?.totalElements}
                        current={page}
                        pageSize={10}
                        showSizeChanger={false}
                        onChange={onChangePage}
                    />
                </div> : null
            }
            { showCreateModal ?
                <VehicleTypeCreateModal
                    dataDetail={{}}
                    modalStatus={showCreateModal}
                    handleOk={()=>{setShowCreateModal(false); onResetList();}}
                    handleCancel={()=>{setShowCreateModal(false)}}            
                /> : <></>
            }
        </div>
    </div>)

    
}

export default VehicleType;