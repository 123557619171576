import { useCallback, useEffect, useState } from 'react';
import { Col, notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from "moment"
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Information } from '../../resources/images/information-circle.svg';
import { ReactComponent as PencilAlt } from '../../resources/images/pencil-alt.svg';
import { ReactComponent as Exclamation } from '../../resources/images/exclamation.svg';
import { ReactComponent as BadgeCheck } from '../../resources/images/badge-check.svg';
import { ReactComponent as LicensePlatesIcon } from '../../resources/images/license-plates-icon.svg';
import { ReactComponent as RegisterNumberIcon } from '../../resources/images/register-number-icon.svg';
import { ReactComponent as CalenderIcon } from '../../resources/images/calender.svg';

import CommonButton from './../../components/Common/Button';
import VehicleDetailModal from './VehicleDetailModal';
import CommonConfirmModal from '../../components/Common/ConfirmModal';
import { DATE_TIME_FORMAT, LOCK_WORK } from '../../utils/constants';
import { VEHICLE_STATUS } from './../../utils/constants';
import { savePositionSelected } from '../../redux/actions/positionSelected.actions';
import VehicleCreateModal from './VehicleCreateModal';

import vehicleRegistrationService from "../../services/vehicleRegistration.service";

interface VehicleItemProps{
    itemData: any,
    onOK: () => void
}

const VehicleItem = (props: VehicleItemProps)=>{
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { itemData, onOK } = props;
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [isViolationData, setIsViolationData] = useState<boolean>(false);
    const [isOffline, setIsOffline] = useState<boolean>(false);
    const [lockWarning, setLockWarning] = useState<string>("");
    const { positions } = useSelector((state:any) => state?.positionsReducer);
    const currentLock = positions.find((item:any)=> item.deviceId===itemData?.categoryLock?.id);

    const handleClick = useCallback((e:any)=>{
        if (document.getElementById(`vehicle-item-${itemData.id}`)?.contains(e.target) ||
            document.getElementById(`id-popup-action-${itemData.id}`)?.contains(e.target)){
            // Clicked in box
            console.log("click nè")
            if(currentLock){
                dispatch(savePositionSelected([currentLock.latitude,currentLock.longitude]));
            }else{
                dispatch(savePositionSelected([]));
            }
        } else{
            // Clicked outside the box
            console.log("click ngoài nè");
            dispatch(savePositionSelected([]));
            setShowPopup(false);
            window.removeEventListener('click', handleClick);
        }
    },[currentLock, dispatch, itemData.id])

    useEffect(()=>{
        if(showPopup){
            window.addEventListener('click', handleClick);
        }    
    },[handleClick, showPopup])

    const getDataTest = async () => {
        const resp = await vehicleRegistrationService.getViolationByRegistrationCarId(itemData?.id)
        const data = resp?.data;
        if (resp?.status === 200 && data?.data?.length > 0) {
            setIsViolationData(true)
        } 
    }

    useEffect(()=>{
        getDataTest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // Check cảnh báo khóa
    useEffect(()=>{
        let warningText:string[] = [];
        if(currentLock?.mucpin <=5){
            warningText.push("Hết pin")
        }
        if(currentLock?.donglaplung ===0){
            warningText.push("Chưa đóng lắp lưng")
        }
        if(currentLock?.dongdaycap ===0){
            warningText.push("Đang tháo dây")
        }
        if(currentLock?.motoloi === 1){
            warningText.push("Mô tô lỗi")
        }
        if(isOffline){
            warningText.push("Khóa offline")
        }
        setLockWarning(warningText.join(", "))

        const checkOffline = setInterval(function check(){
            if(currentLock?.serverTime){
                var startTime = moment(currentLock?.serverTime);
                var endTime = moment();
                var duration = moment.duration(endTime.diff(startTime));
                var minutes = Math.round(duration.asMinutes() % 60);
                console.log(minutes, ' minutes. item đăng ký vào SEV có biển số: ', itemData.plateNumber);
                if(minutes>3){
                    setIsOffline(true);
                }else{
                    setIsOffline(false);
                }
            }
            return check
        }(),60000)
        
        return ()=> clearInterval(checkOffline);

    },[currentLock, isOffline, itemData.plateNumber])

    
    // Hủy
    const onCancelVehicleRegistration = async ()=>{
        setLoadingConfirm(true);
        const resp = await vehicleRegistrationService.cancelVehicleRegistration(itemData?.id);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t(`Hủy giao hàng Biển số xe: ${itemData.plateNumber} thành công!`),
            });
            setVisible(false);
            onOK();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    // Hoàn thành
    const onCompleteVehicleRegistration = async ()=>{
        setLoadingConfirm(true);
        const resp = await vehicleRegistrationService.completeVehicleRegistration(itemData?.id);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t(`Hoàn thành giao hàng Biển số xe: ${itemData.plateNumber} thành công!`),
            });
            setVisible(false);
            onOK();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    return (
        <>
            <div className='vehicle-item-wrapper'>
                <div className='vehicle-item-header'>
                    <div id={`vehicle-item-${itemData.id}`} style={{cursor: "pointer", lineHeight: 0}} onClick={()=> setShowPopup(true)}>
                        <LicensePlatesIcon/>
                        <span>{itemData.plateNumber}</span> 
                        { isViolationData ? <span className="is-violation">
                                {t('vehicleRegistrationPage.list.violation')}
                            </span> :<></>
                        }
                    </div>
                    <CommonButton size={'small'} btnType="primary" onClick={()=>setShowDetail(true)}> 
                        {t('common.button.detail')} 
                    </CommonButton>
                </div>
                <div className='vehicle-item-body'>
                    <Row>
                        <Col span={12}>
                            <div>{ itemData?.categoryLock ? t('Đã gán IMEI') : t('Chưa gán IMEI')}</div>
                        </Col>
                        <Col span={12}>
                            <div>{`${t('vehicleRegistrationPage.list.lockStatus')}: ${isOffline ? "Khóa offline" : LOCK_WORK[currentLock?.khoathietbi]?.label || ""}`}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {/* <div>{`${t('vehicleRegistrationPage.list.lockWarning')}: ${LOCK_WARNING[currentLock?.trangthaithietbi]?.label || ""}`}</div> */}
                            <div>{`${t('vehicleRegistrationPage.list.lockWarning')}: ${lockWarning}`}</div>
                        </Col>
                    </Row>
                </div>
                <div className='vehicle-item-footer'>
                    <Row>
                        <Col span={24}>
                            <div className={`${VEHICLE_STATUS[itemData?.status-1].type} vehicle-item-status`}>{VEHICLE_STATUS[itemData?.status-1].label}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div> 
                                <RegisterNumberIcon/>
                                {`${t('vehicleRegistrationPage.list.deliveryLocation')}: ${itemData?.categoryCar?.id !== 1 ? (itemData?.companyDeliverySites?.length -2 || 0) : itemData?.companyDeliverySites?.length}`}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <div>
                                <CalenderIcon/>
                                {`${t('vehicleRegistrationPage.list.dateInSEV')}: ${moment(itemData.dateIn).format(DATE_TIME_FORMAT)}`}
                            </div>
                        </Col>
                        {/* <Col span={6} className="time">
                            <div>
                                {`${t('3h10p')}`}
                            </div>
                            
                        </Col> */}
                    </Row>
                </div>
            </div>

            { showDetail ?
                <VehicleDetailModal
                    dataDetailId={itemData.id}
                    modalStatus={showDetail}
                    handleOk={()=>{setShowDetail(false); onOK();}}
                    handleCancel={()=>{setShowDetail(false)}} 
                /> : <></>
            }

            { showCreateModal ?
                <VehicleCreateModal
                    dataDetail={itemData}
                    modalStatus={showCreateModal}
                    handleOk={()=>{setShowCreateModal(false); onOK();}}
                    handleCancel={()=>{setShowCreateModal(false)}}            
                /> : <></>
            }
            
            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content={ isComplete ? `Bạn có chắc chắn muốn Hoàn thành giao hàng Biển số xe: "${itemData.plateNumber}" không?`
                    :`Bạn có chắc chắn muốn Hủy giao hàng Biển số xe: "${itemData.plateNumber}" không?`
                }
                visible={visible}
                onOk={()=>{
                    if(isComplete){
                        onCompleteVehicleRegistration();
                    }else{
                        onCancelVehicleRegistration();
                    }
                }}
                loadingBtnOk={loadingConfirm}
            />

            { showPopup ?
                <div className="popup-action" id={`id-popup-action-${itemData.id}`}>
                    <div className="popup-action-item" onClick={()=>setShowDetail(true)}>
                        <Information/>
                        <div>{t('common.button.detail')}</div>
                    </div>
                    { (itemData?.status!==3 && itemData?.status!==4 && itemData?.status!==5 )?
                        <div className="popup-action-item" onClick={()=>setShowCreateModal(true)}>
                            <PencilAlt/>
                            <div>{t('common.button.Edit')}</div>
                        </div> : <></>
                    }
                    { (!itemData?.categoryLock?.id && itemData?.status===1) ?
                        <div className="popup-action-item" onClick={()=>{setVisible(true); setIsComplete(false);}}>
                            <Exclamation/>
                            <div>{t('common.button.cancel')}</div>
                        </div> : <></>
                    }
                    { itemData?.status===3 ?
                        <div className="popup-action-item" onClick={()=>{setVisible(true); setIsComplete(true);}}>
                            <BadgeCheck/>
                            <div>{t('common.button.success')}</div>
                        </div> :<></>
                    }
                </div> : <></>
            }
        </>
    )
}
export default VehicleItem;