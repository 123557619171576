import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import { notification, Space} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ExportOutlined } from '@ant-design/icons';

import {buildQueryString} from '../../../../utils/utilFunctions'
import {useQuery} from '../../../../utils/customHooks'
import CommonTable from '../../../../components/Common/Table'
import { DATE_TIME_FORMAT_SECOND, DEFAULT_PAGE_SIZE} from "../../../../utils/constants";
import SearchBox from "./SearchBox";
import CommonButton from "../../../../components/Common/Button";

import notifyServices from "../../../../services/notifies.service";

function LockReport () {
    const componentPath = "/system-management/lock-report"
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        page: pageQuery,
        pageSize: pageSizeQuery,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: searchQuery,
    } = params
    const page = pageQuery ? parseFloat(pageQuery) : 1;
    const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    const getData = async () => {
        setIsLoading(true)
        const paramsSearch = {
            page: page-1,
            size: pageSize,
            sortBy: sortByQuery,
            sortType: sortTypeQuery,
            search: searchQuery ? 
                        JSON.stringify({
                            ...JSON.parse(searchQuery),
                            module: "LOCK"
                        }) : 
                        JSON.stringify({
                            module: "LOCK"
                        }),
        }
        const resp = await notifyServices.getNotify(paramsSearch);
        const data = resp?.data;
        if (resp?.status === 200) {
            setData(data?.data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
    }

    // xuất excel
    const exportData = async () => {
        if(!isLoadingExcel){
            setIsLoadingExcel(true);
            // const paramsSearch = {
            //     page:0,
            //     size: 10000,
            //     sortBy: sortByQuery,
            //     sortType: sortTypeQuery,
            //     search: searchQuery,
            // }
            // const resp = await reportsServices.exportLock(paramsSearch);
            // const data = resp?.data;
            // if (resp?.status === 200) {
            //     const fileName = `Lock_Data_Export_${moment().format('YYYYMMDD')}_${moment().unix()}.xlsx`
            //     saveAs(data, fileName);
            // } else {
            //     notification.error({
            //         message: data?.message || t('commonError.oopsSystem'),
            //     });
            // }
            setTimeout(() => {
                setIsLoadingExcel(false);
            }, 1500)
            
        }
    }


    const onPageChange = (pagination:any, filters:any, sorter:any) => {
        let queryString = buildQueryString({
            ...params,
            page: pageSize === pagination?.pageSize ? pagination?.current : 1,
            pageSize: pagination?.pageSize,
            sortBy: sorter?.order ? sorter?.field : '',
            sortType: sorter?.order ? sorter?.order === 'ascend' ? 'asc' : 'desc' : ''
        })
        navigate(`${componentPath}${queryString || ''}`)
    }

    const columns = [
        {
            title: t('permissionsPage.list.columns.STT'),
            dataIndex: 'index',
            key: 'index',
            width: '5%',
            align: 'center',
            render: (cell:any, record:any, index:number) => (page - 1) * pageSize + index + 1,
        },
        {
            title: t('IMEI khóa'),
            dataIndex: 'imei',
            key: 'imei',
            render: (value:any, cell:any) => value || '--'
        },
        {
            title: t('Biển số'),
            dataIndex: 'plateNumber',
            key: 'plateNumber',
            render: (value:any, cell:any) => value || '--'
        },
        {
            title: t('Thời gian'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value:any, cell:any) => {
                return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
            }
        },
        {
            title: t('Loại thao tác'),
            dataIndex: 'description',
            key: 'description',
            render: (value:any, cell:any) => value || '--'
        },
        {
            title: t('Vị trí'),
            dataIndex: 'position',
            key: 'position',
            render: (value:any, cell:any) => value || '--'
        },
        {
            title: t('Tài khoản'),
            dataIndex: 'userName',
            key: 'userName',
            render: (value:any, cell:any) => value || '--'
        },
        {
            title: t('Họ tên'),
            dataIndex: 'createBy',
            key: 'createBy',
            render: (value:any, cell:any) => value || '--'
        },
        
    ];

    return <div className='content-wrapper'>
        <SearchBox getData={getData} componentPath={componentPath}/>

        <div className="avic-table-top">
            <div className="avic-table-top-title">
                {t('Danh sách dữ liệu khóa')}
            </div>
            <Space className="avic-table-top-right">
                <CommonButton
                    loading={isLoadingExcel}
                    onClick={() => exportData()}
                    size={'small'}
                    icon={<ExportOutlined />}
                >
                    {t('common.button.exportExcel')}
                </CommonButton>
            </Space>
        </div>

        <CommonTable
            isLoading={isLoading}
            rowKey={'id'}
            dataSource={data?.content || []}
            columns={columns}
            data={data}
            onChange={onPageChange}
            defaultSorter={{
                order: sortTypeQuery,
                field: sortByQuery,
            }}
        />
    </div>
}

export default LockReport;

