import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';

import CommonButton from './../../../components/Common/Button';
import CommonConfirmModal from '../../../components/Common/ConfirmModal';
import LockCreateModal from './LockCreateModal';
import LockDetailModal from './LockDetailModal';

import lockServices from "../../../services/locks.service";
import { LOCK_WORK } from '../../../utils/constants';

interface LockItemProps{
    itemData: any,
    onOK: () => void
}

const LockItem = (props: LockItemProps)=>{
    const {t} = useTranslation();
    const { itemData, onOK } = props;
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const { profile } = useSelector((state:any) => state?.profileReducer);
    const { positions } = useSelector((state:any) => state?.positionsReducer);
    const currentLock = positions.find((item:any)=> item.deviceId===itemData?.id);
    const [lockWarning, setLockWarning] = useState<string>("");
    const [isOffline, setIsOffline] = useState<boolean>(false);
    const isCanUpdateListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_UPDATE");
    const isCanDeleteListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_DELETE");

    const onSuccess = async ()=>{
        setLoadingConfirm(true);
        const resp = await lockServices.deleteLock([itemData?.id]);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t(`Xóa Khóa ${itemData.uniqueid||""} thành công!`),
            });
            onOK();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    // Check cảnh báo khóa
    useEffect(()=>{
        let warningText:string[] = [];
        if(currentLock?.mucpin <=5){
            warningText.push("Hết pin")
        }
        if(currentLock?.donglaplung ===0){
            warningText.push("Chưa đóng lắp lưng")
        }
        if(currentLock?.dongdaycap ===0){
            warningText.push("Đang tháo dây")
        }
        if(currentLock?.motoloi === 1){
            warningText.push("Mô tô lỗi")
        }
        if(isOffline){
            warningText.push("Khóa offline")
        }
        setLockWarning(warningText.join(", "))


        const checkOffline = setInterval(function check(){
            var startTime = moment(currentLock?.serverTime);
            var endTime = moment();
            var duration = moment.duration(endTime.diff(startTime));
            var minutes = Math.round(duration.asMinutes() % 60);
            console.log(minutes + ' minutes.item khóa: ', itemData?.uniqueid);
            if(minutes>3){
                setIsOffline(true);
            }else{
                setIsOffline(false);
            }
            return check
        }(),60000)
        
        return ()=> clearInterval(checkOffline);
    },[currentLock, isOffline, itemData?.uniqueid])

    return (
        <>
            <div className='lock-item-wrapper'>
                <div className='lock-item-header'>
                    <div>
                        <div style={{cursor: "pointer"}} onClick={()=>setShowDetailModal(true)}>{itemData.uniqueid}</div>
                        <div className="item-status-pin">Phần trăm pin: {currentLock?.mucpin} (%)</div>
                        <div className="item-status">{ isOffline ? t('Khóa offline') : LOCK_WORK[currentLock?.khoathietbi]?.label || ""}</div>
                    </div>
                    { isCanUpdateListCategory ? 
                        <CommonButton size={'small'} btnType="primary" onClick={()=> setShowEditModal(true)}> 
                            {t('common.button.Edit')} 
                        </CommonButton> : <></>
                    }
                </div>
                
                <div className='lock-item-footer'>
                    <div>
                        <div className={itemData.statusWork=== 0 ? 'info':'danger'}>{itemData.statusWork=== 0 ? t('Đang sử dụng') : t('Chưa sử dụng')}</div>
                        <div className="item-description">{lockWarning}</div>
                    </div>
                    { isCanDeleteListCategory ? 
                        <CommonButton size={'small'} btnType="danger" onClick={()=>setVisible(true)}> 
                            {t('common.button.remove')} 
                        </CommonButton> : <></>
                    }
                </div>
            </div>

            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content={`Bạn có chắc chắn muốn xóa Khóa "${itemData.uniqueid}" này không?`}
                visible={visible}
                onOk={onSuccess}
                loadingBtnOk={loadingConfirm}
            />

            { showEditModal ?
                <LockCreateModal
                    dataDetail={itemData}
                    modalStatus={showEditModal}
                    handleOk={()=>{setShowEditModal(false);onOK();}}
                    handleCancel={()=>{setShowEditModal(false)}}            
                /> : <></>
            }

            { showDetailModal ?
                <LockDetailModal
                    dataDetail={{
                        ...itemData,
                        lockState: LOCK_WORK[currentLock?.khoathietbi]?.label || "",
                        lockWarning: lockWarning,
                        isOffline: isOffline
                    }}
                    modalStatus={showDetailModal}
                    handleOk={()=>{setShowDetailModal(false); onOK();}}
                    handleCancel={()=>{setShowDetailModal(false)}}            
                /> : <></>
            }
        </>
    )
}
export default LockItem;