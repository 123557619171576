import APIService from '../utils/apiServices';

export interface PositionSearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class PositionServices extends APIService {
    async getPosition(params: PositionSearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/v1/system/category/position/getPage`, {}, {params: apiParams});
    }

    async createPosition(data:any) {
        return await this.request('POST', `/api/v1/system/category/position`, data);
    }

    async updatePosition(id:any, data:any) {
        return await this.request('PUT', `/api/v1/system/category/position/${id}`, data);
    }

    async deletePosition(data:number[]) {
        return await this.request('DELETE', `/api/v1/system/category/position`, data);
    }

    async lockPosition(data:number[]) {
        return await this.request('POST', `/api/v1/system/category/position/lock`, data);
    }

    async unlockPosition(data:number[]) {
        return await this.request('POST', `/api/v1/system/category/position/unlock`, data);
    }

    async exportPosition(params: PositionSearchParams) {
        return await this.request('GET', `/api/v1/system/category/position/exportExcel`, {}, {
            responseType: 'blob',
            params: params,
        });
    }
}
const service = new PositionServices();
export default service
