import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Form, Space, Popover, notification } from "antd";
import { saveAs } from 'file-saver';
import moment from "moment";
import { debounce } from "lodash";

import { ReactComponent as FilterIcon } from "../../resources/images/filter-square-icon.svg";
import { ReactComponent as AddIcon } from "../../resources/images/add-square-icon.svg";
import { ReactComponent as ExportIcon } from "../../resources/images/export-square-icon.svg";
import { ReactComponent as Search} from '../../resources/images/search-1.svg';

import CommonInput from './../../components/Common/Input';
import CommonEmpty from './../../components/Common/Empty';
import CommonForm from './../../components/Common/Form';
import CommonFormItem from './../../components/Common/FormItem';
import CommonPagination from './../../components/Common/Pagination';
import VehicleItem from './VehicleItem';
import VehicleCreateModal from "./VehicleCreateModal";
import VehicleFilter from './VehicleFilter';
import CommonSpin from "../../components/Common/Spin";
import { useQuery } from "../../utils/customHooks";
import { buildQueryString } from "../../utils/utilFunctions";

import vehicleRegistrationService from "../../services/vehicleRegistration.service";


const VehicleRegistration = ()=>{
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        page: pageQuery,
        search: searchQuery,
    } = params;

    const page = pageQuery ? parseFloat(pageQuery) : 1;

    const getData = useCallback( async (reload?: boolean) =>{
        setIsLoading(true);
        const paramsSearch = {
            page: reload ? 0 : page-1,
            search: (searchQuery) ,
        }
        const resp = await vehicleRegistrationService.getVehicleRegistration(paramsSearch);
        const data = resp?.data;

        if (resp?.status === 200) {
            setData(data?.data)
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    },[page, searchQuery, t])

    useEffect(()=>{
        getData();
        const autoReload = setInterval(getData,10000);
        return ()=> clearInterval(autoReload);
    },[getData])

    const onChangePage = (page: number, pageSize: number)=>{
        let queryString = buildQueryString({
            ...params,
            page: page,
        })
        navigate(`${queryString || ''}`);
    }

    const onResetList = ()=>{
        let queryString = buildQueryString({
            ...params,
            page: 1,
        }); 
        if (queryString !== search) {    
            navigate(`${queryString || ''}`)
        }
        getData(true);   
    }

    
    const onSearch = (values:any)=>{
        const oldSearch = searchQuery ? JSON.parse(searchQuery) : {}
        let queryString = buildQueryString({
            tab: "mapTab",
            page: 1,
            search: JSON.stringify({
                ...oldSearch,
                ...form.getFieldsValue()
            }),
        })
        if (queryString !== search) {    
            navigate(`${queryString || ''}`)
        } 
    }

    
    // xuất excel
    const exportData = async () => {
        if(!isLoadingExcel){
            setIsLoadingExcel(true);
            const paramsSearch = {
                page:0,
                size: 10000,
                search: (searchQuery) || JSON.stringify({})
            }
            const resp = await vehicleRegistrationService.exportVehicleRegistration(paramsSearch);
            const data = resp?.data;
            if (resp?.status === 200) {
                const fileName = `Vehicle_Registration_Data_Export_${moment().format('YYYYMMDD')}_${moment().unix()}.xlsx`
                saveAs(data, fileName);
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
            setIsLoadingExcel(false);
        }
    }

    const handleOpenChangePôpver = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    return (<div className='page-sidebar-container'>
        <div className="vehicle-registration-page">
            <Space className="vehicle-registration-form">
                <CommonForm
                    form={form}
                    onFieldsChange={debounce(onSearch,300)}
                    layout="horizontal"
                    initialValues={{
                        plateNumber:  searchQuery ? JSON.parse(searchQuery)?.plateNumber : undefined,
                    }}
                >
                    <CommonFormItem name="plateNumber">
                        <CommonInput
                            placeholder={t('vehicleRegistrationPage.searchBox.placeholder.plateNumber') as string}
                            prefix={<Search className='prefix-icon'/> }
                        />
                    </CommonFormItem>
                </CommonForm>
                <div className="group-icon">
                    <Popover 
                        placement="bottom" 
                        content={<VehicleFilter onHide={()=> setOpen(false)} getData={getData}/>} 
                        title={t('vehicleRegistrationPage.searchBox.title')} 
                        trigger="click" 
                        open={open}
                        onOpenChange={handleOpenChangePôpver}
                    >
                        <FilterIcon/>
                    </Popover>
                    <AddIcon onClick={()=> setShowCreateModal(true)}/>
                    <ExportIcon onClick={exportData}/>
                </div>
            </Space>

            <div className="vehicle-total">
                <div>{`${t('vehicleRegistrationPage.list.total')}:  ${data?.totalElements||0} ${t('vehicleRegistrationPage.list.vehicle')}`}</div>
            </div>

            <div className="vehicle-list">
                <CommonSpin isLoading={isLoading}>
                    { data?.content?.length ? <div>
                                    {data?.content.map((item:any, index:number) => {
                                        return <VehicleItem 
                                                    key={item?.id} 
                                                    itemData={item} 
                                                    onOK={onResetList}
                                                />
                                    })}
                            </div> 
                        : <></>
                    }
                </CommonSpin>
                { !(!!data?.totalElements) ? <CommonEmpty /> : <></>}
            </div>

            { data?.totalElements ? <div className="vehicle-pagination">
                    <CommonPagination
                        defaultCurrent={1} 
                        total={data?.totalElements}
                        current={page}
                        pageSize={10}
                        showSizeChanger={false}
                        onChange={onChangePage}
                    />
                </div> : null
            }
            { showCreateModal ?
                <VehicleCreateModal
                    dataDetail={{}}
                    modalStatus={showCreateModal}
                    handleOk={()=>{setShowCreateModal(false); onResetList();}}
                    handleCancel={()=>{setShowCreateModal(false)}}            
                /> : <></>
            }
        </div>
    </div>)

    
}

export default VehicleRegistration;