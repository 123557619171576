import React from 'react';
import { useLocation } from 'react-router-dom';

import MainLayout from "./MainLayout";
import SystemLayout from './SystemLayout';

function Layout(props:any) {
    const location = useLocation();

    if (location?.pathname.includes("system-management")) {
        return <SystemLayout>
            {props.children}
        </SystemLayout>;
    }

    return <MainLayout>
        {props.children}
    </MainLayout>;
}

export default Layout;

