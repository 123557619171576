import { useEffect, useState } from "react";
import { Col, Form, Input, Row, Space, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useWatch } from "antd/es/form/Form";
import { useSelector } from "react-redux";

import { ReactComponent as Close } from "../../../resources/images/close-contained.svg";

import CommonDrawer from "../../../components/Common/Drawer";
import CommonButton from "../../../components/Common/Button";
import CommonTree from "../../../components/Common/Tree";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonForm from "../../../components/Common/Form";
import CommonSpin from './../../../components/Common/Spin';
import { REGEX_PHONE_NUMBER, ROLE_PAGE_STATUS } from "../../../utils/constants";
import ChangePasswordModal from "../../Login/ChangePassword";

import accountsServices from "../../../services/users.service";
import permissionsServices from "../../../services/permissions.service"

export interface AccountDrawerProps{
    visible: boolean,
    onAddSuccessful: () => void,
    resetState: () => void,
    currentAccount: any,
}

const AccountDrawer = ({ visible, onAddSuccessful, currentAccount, resetState }: AccountDrawerProps) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [selectDataPermission, setSelectDataPermission] = useState<any[]>([]);
    
    const {profile} = useSelector((state:any) => state?.profileReducer);
    const isCanCreateListAccount = profile?.authorities?.find((item:any)=> item.authority === "ACCOUNT_CREATE");
    const isCanUpdateListAccount = profile?.authorities?.find((item:any)=> item.authority === "ACCOUNT_UPDATE");

    const groupsId = useWatch("groups",form);

    const getData =  async ()=>{
        setIsLoading(true)
        const resp = await permissionsServices.getFullAuthority();
        const data = resp?.data;
        if (resp?.status === 200) {
            const newTreeData = data?.data?.map((item:any)=>({key: item.id, title: item.description}));
            setTreeData(newTreeData);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
    };

    const getDataSelect = async () => {
        const resp = await permissionsServices.getPermission({page: 0, size: 1000});
        const data = resp?.data;
        if (resp?.status === 200) {
            setSelectDataPermission(data?.data?.content);
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
    }

    useEffect(()=>{
        getDataSelect();
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        const newCheckedKeys:React.Key[] = [];
        groupsId?.map((groupsIdItem:any)=>{
            selectDataPermission?.find((item:any)=>item.id===groupsIdItem)?.authorities?.map((item:any)=>{
                if(!newCheckedKeys.includes(item.id)){
                    newCheckedKeys.push(item.id);
                }
                return item
            });
            return groupsIdItem;
        });
        setCheckedKeys(newCheckedKeys);
    },[groupsId, selectDataPermission]);

    const onFinish = (values: any) => {
        onSubmit(values)
    }

    const onSubmit = async (values:any) => {
        setIsSubmitLoading(true)
        let resp
        const body = {
            "name": values?.name,
            "username": values?.username,
            "password": values?.password,
            "email": values?.email,
            "phone": values?.phone,
            "groups": values?.groups?.map((item:any)=>({id: item})),
            "status": values?.status ? ROLE_PAGE_STATUS[0].value : ROLE_PAGE_STATUS[1].value
        }
        if (currentAccount) {
            resp = await accountsServices.updateAccount(currentAccount.id, body);
        } else {
            resp = await accountsServices.createAccount(body);
        }
        const data = resp?.data;
        if (resp?.status === 200) {
            if (!currentAccount) {
                notification.success({
                    message: t('accountPage.form.message.createSuccess'),
                });
            } else {
                notification.success({
                    message: t('accountPage.form.message.updateSuccess'),
                });
            } 
            onAddSuccessful();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        
        setIsSubmitLoading(false)
    }

    return (
        <CommonDrawer
            closable={false}
            mask={true}
            maskClosable={false}
            width={'60%'}
            title={currentAccount ?
                <div className="drawer-title">
                    <span>{t('accountPage.form.detailTitle')}</span>
                    <Close onClick={resetState} />
                </div>
                : <div className="drawer-title">
                    <span>{t('accountPage.form.createTitle')}</span>
                    <Close onClick={resetState} />
                </div>}
            placement="right"
            open={visible}
            onClose={resetState}
            footer={[
                <Row className='row-drawer' key='footer'>
                    <Space>
                        <CommonButton
                            key="close"
                            btnType='default'
                            size="large"
                            onClick={resetState}
                        >
                            {t('common.button.close')}
                        </CommonButton>
                        { isCanCreateListAccount && !currentAccount ?
                            <CommonButton
                                key="update"
                                btnType='primary'
                                size="large"
                                onClick={() => form.submit()}
                                loading={isSubmitLoading}
                            >
                                {t('common.button.addNew')}
                            </CommonButton> : <></>
                        }
                        { isCanUpdateListAccount && currentAccount ?
                            <CommonButton
                                key="update"
                                btnType='primary'
                                size="large"
                                onClick={() => form.submit()}
                                loading={isSubmitLoading}
                            >
                                {t('common.button.save')}
                            </CommonButton> : <></>
                        }
                    </Space>
                </Row>
            ]}
        >   
            <CommonForm
                key="form"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    name: currentAccount?.name,
                    username: currentAccount?.username,
                    email: currentAccount?.email,
                    phone: currentAccount?.phone,
                    password: currentAccount?.password,
                    groups: currentAccount?.groups?.map((item:any)=> item.id),
                    status: currentAccount ? (currentAccount?.status === 0 ? true : false) : true
                }}
                disabled={!isCanUpdateListAccount && currentAccount}
            >
                <div className="detail-page-box">
                    <div className="box-title">
                        {t('accountPage.form.infoBoxTitle')}
                    </div>
                    <Row gutter={20}>
                        <Col span={12}>
                            <CommonFormItem
                                disabled={currentAccount ? true : false}
                                name="username"
                                label={t('accountPage.form.label.username')}
                                placeholder={t('accountPage.form.placeholder.username') as string}
                                rules={currentAccount ? [] : [
                                    { whitespace: true, required: true, message: t('accountPage.form.validate.username') as string }
                                ]}
                                showRequiredIcon={currentAccount ? false : true}
                            />
                        </Col>
                        {/* !profile?.authorities?.find((item:any)=>item.authority==="ACCOUNT_UPDATE") */}
                        <Col span={12}>
                            <Row gutter={8}>
                                <Col span={(isCanUpdateListAccount && currentAccount) ? 18 : 24}>
                                    <CommonFormItem
                                        name="password"
                                        label={t('accountPage.form.label.password')}
                                        rules={currentAccount ? [] : [
                                            { whitespace: true, required: true, message: t('accountPage.form.validate.password') as string }
                                        ]}
                                        showRequiredIcon={currentAccount ? false : true}
                                    >
                                        <Input.Password 
                                            placeholder={t('accountPage.form.placeholder.password') as string}
                                            disabled={currentAccount ? true : false}  
                                            allowClear
                                        />
                                    </CommonFormItem>
                                </Col>
                                { (isCanUpdateListAccount && currentAccount) ? 
                                    <Col span={6}>
                                        <CommonFormItem label=" ">
                                            <CommonButton onClick={()=>setVisibleModal(true)} btnType="default" size="small">{t("common.button.changePass")}</CommonButton>
                                        </CommonFormItem>
                                    </Col> : <></>
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={12}>
                            <CommonFormItem
                                name="name"
                                label={t('accountPage.form.label.name')}
                                placeholder={t('accountPage.form.placeholder.name') as string}
                                rules={[
                                    { whitespace: true, required: true, message: t('accountPage.form.validate.name') as string }
                                ]}
                                showRequiredIcon={true}
                            />
                        </Col>
                        <Col span={12}>
                            <CommonFormItem
                                name="email"
                                label={t('accountPage.form.label.email')}
                                placeholder={t('accountPage.form.placeholder.email') as string}
                                rules={[
                                    { whitespace: true, required: true, message: t('accountPage.form.validate.email') as string }
                                ]}
                                showRequiredIcon={true}
                            />
                        </Col>
                        <Col span={12}>
                            <CommonFormItem
                                name="phone"
                                label={t('accountPage.form.label.phoneNumber')}
                                placeholder={t('accountPage.form.placeholder.phoneNumber') as string}
                                rules={[
                                    { pattern: REGEX_PHONE_NUMBER, message: t('accountPage.form.validate.phoneNumber') }
                                ]}
                                showRequiredIcon={true}
                            />
                        </Col>
                        <Col span={12}>
                            <CommonFormItem
                                name="groups"
                                label={t('accountPage.form.label.groups')}
                                placeholder={t('accountPage.form.placeholder.groups') as string}
                                rules={[
                                    { required: true, message: t('accountPage.form.validate.groups') as string }
                                ]}
                                showRequiredIcon={true}
                                mode="multiple"
                                type="select"
                                maxTagCount={2}
                                options={selectDataPermission?.map((item:any)=>({value: item.id, label: item.groupName}))}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <CommonFormItem
                                name="status"
                                label={t('accountPage.form.label.status')}
                                valuePropName="checked"
                                type='switch'
                            />
                        </Col>
                    </Row>
                    <div className="box-title">
                        {t('accountPage.form.permissionBoxTitle')}
                    </div>
                    <CommonSpin isLoading={isLoading}> 
                        <CommonTree
                            key="tree"
                            checkable
                            showLine={true}
                            checkedKeys={checkedKeys}
                            treeData={treeData}
                        />
                    </CommonSpin>
                </div>
            </CommonForm>
            <ChangePasswordModal
                userId={currentAccount?.id}
                handleOk={()=>setVisibleModal(false)}
                openModal={visibleModal}
            />
        </CommonDrawer>
    )
}

export default AccountDrawer;