import * as Constants from '../constants/positions.constants';

const initialState = {
  positions: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action:any) {
  let newPositions:any[] = [...state.positions];
  if(action?.payload?.length>0){
      action?.payload?.map((itemPayload:any)=>{
        const indexPayload = newPositions?.findIndex((item:any)=>item.deviceId ===itemPayload.deviceId );
        if(indexPayload !== -1){
          newPositions[indexPayload] = itemPayload;
        }else{
          newPositions=[...newPositions, itemPayload]
        }
        return itemPayload;
      })
  }
  switch (action.type) {
    case Constants.SAVE_POSITIONS:
      return {
        ...state,
        // positions: action?.payload,
        positions: newPositions,
      };
    default:
      return state
  }
}