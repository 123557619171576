import React, {useState, useEffect} from 'react'
import { Col, Form, Grid, Input, List, Modal, Row, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Checkbox } from "../../resources/images/checkbox-circle.svg";
import { ReactComponent as Error } from "../../resources/images/error.svg";

import CommonButton from '../../components/Common/Button';
import CommonForm from '../../components/Common/Form';
import { AT_LEAST_1_NUMBER, AT_LEAST_8_CHARS, REGEX_PASSWORD } from '../../utils/constants';

import userServices from "../../services/users.service";

export interface ChangePasswordProps{
    userId: number,
    openModal: boolean;
    handleOk: () => void;
}

function ChangePassword(props: ChangePasswordProps) {
    const { openModal, handleOk, userId } = props;
    const { t } = useTranslation();
    const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
    const [repeatError, setRepeatError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const screens = Grid.useBreakpoint();
    const [form] = Form.useForm();
    const newPassword = Form.useWatch("newPassword", form);

    const onFinish = async (values: any) => {
        setIsLoading(true);
        const resp = await userServices.changePasswordByAdmin({...values, userId});
        if (resp?.status === 200) {
            notification.success({
                message: t('accountPage.form.message.changePasswordSuccess1'),
            });
            // LocalStorage.getInstance().save('accessToken', null);
            // window.location.href = '/';
            handleOk();
        } 
        else {
            notification.error({
                message: resp?.data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    }

    const ListItems = [
        t('profilePage.changePasswordTab.condition.atLeast8Chars'),
        // t('profilePage.changePasswordTab.condition.atLeast1Uppercase'),
        t('profilePage.changePasswordTab.condition.atLeast1Number'),
    ]

    
    useEffect(() => {
        if (newPassword) {
            let newActiveIndexes: number[] = [];
            if (newPassword.match(AT_LEAST_8_CHARS)) {
                newActiveIndexes.push(0);
            }

            // if (newPassword.match(atLeast1Uppercase)) {
            //     newActiveIndexes.push(1);
            // }

            if (newPassword.match(AT_LEAST_1_NUMBER)) {
                newActiveIndexes.push(1);
            }
            setActiveIndexes(newActiveIndexes);
        } else {
            setActiveIndexes([]);
        }
    }, [newPassword]);



    const onValuesChange = (changedValues: any) => {
        setFormChanged(true);
    }

    const onCancel = () => {
        if (formChanged) {
            Modal.confirm({
                title: t('common.confirmAction'),
                okText: t('common.button.accept'),
                onOk: async () => {
                    form.resetFields();
                    setFormChanged(false);
                    handleOk();
                },
                onCancel: () => {
                    console.log("Cancel");
                },
                cancelText: t('common.button.cancel')
            })
        }else{
            handleOk();
        }
    }

    return (
        <Modal
            className="modal-change-password"
            title={t("profilePage.changePasswordTab.title")}
            open={openModal}
            maskClosable={false}
            onCancel={onCancel}
            footer={[
                <CommonButton
                    key="cancel"
                    onClick={onCancel}
                    size="small"
                >
                    {t("common.button.cancel")}
                </CommonButton>,
                <CommonButton
                    form="myForm"
                    key="submit"
                    htmlType="submit"
                    btnType="primary"
                    size="small"
                    className="btn-icon-left"
                    loading={isLoading}
                    onClick={()=>form.submit()}
                >
                    {t("common.button.save")}
                </CommonButton>
            ]}
        >
            <CommonForm
                    form={form}
                    layout="vertical"
                    name="change-password"
                    requiredMark={false}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    scrollToFirstError={{ behavior: 'smooth'}}
                >
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={12} order={screens.xs ? 2 : 1}>
                            <Form.Item
                                validateTrigger={["onChange", "onBlur"]}
                                name="newPassword"
                                label={t('profilePage.changePasswordTab.label.newPassword')}
                                rules={[
                                    { required: true, message: t('profilePage.changePasswordTab.validate.newPassword') as string },
                                    { pattern: REGEX_PASSWORD, message: "" }
                                ]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                validateTrigger={["onChange", "onBlur"]}
                                name="confirmPassword"
                                label={t('profilePage.changePasswordTab.label.reNewPassword')}
                                rules={[
                                    { required: true, message: t('profilePage.changePasswordTab.validate.reNewPassword') as string  },

                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                setRepeatError(false);
                                                return Promise.reject();
                                            }

                                            if (getFieldValue('newPassword') !== value) {
                                                setRepeatError(true)
                                                return Promise.reject();
                                            }
                                            setRepeatError(false);
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                            {repeatError ? <p className="password-error">{t('profilePage.changePasswordTab.validate.mismatched')}</p> : <></>}
                        </Col>
                        <Col xs={24} sm={12} order={screens.xs ? 1 : 2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <div className="change-password-condition">
                                <div className="change-password-condition-title">{t('profilePage.changePasswordTab.label.requirementNewPassword')}</div>
                                <List
                                    split={false}
                                    dataSource={ListItems}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            className={activeIndexes.includes(index) ? "password-condition-active" : newPassword ? "password-condition-error" : ""}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            {newPassword && !activeIndexes.includes(index) ?
                                                <><Error />{item}</> :
                                                <><Checkbox />{item}</>}
                                        </List.Item>
                                    )} />
                            </div>
                        </Col>
                    </Row>
            </CommonForm>
        </Modal>
    );
}

export default ChangePassword;

