import { useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CommonButton from './../../../components/Common/Button';
import CommonConfirmModal from '../../../components/Common/ConfirmModal';
import VehicleTypeCreateModal from './VehicleTypeCreateModal';

import vehicleTypeServices from "../../../services/vehicleTypes.service";

interface VehicleTypeItemProps{
    itemData: any,
    onOK: () => void
}

const VehicleTypeItem = (props: VehicleTypeItemProps)=>{
    const {t} = useTranslation();
    const { itemData, onOK } = props;
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const { profile } = useSelector((state:any) => state?.profileReducer);
    const isCanUpdateListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_UPDATE");
    const isCanDeleteListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_DELETE");

    const onSuccess = async ()=>{
        setLoadingConfirm(true);
        const resp = await vehicleTypeServices.deleteVehicleType([itemData?.id]);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: data?.message || t(`Xóa loại xe ${itemData.name} thành công!`),
            });
            onOK();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setLoadingConfirm(false);
    }

    const handleOk = ()=>{
        setShowDetailModal(false);
        onOK();
    }

    return (
        <>
            <div className='vehicle-type-item-wrapper'>
                <div className='vehicle-type-item-header'>
                    <div>
                        <div>{itemData.name}</div>
                        <div className="time-of-vehicle" style={itemData?.isInternalCar!==0 ? {display: 'none'}:{}}>{itemData.timeForAllow || 0} phút</div>
                    </div>
                    { isCanUpdateListCategory ?
                        <CommonButton size={'small'} btnType="primary" onClick={()=> setShowDetailModal(true)}> 
                            {t('common.button.Edit')} 
                        </CommonButton> : <></>
                    }
                </div>
                
                <div className='vehicle-type-item-footer'>
                    <div className={itemData?.status === 0 ? 'info':'danger'}>{itemData?.status === 0 ? t('Đang hoạt động') : t('Ngừng hoạt động')}</div>
                    {isCanDeleteListCategory ? 
                        <CommonButton style={itemData?.isInternalCar!==0 ? {display: 'none'}:{}} size={'small'} btnType="danger" onClick={()=>setVisible(true)}> 
                            {t('common.button.remove')} 
                        </CommonButton> : <></>
                    }
                </div>
            </div>

            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content={`Bạn có chắc chắn muốn xóa Loại xe "${itemData.name}" này không?`}
                visible={visible}
                onOk={onSuccess}
                loadingBtnOk={loadingConfirm}
            />

            { showDetailModal ?
                <VehicleTypeCreateModal
                    dataDetail={itemData}
                    modalStatus={showDetailModal}
                    handleOk={handleOk}
                    handleCancel={()=>{setShowDetailModal(false)}}            
                /> : <></>
            }
        </>
    )
}
export default VehicleTypeItem;