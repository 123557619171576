import { useCallback, useEffect, useState } from "react";
import { Form, Row, Col, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import _, { debounce } from "lodash";
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useSelector } from "react-redux";

import { ReactComponent as AddIcon } from "../../../resources/images/add-square-icon.svg";
import { ReactComponent as AlphabetIcon } from '../../../resources/images/alphabet-icon.svg';
import { ReactComponent as ExportIcon } from "../../../resources/images/export-square-icon.svg";

import CommonEmpty from './../../../components/Common/Empty';
import CommonForm from './../../../components/Common/Form';
import CommonFormItem from './../../../components/Common/FormItem';
import CommonPagination from './../../../components/Common/Pagination';
import PositionItem from './PositionItem';
import PositionCreateModal from "./PositionCreateModal";
import { useQuery } from "../../../utils/customHooks";
import { buildQueryString } from "../../../utils/utilFunctions";
import CommonSpin from "../../../components/Common/Spin";

import positionServices from "../../../services/positions.service";


const Position = ()=>{
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataSelect, setDataSelect] = useState<any[]>([]);
    const [dataSelectPosition, setDataSelectPosition] = useState<any[]>([]);
    const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
    const buildingName = useWatch("name",form);
    const queryObj:any = useQuery();
    const {params = {}, search} = queryObj
    const {
        page: pageQuery,
        search: searchQuery,
    } = params;
    const [searchType, setSearchType] = useState<number>(searchQuery ? (parseInt(JSON.parse(searchQuery)?.searchType as string) || 3) : 3);
    const page = pageQuery ? parseFloat(pageQuery) : 1;
    const { profile } = useSelector((state:any) => state?.profileReducer);
    const isCanCreateListCategory = profile?.authorities?.find((item:any)=> item.authority === "CATEGORY_CREATE");

    const getData = useCallback( async (reload?: boolean) =>{
        setIsLoading(true);
        const newSearchQuery = searchQuery ? JSON.parse(searchQuery) : {}
        const paramsSearch = {
            page: reload ? 0 : page-1,
            search: JSON.stringify({
                ...newSearchQuery,
                searchType
            }),
        }
        const resp = await positionServices.getPosition(paramsSearch);
        const data = resp?.data;

        if (resp?.status === 200) {
            setData(data?.data);
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    },[page, searchQuery, searchType, t])

    useEffect(()=>{
        getData();
    },[getData])

    const getDataSelect =async () => {
        const resp = await positionServices.getPosition({
            search: JSON.stringify({
                typeGet: 99,
            })
        });
        const data = resp?.data;
        if (resp?.status === 200) {
            setDataSelect(data?.data?.content);
        } else {
            setDataSelect([]);
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
    }

    useEffect(()=>{
        getDataSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(buildingName){
            setDataSelectPosition(
                Object.keys(_.groupBy(dataSelect.filter((item:any) => item.name === buildingName), function (item:any) { return item.address }))
                    .map((item:any)=>({value: item, label: item})));
        }
        else{
            setDataSelectPosition([]);
        }
    },[buildingName, dataSelect])

    const onChangePage = (page: number, pageSize: number)=>{
        let queryString = buildQueryString({
            ...params,
            page: page,
        })
        navigate(`${queryString || ''}`);
    }

    const onSort = () => {
        const newSearchQuery = searchQuery ? JSON.parse(searchQuery) : {}
        const newSearchType = (searchType === 2 || searchType === 3)? 1 : 2;
        let queryString = buildQueryString({
            ...params,
            search: JSON.stringify({
                ...newSearchQuery,
                searchType: newSearchType,
            }),
        })
        setSearchType(newSearchType)
        navigate(`${queryString || ''}`);
    }

    const onSearch = (values:any)=>{
        let queryString = buildQueryString({
            tab: "categoryTab",
            subTab: "positionTab",
            page: 1,
            search: JSON.stringify({
                ...form.getFieldsValue()
            }),
        })
        if (queryString !== search) {    
            navigate(`${queryString || ''}`)
        }
    }

    // xuất excel
    const exportData = async () => {
        if(!isLoadingExcel){
            setIsLoadingExcel(true);
            const paramsSearch = {
                page:0,
                size: 10000,
                search: (searchQuery) || JSON.stringify({})
            }
            const resp = await positionServices.exportPosition(paramsSearch);
            const data = resp?.data;
            if (resp?.status === 200) {
                const fileName = `Position_Data_Export_${moment().format('YYYYMMDD')}_${moment().unix()}.xlsx`
                saveAs(data, fileName);
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
            setIsLoadingExcel(false);
        }
    }

    const onResetList:any = ()=>{
        let queryString = buildQueryString({
            ...params,
            page: 1,
        });
        // detect if query not change => call api
        if (queryString !== search) {    
            navigate(`${queryString || ''}`);
        }
        getData(true);
    }

    return (<div className='page-sidebar-container'>
        <div className="position-category-page">
            <div className="position-category-form">
                <CommonForm
                    form={form}
                    onFieldsChange={debounce(onSearch,300)}
                    layout="horizontal"
                    initialValues={{
                        name:  searchQuery ? JSON.parse(searchQuery)?.name : undefined,
                        address: searchQuery ? JSON.parse(searchQuery)?.address : undefined,
                        status: searchQuery ? JSON.parse(searchQuery)?.status : undefined,
                    }}
                >
                    <Row>
                        <Col span={isCanCreateListCategory ? 16 : 18}>
                            <CommonFormItem
                                name="name"
                                placeholder={t('positionPage.searchBox.placeholder.building') as string}
                                type="select"
                                options={ Object.keys(_.groupBy(dataSelect, function (item:any) { return item.name })).map((item:any)=>({value: item, label: item}))}
                                onChange={()=>{form.setFieldValue("address",undefined)}}
                            />
                        </Col>
                        <Col span={isCanCreateListCategory ? 8 : 6}>
                            <div className="group-icon">
                                <AlphabetIcon className="alphabet-icon" onClick={onSort}/>
                                { isCanCreateListCategory ? <AddIcon onClick={()=> setShowCreateModal(true)}/> : <></>}
                                <ExportIcon onClick={()=>exportData()}/>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <CommonFormItem
                                name="address"
                                placeholder={t('positionPage.searchBox.placeholder.position') as string}
                                type="select"
                                options={dataSelectPosition}
                            />
                        </Col>
                        <Col span={12}>
                            <CommonFormItem
                                name="status"
                                placeholder={t('positionPage.searchBox.placeholder.status') as string}
                                type="select"
                                options={[
                                    {label: "Hoạt động", value: 0},
                                    {label: "Ngừng hoạt động", value: 1},
                                ]}
                            />
                        </Col>
                    </Row>
                </CommonForm>
            </div>

            <div className="position-total">
                <div>{`${t('positionPage.total')}: ${data?.totalElements||0} ${t('positionPage.position')}`}</div>
            </div>

            <div className="position-list">
                <CommonSpin isLoading={isLoading}>
                    { data?.content?.length ? <div>
                                {data?.content.map((item:any, index:number) => {
                                    return <PositionItem 
                                                key={item?.id} 
                                                itemData={item} 
                                                onOK={onResetList}
                                            />
                                })}
                        </div> 
                        : <></>
                    }
                </CommonSpin>
                { !(!!data?.totalElements) ? 
                    <CommonEmpty /> : <></>
                }
            </div>

            { data?.totalElements ? <div className="position-pagination">
                    <CommonPagination
                        defaultCurrent={1} 
                        total={data?.totalElements}
                        current={page}
                        pageSize={10}
                        showSizeChanger={false}
                        onChange={onChangePage}
                    />
                </div> : null
            }
            { showCreateModal ?
                <PositionCreateModal
                    dataDetail={undefined}
                    modalStatus={showCreateModal}
                    handleOk={()=>{
                        setShowCreateModal(false);
                        onResetList();
                    }}
                    handleCancel={()=>{setShowCreateModal(false);}}            
                /> : <></>
            }
        </div>
    </div>)

    
}

export default Position;