import { Col, Form, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonModal from '../../../components/Common/Modal';
import { REGEX_NO_SPACE, REGEX_PHONE_NUMBER } from "../../../utils/constants";

import lockServices from "../../../services/locks.service";

interface LockCreateModalProps {
    dataDetail: any,
    modalStatus: boolean,
    handleOk: () => void,
    handleCancel: () => void
}

const LockCreateModal = (props:LockCreateModalProps) => {
    const [form] = Form.useForm();
    const {modalStatus, handleOk, handleCancel, dataDetail} = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const onFinish = (values:any) => {
        onCreateLock(values);
    }

    const onCreateLock = async (values:any) => {
        setIsLoading(true);
        if(dataDetail?.id){
            const dataUpdate = {...dataDetail,...values};
            delete dataUpdate.id
            const resp = await lockServices.updateLock(dataDetail?.id, dataUpdate);
            const data = resp?.data;
            if (resp?.status === 200) {
                notification.success({
                    message: data?.message || t('lockPage.form.message.editSuccess'),
                });
                handleOk();
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
        }else{
            const resp = await lockServices.createLock({...values, statusWork: 0});
            const data = resp?.data;
            if (resp?.status === 200) {
                notification.success({
                    message: data?.message || t('lockPage.form.message.createSuccess'),
                });
                handleOk();
            } else {
                notification.error({
                    message: data?.message || t('commonError.oopsSystem'),
                });
            }
        }
        setIsLoading(false);
    }

    return(
        <CommonModal
            className="lock-modal-create"
            title={dataDetail?.id ? t('lockPage.form.editTitle') : t('lockPage.form.createTitle')}
            maskClosable={false}
            open={modalStatus}
            onCancel={handleCancel}
            footer={[
                <CommonButton key="cancel" onClick={handleCancel} size="small"  >
                    {t('common.button.close')}
                </CommonButton>,
                <CommonButton
                    form="myForm"
                    key="submit"
                    onClick={()=>form.submit()}
                    btnType="primary"
                    size="small"
                    className="btn-icon-left"
                    loading={isLoading}
                >
                    {t('common.button.save')}
                </CommonButton>
            ]}
        >
            <CommonForm  
                form={form}  
                onFinish={onFinish} 
                layout="vertical" 
                initialValues={{
                    uniqueid:  dataDetail?.uniqueid,
                    plateNumber: dataDetail?.plateNumber,
                    companyName: dataDetail?.companyName,  
                    driverName: dataDetail?.driverName,  
                    phone: dataDetail?.phone,
                }}
            >
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            name="uniqueid"
                            label={t('lockPage.form.label.IMEI')}
                            placeholder={t('lockPage.form.placeholder.IMEI') as string}
                            showRequiredIcon
                            showCount
                            maxLength={30}
                            rules={[
                                { required: true, message: t('lockPage.form.validate.IMEI') },
                                { pattern: REGEX_NO_SPACE, message: t('lockPage.form.validate.IMEIDontSpace') }
                            ]}
                            type='textArea'
                        />
                    </Col>
                </Row>
                <div className="assignment-info">
                    {t('lockPage.form.label.lockInfo')}
                </div>
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            name="plateNumber"
                            label={t('lockPage.form.label.plateNumber')}
                            placeholder={t('lockPage.form.placeholder.plateNumber') as string}
                            showCount
                            maxLength={30}
                            type='textArea'
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            name="companyName"
                            label={t('lockPage.form.label.companyName')}
                            placeholder={t('lockPage.form.placeholder.companyName') as string}
                            showCount
                            maxLength={200}
                            type='textArea'
                        />
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            name="driverName"
                            label={t('lockPage.form.label.driverName')}
                            placeholder={t('lockPage.form.placeholder.driverName') as string}
                            showCount
                            maxLength={200}
                            type='textArea'
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            name="phone"
                            label={t('lockPage.form.label.phone')}
                            placeholder={t('lockPage.form.placeholder.phone') as string}
                            showCount
                            maxLength={30}
                            type='textArea'
                            rules={[
                                { pattern: REGEX_PHONE_NUMBER, message: t('lockPage.form.validate.phone') }
                            ]}
                        />
                    </Col>
                </Row>
            </CommonForm>
        </CommonModal>
    )
}

export default LockCreateModal;