import { Button } from "antd";
import { useSelector } from "react-redux";
import Control from "react-leaflet-custom-control";
import Geocoder from 'leaflet-control-geocoder';
import L, { LatLngExpression } from "leaflet";
import { CircleMarker, Marker, Tooltip, useMap } from 'react-leaflet';
import { useEffect, useMemo, useRef, useState } from "react";
import { ZoomControl } from 'react-leaflet';
import "leaflet-control-geocoder/dist/Control.Geocoder.css";

import { ReactComponent as LocationIcon} from '../../../resources/images/location.svg';
import marker from '../../../resources/images/marker.png';
import markerSelected from '../../../resources/images/marker_selected.png';

const MapControls = ()=>{
    const map = useMap();
    const [myLocation, setMyLocation] = useState<LatLngExpression>([21.201509, 105.991802]);
    // const myLocation:LatLngExpression = [21.201509, 105.991802];
    const circlePosition = useRef<any>(null);
    const [isFly,setIsFly] = useState<boolean>(true);
    const markerSearch = useRef<any>(null);
    const iconMarker = useMemo(()=>{
        const icon = new L.Icon({
            iconUrl: marker,
            iconRetinaUrl: marker,
            iconSize: new L.Point(25, 41),
            iconAnchor: [13, 41],
        })
        return icon
    },[]);
    const iconMarkerSelected = useMemo(()=>{
        const icon = new L.Icon({
            iconUrl: markerSelected,
            iconRetinaUrl: markerSelected,
            iconSize: new L.Point(25, 41),
            iconAnchor: [13, 41],
        })
        return icon
    },[]);
    
    const { positions } = useSelector((state:any) => state?.positionsReducer);
    const { positionSelected } = useSelector((state:any) => state?.positionSelectedReducer);

    // render marker search
    useEffect(() => {
        const GeocoderControl = new Geocoder();
        GeocoderControl.addTo(map);
        GeocoderControl.on("markgeocode", function (e:any) {
            var latlng = e.geocode.center;
            if(markerSearch.current){
                map.removeLayer(markerSearch.current)
            }
            markerSearch.current =  L.marker(latlng, { icon: iconMarker })
                .addTo(map)
                .bindPopup(e.geocode.name)
                .openPopup();
        });
    }, [iconMarker, map]);
    
    // fly đến xe gắn khóa được click
    useEffect(()=>{
        if(positionSelected.length>0){
            map.flyTo(positionSelected, 18);
        }
    },[map, positionSelected])

    // fly đến vị trí hiện tại
    useEffect(()=>{
        if(isFly){
            // console.log("flying", isFly)
            map.flyTo(myLocation, 16);
        }
        setIsFly(false);
    },[isFly, map, myLocation])

    // render vị trí hiện tại
    const handleClick = ()=>{
        // console.log("update MyLocation")
        map.locate().on("locationfound", function (e:any) {
            // setMyLocation(e.latlng);
            if(circlePosition.current){
                map.removeLayer(circlePosition.current)
            }
            circlePosition.current =  L.circle(myLocation, 800, {
                // Number is in Meters
                    color: "#136aec",
                    fillOpacity: 0.2,
                    fillColor: "#136aec",
                    opacity: 1,
                    weight: 0
                }).addTo(map);
        });
    }
    useEffect(()=>{
        const updateMyLocation = setInterval(function check(){
            handleClick();
            return check
        }(),10000)
        
        return ()=> clearInterval(updateMyLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return ( 
        <>
            { positions ?  
                positions.map((item:any, index: number) => <Marker 
                                                                key={item.id} 
                                                                position={[item.latitude, item.longitude]} 
                                                                icon={(item.latitude !== positionSelected?.[0]|| item.longitude !== positionSelected?.[1]) ? iconMarker : iconMarkerSelected}
                                                            >
                                                                <Tooltip 
                                                                    direction="top" 
                                                                    offset={[0, -40]} 
                                                                    opacity={1} 
                                                                    permanent
                                                                >
                                                                    {/* {`${item.protocol} ${index}`} */}
                                                                    {
                                                                        item?.platenumber || item?.uniqueId?.slice(-4, item?.uniqueId?.length || `${item.protocol} ${index}`)
                                                                    }
                                                                </Tooltip>
                                                            </Marker> )
                                                            : <></>
            }
            <Control prepend position='topright'>
                <ZoomControl position='topright'/>
                <Button onClick={()=>{handleClick(); setIsFly(true);}}> 
                    <LocationIcon />
                </Button>
            </Control>
            {myLocation && (
                <CircleMarker
                    center={myLocation}
                    radius={9}
                    color="white"
                    fillColor="#2A93EE"
                    fillOpacity={1}
                    opacity={1}
                />
            )}
        </>
    )
}

export default MapControls
