import APIService from '../utils/apiServices';

export interface VehicleRegistrationSearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class VehicleRegistrationServices extends APIService {
    async getVehicleRegistration(params: VehicleRegistrationSearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/company-delivery/list-registration-car`, {}, {params: apiParams});
    }

    async getByIdVehicleRegistration(id:any) {
        return await this.request('GET', `/api/company-delivery/registration-car/${id}`);
    }

    async createVehicleRegistration(data:any) {
        return await this.request('POST', `/api/company-delivery/registration-car`, data);
    }
    
    async cancelVehicleRegistration(id:any) {
        return await this.request('PUT', `/api/company-delivery/cancel-delivery/${id}`);
    }

    async completeVehicleRegistration(id:any) {
        return await this.request('PUT', `/api/company-delivery/complete-delivery/${id}`);
    }

    async updateVehicleRegistration(id:any, data:any) {
        return await this.request('PUT', `/api/company-delivery/update-registration-car/${id}`, data);
    }
    
    async deleteVehicleRegistration(data:number[]) {
        return await this.request('DELETE', `/api/company-delivery/delete-registration-car`, data);
    }
    
    async exportVehicleRegistration(params: VehicleRegistrationSearchParams) {
        return await this.request('GET', `/api/company-delivery/exportExcel`, {}, {
            responseType: 'blob',
            params: params,
        });
    }

    
    async getViolationByRegistrationCarId(id:any) {
        return await this.request('GET', `/api/company-delivery/get-violation-by-registration-car-id/${id}`);
    }

}
const service = new VehicleRegistrationServices();
export default service
