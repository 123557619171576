import { Col, Form, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
// table drop row
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { ColumnsType } from 'antd/es/table';
// table drop row

import { ReactComponent as DeleteIcon } from '../../resources/images/delete-red.svg';
import { ReactComponent as AddIcon } from '../../resources/images/plus-blue.svg';

import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonSelect from './../../components/Common/Select';
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonModal from './../../components/Common/Modal';
import CommonTable from "../../components/Common/Table";
import { DATE_TIME_FORMAT } from "../../utils/constants";

import vehicleTypeServices from "../../services/vehicleTypes.service";
import positionServices from "../../services/positions.service";
import vehicleRegistrationService from "../../services/vehicleRegistration.service";
import { useWatch } from "antd/es/form/Form";

interface VehicleCreateModalProps {
    dataDetail: any,
    modalStatus: boolean,
    handleOk: () => void,
    handleCancel: () => void
}

interface DataType {
    key: string;
    index: number;
    name: string;
    disabled: boolean;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const RowTable = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({id: props['data-row-key'],});

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
            if ((child as React.ReactElement).key === 'sort') {
                return React.cloneElement(child as React.ReactElement, {
                children: (
                    <MenuOutlined
                    ref={setActivatorNodeRef}
                    style={{ touchAction: 'none', cursor: 'move' }}
                    {...listeners}
                    />
                ),
                });
            }
            return child;
        })}
    </tr>
    );
};


const VehicleCreateModal = (props:VehicleCreateModalProps) => {
    const [form] = Form.useForm();
    const {modalStatus, handleOk, handleCancel, dataDetail} = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [itemDeleteId, setItemDeleteId] = useState<number|undefined>(undefined);
    const [vehicleTypeSelectData, setVehicleTypeSelectData] = useState<any[]>([]);
    const [positionSelectData, setPositionSelectData] = useState<any[]>([]);
    const [gateInSelectData, setGateInSelectData] = useState<any[]>([]);
    const [checkDuplicate, setCheckDuplicate] = useState<any[]>([]);
    const [rowsEnableSort, setRowsEnableSort] = useState<any[]>([]);
    const [gateOutData, setGateOutData] = useState<any>();
    const [vehicleRegistrationSelectData, setVehicleRegistrationSelectData] = useState<any[]>([]);
    const [dataSource, setDataSource] = useState<any[]>(()=>{
        if(dataDetail?.companyDeliverySites){
            const newArr = [...dataDetail?.companyDeliverySites];
            newArr.pop(); //xóa cổng vào
            newArr.shift(); // xóa cổng ra
            setRowsEnableSort(newArr?.filter((item:any)=>(item?.unlockTime||item?.unlockUser)));
            return newArr?.map((item:any,index:number)=>({
                    key: (item?.orderNumber).toString(),
                    index: (item?.orderNumber),
                    name: item?.categoryPosition?.id,
                    disabled: (item?.unlockTime||item?.unlockUser) ? true : false,
                })) 
        }                                       
        return [];
    });     
    const categoryCarId = useWatch("categoryCar",form);
    
    const getData =  async (reload?: boolean) =>{
        const paramsSearch = {
            page: 0,
            size: 1000,
            search: JSON.stringify({status: 0}) ,
        }

        //select loại xe
        const resp = await vehicleTypeServices.getVehicleType(paramsSearch);
        const data = resp?.data;
        if (resp?.status === 200) {
            setVehicleTypeSelectData(data?.data?.content?.map((item:any)=>({value: item.id, label: item.name})));
        } else {
            setVehicleTypeSelectData([]);
        }

        //select vị trí
        const respPosition = await positionServices.getPosition(paramsSearch);
        const dataPosition = respPosition?.data;
        if (respPosition?.status === 200) {
            setPositionSelectData(dataPosition?.data?.content?.filter(
                                                        (item:any) => item.type!==100&&item.type!==200)?.map(
                                                            (item:any)=> ({value:item.id, label: item.name+" - "+item.address})));
            setGateInSelectData(dataPosition?.data?.content?.filter(
                                                    (item:any) => item.type===100)?.map(
                                                        (item:any)=> ({value:item.id, label: item.name})));
            setGateOutData(dataPosition?.data?.content?.filter((item:any) => item.type===200)?.[0]);
        } else {
            setPositionSelectData([]);
        }

        //select biển số xe
        const respVehicleRegistration = await vehicleRegistrationService.getVehicleRegistration({...paramsSearch, search: JSON.stringify({})});
        const dataVehicleRegistration = respVehicleRegistration?.data;
        if (respVehicleRegistration?.status === 200) {
            setVehicleRegistrationSelectData(Object.keys(_.groupBy(dataVehicleRegistration?.data?.content, function (item:any){ return item.plateNumber}))?.map((item:any)=>({value: item})));
        } else {
            setVehicleRegistrationSelectData([]);
        }

    };

    useEffect(()=>{
        getData();
    },[])

    const onDeleteRow = async () => {
        setLoadingConfirm(true);
        await setDataSource((prevData:any)=>{
            const newDataSource = prevData.filter((item: any)=>item.index !== itemDeleteId);
            return newDataSource;
        })
        setVisible(false);
        setLoadingConfirm(false);
    }

    // check duplicate vị trí kho
    useEffect(()=>{
        if(dataSource.length>1){
            const duplicateData = dataSource?.filter((item:any, index:number)=>{
                if(index===0){
                    return item?.name && item?.name===dataSource[1]?.name;
                }else if(index===dataSource.length-1){
                    return item?.name && item?.name===dataSource[dataSource.length-2].name;
                }else{
                    return item?.name && (item.name===dataSource[index+1].name || item.name===dataSource[index-1].name);
                }
            })
            setCheckDuplicate(duplicateData?.map((item:any)=> item.key))
        }else{
            setCheckDuplicate([]);
        }
    },[dataSource, t])

    // Thêm dòng
    const handleAddNewRow = () => {
        const newData: DataType = {
            key: (dataSource?.length+1).toString(),
            index: (dataSource?.length+1),
            name: '',
            disabled: false,
        };
        setDataSource([...dataSource, newData]);
    };

    //Sort row
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id && parseInt(over?.id as string) > rowsEnableSort.length) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex((i) => i.key === active.id);
                const overIndex = previous.findIndex((i) => i.key === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }else{
            notification.error({
                message: t('vehicleRegistrationPage.form.validate.enableSort'),
            });
        }
    };
    
    const onFinish = (values:any) => {
        if(categoryCarId!==1){
            if(dataSource?.length===0){
                notification.error({
                    message: t('vehicleRegistrationPage.form.validate.infoPositionIsNull'),
                });
                return;
            }
            if(dataSource?.find((item:any)=>item.name==='' ||item.name===undefined)){
                notification.error({
                    message: t('vehicleRegistrationPage.form.validate.infoPosition'),
                });
                return;
            }
    
            if(checkDuplicate.length>0){
                notification.error({
                    message: t('vehicleRegistrationPage.form.validate.duplicatedPosition'),
                });
                return;
            }
        }

        onCreateRegistrationService(values);
    }

    const onCreateRegistrationService = async (values:any) => {
        setIsLoading(true);
        if(categoryCarId!==1){
            if(dataDetail?.id){
                const newDataSource = dataSource.slice(rowsEnableSort?.length);
                const newDataDetail ={
                    ...dataDetail,
                    companyDeliverySites: [
                        {...dataDetail?.companyDeliverySites?.[0]},
                        ...rowsEnableSort,
                        ...newDataSource?.map((item:any, index:number)=>( { categoryPosition: { id: item.name }, orderNumber: index+1+rowsEnableSort.length })),
                        {...dataDetail?.companyDeliverySites?.[dataDetail?.companyDeliverySites?.length-1]},
                    ]
                }
                const resp = await vehicleRegistrationService.updateVehicleRegistration(dataDetail?.id, newDataDetail);
                const data = resp?.data;
                if (resp?.status === 200) {
                    notification.success({
                        message: data?.message || t('vehicleRegistrationPage.form.message.editSuccess'),
                    });
                    setDataSource([]);
                    handleOk();
                } else {
                    notification.error({
                        message: data?.message || t('commonError.oopsSystem'),
                    });
                }
            }else{
                const dataSubmit = {
                    ...values, 
                    dateIn: values?.dateIn?.toISOString(),
                    categoryCar: {id: values?.categoryCar},
                    companyDeliverySites: [
                        { categoryPosition: { id: values?.gateIn }, orderNumber: 0 },
                        ...dataSource?.map((item:any, index:number)=>( { categoryPosition: { id: item.name }, orderNumber: index+1 })),
                        { categoryPosition: { id: gateOutData?.id }, orderNumber: 10000 },
                    ],
                }
                const resp = await vehicleRegistrationService.createVehicleRegistration({...dataSubmit,status: 1 });
                const data = resp?.data;
                if (resp?.status === 200) {
                    notification.success({
                        message: data?.message || t('vehicleRegistrationPage.form.message.createSuccess'),
                    });
                    setDataSource([]);
                    handleOk();
                } else {
                    notification.error({
                        message: data?.message || t('commonError.oopsSystem'),
                    });
                }
            }
        }else{
            if(dataDetail?.id){
                const resp = await vehicleRegistrationService.updateVehicleRegistration(dataDetail?.id, {...dataDetail});
                const data = resp?.data;
                if (resp?.status === 200) {
                    notification.success({
                        message: data?.message || t('vehicleRegistrationPage.form.message.editSuccess'),
                    });
                    setDataSource([]);
                    handleOk();
                } else {
                    notification.error({
                        message: data?.message || t('commonError.oopsSystem'),
                    });
                }
            }else{
                const dataSubmit = {
                    ...values, 
                    dateIn: values?.dateIn?.toISOString(),
                    categoryCar: {id: values?.categoryCar},
                }
                const resp = await vehicleRegistrationService.createVehicleRegistration({...dataSubmit,status: 1 });
                const data = resp?.data;
                if (resp?.status === 200) {
                    notification.success({
                        message: data?.message || t('vehicleRegistrationPage.form.message.createSuccess'),
                    });
                    setDataSource([]);
                    handleOk();
                } else {
                    notification.error({
                        message: data?.message || t('commonError.oopsSystem'),
                    });
                }
            }
        }
        setIsLoading(false);
    }

    const columns: ColumnsType<DataType> = [
        {
            key: 'sort',
            width: '5%',
        },
        {
            title: t('vehicleRegistrationPage.form.list.STT'),
            key: 'index',
            align: "center",
            width: '10%',
            render: (cell:any, record:any, index:number) => index + 1,
        },
        {
            title: t('vehicleRegistrationPage.form.list.action'),
            key:"action",
            align: "center",
            width: '10%',
            render: (cell:any, record:any, index:number) => {
                if(record?.disabled){
                    return <DeleteIcon className="delete-icon" style={{cursor: "not-allowed"}}/>
                }
                return <DeleteIcon className="delete-icon" onClick={()=>{setVisible(true); setItemDeleteId(record.index)}}/>
            }
        },
        {
            title: t('vehicleRegistrationPage.form.list.deliveryPosition'),
            dataIndex: 'name',
            key: "name",
            align: "center",
            render: (cell:any, record:any, index:number) => {
                return <div className={`row-table ${(!cell || checkDuplicate?.includes(record.key)) ? "dangerous" : ""}`}> 
                    <CommonSelect 
                        disabled={record?.disabled}
                        options={positionSelectData}
                        placeholder={t('vehicleRegistrationPage.form.placeholder.deliveryPosition')}
                        onChange={(value:any)=>{
                            setDataSource((prevState)=> {
                                const newData = [...prevState];
                                newData[index].name = value;
                                return newData;
                            })
                        }}
                        value={cell||undefined}
                        allowClear
                        showSearch
                    />
                </div>
            }
        }
    ];

    return(
        <CommonModal
            className="vehicle-modal-create"
            title={dataDetail?.id ? t('vehicleRegistrationPage.form.editTitle') : t('vehicleRegistrationPage.form.createTitle')}
            open={modalStatus}
            onCancel={handleCancel}
            footer={[
                <CommonButton
                    key="cancel"
                    onClick={handleCancel}
                    size="small"
                >
                    {t('common.button.close')}
                </CommonButton>,
                <CommonButton
                    form="myForm"
                    key="submit"
                    onClick={()=>form.submit()}
                    btnType="primary"
                    size="small"
                    className="btn-icon-left"
                    loading={isLoading}
                >
                    {t('common.button.save')}
                </CommonButton>
            ]}
        >
            <CommonForm
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    plateNumber: dataDetail?.plateNumber,
                    categoryCar: dataDetail?.categoryCar?.id,
                    gateIn: dataDetail?.gateIn,
                    dateIn: moment(dataDetail?.dateIn)
                }}
            >
                <Row gutter={30}>
                    <Col span={12}>
                        <CommonFormItem
                            name="plateNumber"
                            label={t('vehicleRegistrationPage.form.label.plateNumber')}
                            type="autoComplete"
                            showRequiredIcon={dataDetail?.id ? false : true}
                            options={dataDetail?.id ? [] : vehicleRegistrationSelectData}
                            placeholder={t('vehicleRegistrationPage.form.placeholder.plateNumber') as string}
                            rules={dataDetail?.id ? [] : [
                                { required: true, whitespace:true, message: t('vehicleRegistrationPage.form.validate.plateNumber') }
                            ]}
                            disabled={dataDetail?.id ? true : false}
                        />
                    </Col>
                    <Col span={12}>
                        <CommonFormItem
                            name="categoryCar"
                            label={t('vehicleRegistrationPage.form.label.vehicleType')}
                            placeholder={t('vehicleRegistrationPage.form.placeholder.vehicleType') as string}
                            type="select"
                            showRequiredIcon={dataDetail?.id ? false : true}
                            options={vehicleTypeSelectData}
                            rules={dataDetail?.id ? [] : [
                                { required: true, message: t('vehicleRegistrationPage.form.validate.vehicleType') }
                            ]}
                            disabled={dataDetail?.id ? true : false}
                        />
                    </Col>
                </Row>
                <Row gutter={30}>  
                    { categoryCarId !== 1 ?
                        <Col span={12}>
                            <CommonFormItem
                                name="gateIn"
                                label={t('vehicleRegistrationPage.form.label.gateIn')}
                                placeholder={t('vehicleRegistrationPage.form.placeholder.gateIn') as string}
                                type="select"
                                showRequiredIcon={dataDetail?.id ? false : true}
                                options={gateInSelectData}
                                rules={dataDetail?.id ? [] : [
                                    { required: true, message: t('vehicleRegistrationPage.form.validate.gateIn') }
                                ]}
                                disabled={dataDetail?.id ? true : false}
                            />
                        </Col> : <></>
                    }
                    <Col span={12}>
                        <CommonFormItem
                            name="dateIn"
                            type="datePicker"
                            label={t('vehicleRegistrationPage.form.label.dateInSEV')}
                            placeholder={t('vehicleRegistrationPage.form.placeholder.dateInSEV') as string}
                            showRequiredIcon
                            rules={[
                                { required: true, message: t('vehicleRegistrationPage.form.validate.dateInSEV') }
                            ]}
                            showTime={{ format: 'HH:mm' }}
                            format={DATE_TIME_FORMAT}
                            disabled={true}
                        />
                    </Col>
                </Row>
                { categoryCarId!==1 ?
                    <div>
                        <div className="list-title">
                            {t('vehicleRegistrationPage.form.label.infoPosition')} <span style={{marginLeft: "0.104vw", color: "#ff4d4f", fontSize: "0.729vw"}}>*</span>
                        </div>
                        <div className="list-total">{`${t('vehicleRegistrationPage.form.total')} ${dataSource?.length || 0} ${t('vehicleRegistrationPage.form.deliveryLocation')}`}</div>
                        <DndContext onDragEnd={onDragEnd}>
                            <SortableContext
                                // rowKey array
                                items={dataSource.map((i) => i.key)}
                                strategy={verticalListSortingStrategy}
                            >
                                <CommonTable
                                    components={{
                                        body: {
                                            row: RowTable,
                                        },
                                    }}
                                    scroll={{ y:200 }}
                                    pagination={false}
                                    rowKey="key"
                                    columns={columns}
                                    dataSource={dataSource}
                                />
                            </SortableContext>
                        </DndContext>

                        <AddIcon className="add-icon" onClick={handleAddNewRow}/>

                    </div>:<></>
                }
            </CommonForm>
            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content="Bạn có chắc chắn muốn xóa vị trí này không?"
                visible={visible}
                onOk={onDeleteRow}
                loadingBtnOk={loadingConfirm}
            />
        </CommonModal>
    )
}

export default VehicleCreateModal;