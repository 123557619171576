import { Col, Form, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonModal from './../../components/Common/Modal';

import lockServices from "../../services/locks.service";
import commandService from "../../services/commands.service";

interface AssignIMEIModalProps {
    dataDetail: any,
    modalStatus: boolean,
    handleOk: () => void,
    handleCancel: () => void
}

const AssignIMEIModal = (props:AssignIMEIModalProps) => {
    const [form] = Form.useForm();
    const {modalStatus, handleOk, handleCancel, dataDetail} = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [IMEISelectData, setIMEISelectData] = useState<any[]>([]);
    const { profile } = useSelector((state:any) => state?.profileReducer);
    
    const getData =  async (reload?: boolean) =>{
        const paramsSearch = {
            page: 0,
            size: 1000,
            search: JSON.stringify({status: 0}) ,
        }

        const resp = await lockServices.getLock(paramsSearch);
        // const resp = await lockServices.getVehicleType(paramsSearch);
        const data = resp?.data;
        if (resp?.status === 200) {
            setIMEISelectData(data?.data?.content?.map((item:any)=>({value: item.id, label: item.uniqueid})));
        } else {
            setIMEISelectData([]);
        }
    };

    useEffect(()=>{
        getData();
    },[])
    
    const onFinish = (values:any) => {
        setVisible(true);
    }

    const onAssignIMEI = async () => {
        setIsLoading(true);
        const dataSubmit:any = {
            registerCarId: dataDetail?.id,
            deviceId: form.getFieldValue('IMEINumber'),
            userId: profile?.id,
            positionId: dataDetail?.companyDeliverySites[0]?.categoryPosition?.id,
            companyDeliverySitesId: dataDetail?.companyDeliverySites[0]?.id,
            type: 50,
        }
        
        console.log(dataSubmit);
        const resp = await commandService.unlockByAdminCommand(dataSubmit);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: t('vehicleRegistrationPage.form.message.assignSuccess'),
            });
            setVisible(false);
            handleOk();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false);
    }

    return(
        <CommonModal
            title={t('vehicleRegistrationPage.form.assignTitle')}
            open={modalStatus}
            onCancel={handleCancel}
            footer={[
                <CommonButton
                    key="cancel"
                    onClick={handleCancel}
                    size="small"
                >
                    {t('common.button.cancel')}
                </CommonButton>,
                <CommonButton
                    form="myForm"
                    key="submit"
                    onClick={()=>form.submit()}
                    btnType="primary"
                    size="small"
                    className="btn-icon-left"
                    loading={isLoading}
                >
                    {t('common.button.confirm')}
                </CommonButton>
            ]}
        >
            <CommonForm
                form={form}
                onFinish={onFinish}
                layout="horizontal"
                initialValues={{
                    plateNumber: dataDetail?.plateNumber,
                }}
            >
                <Row gutter={30}>
                    <Col span={24}>
                    <CommonFormItem
                        isView={true}
                        name="plateNumber"
                        label={t('vehicleRegistrationPage.form.label.plateNumber')}
                    />
                    </Col>
                    <Col span={24}>
                        <CommonFormItem
                            name="IMEINumber"
                            label={t('vehicleRegistrationPage.form.label.assignIMEI')}
                            placeholder={t('vehicleRegistrationPage.form.placeholder.assignIMEI') as string}
                            type="select"
                            showRequiredIcon
                            options={IMEISelectData}
                            rules={[
                                { required: true, message: t('vehicleRegistrationPage.form.validate.assignIMEI') }
                            ]}
                        />
                    </Col>
                </Row>
            </CommonForm>
            <CommonConfirmModal
                onCancel={() => { setVisible(false) }}
                showCancel={true}
                showOk={true}
                content={`Bạn có chắc chắn muốn gán IMEI cho xe có biển số: "${dataDetail?.plateNumber}"  này không?`}
                visible={visible}
                onOk={onAssignIMEI}
                loadingBtnOk={isLoading}
            />
        </CommonModal>
    )
}

export default AssignIMEIModal;