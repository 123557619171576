import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {useTranslation} from "react-i18next";
import {EditOutlined} from '@ant-design/icons'
import {Form, Space, notification} from "antd";
import { useDispatch, useSelector} from "react-redux";

import CommonButton from "../../../../components/Common/Button";
import CommonForm from "../../../../components/Common/Form";
import CommonFormItem from "../../../../components/Common/FormItem";

import { REGEX_ONLY_NUMBER } from "../../../../utils/constants";
import { saveProfile } from '../../../../redux/actions/profile.actions';

import accountsServices from "../../../../services/users.service";
import userServices from "../../../../services/users.service";

function AccountProfile (props:any, ref:any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);

    const {
        profile
    } = useSelector((state:any) => state?.profileReducer);

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        isEdit,
        onClear
    }));

    const onFinish = (values: any) => {
        onSubmit(values)
    }

    const getProfile = async () => {
        const resp = await userServices.getProfile();
        const data = resp.data;
        if (resp?.status === 200) {
            dispatch(saveProfile(data?.data))
        }
    }

    const onSubmit = async (values:any) => {
        const body = {
            ...profile,
            "name": values?.name,
            "phone": values?.phone,
            "email": values?.email,
            authorities: null,
            grantedAuths: null,
        }

        setIsLoading(true)
        const resp = await accountsServices.updateAccount(profile.id, body);
        const data = resp?.data;
        if (resp?.status === 200) {
            notification.success({
                message: t('accountPage.form.message.updateSuccess'),
            });
            setIsEdit(false);
            getProfile();
        } else {
            notification.error({
                message: data?.message || t('commonError.oopsSystem'),
            });
        }
        setIsLoading(false)
    }

    const onClear = () => {
        form.resetFields();
        setIsEdit(false)
    }

    return <div className="profile-tab-content">
        <div className="profile-tab-content-header">
            <div className="profile-tab-content-header-left">
                {t('profilePage.accountProfileTab.title')}
            </div>
            <div className="profile-tab-content-header-right">
                {
                    !isEdit &&
                    <EditOutlined className='cursor-pointer' onClick={() => setIsEdit(true)}/>
                }
            </div>
        </div>

        <CommonForm
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
                name: profile?.name,
                username: profile?.username,
                role: profile?.groups[0]?.groupName,
                email: profile?.email,
                phone: profile?.phone,
                modifiedDate: profile?.modifiedDateStr
            }}
            isLoading={isLoading}
        >
            <CommonFormItem
                isView={!isEdit}
                name="name"
                label={t('profilePage.accountProfileTab.label.fullName')}
                placeholder={t('profilePage.accountProfileTab.placeholder.fullName') as string}
                rules={[
                    { required: true, message: t('validate.fullNameRequired') as string }
                ]}
            />
            <CommonFormItem
                isView={!isEdit}
                name="username"
                label={t('profilePage.accountProfileTab.label.userName')}
                placeholder={t('profilePage.accountProfileTab.placeholder.userName') as string}
                disabled={true}
            />
            <CommonFormItem
                isView={!isEdit}
                name="role"
                label={t('profilePage.accountProfileTab.label.roles')}
                placeholder={t('profilePage.accountProfileTab.placeholder.roles') as string}
                disabled={true}
            />
            <CommonFormItem
                isView={!isEdit}
                name="email"
                label={t('profilePage.accountProfileTab.label.email')}
                placeholder={t('profilePage.accountProfileTab.placeholder.email') as string}
            />
            <CommonFormItem
                isView={!isEdit}
                name="phone"
                label={t('profilePage.accountProfileTab.label.phoneNumber')}
                placeholder={t('profilePage.accountProfileTab.placeholder.phoneNumber') as string}
                rules={[
                    { pattern: new RegExp(REGEX_ONLY_NUMBER), message: t('validate.phoneNumberFormat') as string },
                ]}
            />
            <CommonFormItem
                isView={!isEdit}
                name="modifiedDate"
                label={t('profilePage.accountProfileTab.label.modifiedDate')}
                disabled={true}
                placeholder={t('profilePage.accountProfileTab.placeholder.modifiedDate') as string}
            />
            {
                isEdit && <Space className="form-btn-container">
                    <CommonButton size={'small'} onClick={onClear}>
                        {t('common.button.cancel')}
                    </CommonButton>
                    <CommonButton btnType="primary" size={'small'} htmlType="submit">
                        {t('common.button.save')}
                    </CommonButton>
                </Space>
            }
        </CommonForm>

    </div>
}

export default forwardRef(AccountProfile);

