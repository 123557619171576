import APIService from '../utils/apiServices';

export interface ReportSearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class ReportServices extends APIService {
    async getVehicleReport(params: ReportSearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/v1/report/bao-cao-xe`, {}, {params: apiParams});
    }
}
const service = new ReportServices();
export default service
