import APIService from '../utils/apiServices';

interface LoginData {
    email: string,
    pass: string
}
interface ChangePasswordData {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    userId: number
}

export interface AccountSearchParams {
    search?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortType?: string,
}

class UserServices extends APIService {
    async login(data:LoginData) {
        return await this.request('POST', `/public/v1/auth/jwt-login`, data);
    }

    async getProfile() {
        return await this.request('GET', `/api/v1/system/user/me`, {});
    }

    async changePassword(data:ChangePasswordData) {
        return await this.request('POST', `/api/v1/system/user/changePassword`, data);
    }
    
    async changePasswordByAdmin(data:ChangePasswordData) {
        return await this.request('POST', `/api/v1/system/user/changePasswordByAdmin`, data);
    }

    async updateProfile(data:any) {
        return await this.request('PUT', `account/profile`, data);
    }

    async getAccount(params: AccountSearchParams) {
        let apiParams = { 
            page: params.page || 0,
            size: params.size || 10,
            sortType: params.sortType ||"DESC", 
            sortBy: params.sortBy || "modifiedDate",
            search: params.search || JSON.stringify({})
        }
        return await this.request('GET', `/api/v1/system/user/getPage`, {}, {params: apiParams});
    }

    async getAccountById(id:number) {
        return await this.request('GET', `/api/v1/system/user/${id}`);
    }

    async createAccount(data:any) {
        return await this.request('POST', `/api/v1/system/user`, data);
    }

    async updateAccount(id:any, data:any) {
        return await this.request('PUT', `/api/v1/system/user/${id}`, data);
    }

    async deleteAccount(data:number[]) {
        return await this.request('DELETE', `/api/v1/system/user/delete`, data);
    }

    async lockAccount(data:number[]) {
        return await this.request('POST', `/api/v1/system/user/lock`, data);
    }

    async unlockAccount(data:number[]) {
        return await this.request('POST', `/api/v1/system/user/unlock`, data);
    }
}
const service = new UserServices();
export default service
